import { ReactNode, useMemo } from 'react';
import { Flex, FlexProps, Box } from '@chakra-ui/react';
import { MicIcon, MicOffIcon } from '@emochan-cabinet/icons';
import { IconButton } from '../../buttons/Button';
import { Label } from '../../typography/Label';
import { Transcript } from './Transcript';
import { TranscriptBoxContext, TranscriptBoxState } from './TranscriptBoxProvider';
import { TranscriptTextInput } from './TranscriptInput';
import { Tooltip } from '../../notifications/Tooltip';

interface TranscriptBoxProps extends FlexProps, TranscriptBoxState {
  waitingLabel: string,
  handleTranscribe?: () => void,
  actionStart?: ReactNode,
  actionEnd?: ReactNode,
  tooltipLabels?: [string, string],
}

export function TranscriptBox({
  status,
  waitingLabel,
  handleTranscribe,
  actionStart,
  actionEnd,
  tooltipLabels,
  children,
  ...rest
}: TranscriptBoxProps): JSX.Element {
  const isWaiting = useMemo(() => status === 'waiting', [status]);

  const micIcon = useMemo(() => (isWaiting || status === 'transcribing' ? <MicIcon /> : <MicOffIcon />), [status, isWaiting]);
  const tooltipLabel = useMemo(() => {
    if (tooltipLabels === undefined) return undefined;
    return (isWaiting || status === 'transcribing' ? tooltipLabels[1] : tooltipLabels[0]);
  }, [status, isWaiting, tooltipLabels]);

  const action = useMemo(() => (
    <Flex gap="8">
      {actionStart}
      <Tooltip label={tooltipLabel} placement="top">
        <IconButton
          icon={micIcon}
          color="primary"
          buttonStyle={status === 'transcribing' ? 'tonal' : 'fill'}
          disabled={isWaiting}
          onClick={handleTranscribe}
          label={waitingLabel}
        />
      </Tooltip>
    </Flex>
  ), [actionStart, handleTranscribe, waitingLabel, isWaiting, status, micIcon, tooltipLabel]);

  const waiting = useMemo(() => {
    if (!isWaiting) return null;

    return (
      (
        <Flex
          pos="absolute"
          top={-1}
          bottom={-1}
          left={-1}
          right={-1}
          zIndex={1}
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 4, 15, 0.5)"
          borderRadius="inherit"
        >
          <Flex
            as="button"
            type="button"
            alignItems="center"
            gap="16"
            boxShadow="below"
            borderRadius="full"
            bg="grey.0"
            py="8"
            pl="8"
            pr="24"
            _hover={{ boxShadow: 'high.below' }}
            _focus={{ bg: 'blue.5' }}
            onClick={handleTranscribe}
            // clickable overlay
            _before={{
              content: '""',
              cursor: 'inherit',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Box p="12" bg="gradient.blue" borderRadius="full">
              <MicIcon boxSize="1.5rem" color="grey.0" />
            </Box>
            <Label
              as="span"
              size="small"
              color="blue.80"
              bg="gradient.blue"
              backgroundClip="text"
            >
              {waitingLabel}
            </Label>
          </Flex>
        </Flex>
      )
    );
  }, [handleTranscribe, waitingLabel, isWaiting]);

  return (
    <TranscriptBoxContext.Provider value={{ status }}>
      <Flex
        className="emochan-transcript"
        flexDir="column"
        gap={{ base: '16', md: '24' }}
        {...rest}
      >
        <Flex
          pos="relative"
          flexDir="column-reverse"
          borderRadius={16}
          border="1px solid"
          borderColor={isWaiting || status === 'paused' ? 'border.light.transparent' : 'blue.50'}
          minH="4.5rem"
          flexGrow={1}
          overflowY={isWaiting ? 'hidden' : 'auto'}
          overflowX="hidden"
          overscrollBehavior="contain"
        >
          <Flex
            flexDir="column"
            gap="24"
            p="24"
            flexGrow={1}
            marginBottom="auto"
          >
            {children}
          </Flex>
          {waiting}
        </Flex>

        <Flex
          h="3.5rem"
          gap="16"
          justifyContent="space-between"
          alignItems="center"
          role="group"
        >
          {action}
          {actionEnd}
        </Flex>
      </Flex>
    </TranscriptBoxContext.Provider>
  );
}

TranscriptBox.Transcript = Transcript;
TranscriptBox.TranscriptTextInput = TranscriptTextInput;
TranscriptBox.displayName = 'TranscriptBox';
