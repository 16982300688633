import { FC, useMemo, useState } from 'react';
import { Flex, Box, BoxProps } from '@chakra-ui/react';
import { DialogueIcon, TrashcanIcon } from '@emochan-cabinet/icons';
import { Label } from '../../typography/Label';
import { Paragraph } from '../../typography/Paragraph';
import { Avatar } from '../../displays/Avatar';
import { Tooltip } from '../../notifications/Tooltip';
import { Button } from '../../buttons/Button';

export type MemberCardStatus =
  // リセット・再開
  | 'resumable'
  | 'hasRecords'
  | 'noRecords';

function getBg(status: MemberCardStatus) {
  switch (status) {
    case 'noRecords': return 'grey.5';
    case 'resumable': return 'red.5';
    case 'hasRecords': return 'blue.5';
    default: {
      const exhaustiveCheck: never = status;
      return exhaustiveCheck;
    }
  }
}

function getMessageColor(status: MemberCardStatus) {
  switch (status) {
    case 'noRecords': return 'grey.60';
    case 'resumable': return 'red.50';
    case 'hasRecords': return 'blue.50';
    default: {
      const exhaustiveCheck: never = status;
      return exhaustiveCheck;
    }
  }
}

type MemberCardNoRecords = {
  status: 'noRecords',
  tooltipLabel: string,
  buttonLabel: string,
  message: string,
  handleAction?(): void,
  handleMemberSheet?(): void,
}

type MemberCardHasRecords = {
  status: 'hasRecords',
  tooltipLabel: string,
  buttonLabel: string,
  message: JSX.Element,
  handleAction?(): void,
  handleMemberSheet?(): void,
}

type MemberCardResumable = {
  status: 'resumable',
  tooltipLabel: string,
  buttonLabel: string,
  message: string
  subTooltipLabel: string,
  subButtonLabel: string,
  handleAction?(): void,
  handleSubAction?(): void,
  handleMemberSheet?(): void,
}

type MemberCardStatusProps = MemberCardNoRecords | MemberCardHasRecords | MemberCardResumable;

interface MemberCardProps extends BoxProps {
  name: string,
  avatarURL: string,
}

export function MemberCard(props: MemberCardProps & MemberCardStatusProps) {
  const {
    status,
    tooltipLabel,
    buttonLabel,
    name,
    message,
    avatarURL,
    handleAction,
    handleMemberSheet,
  } = props;
  const isResumable = status === 'resumable';
  const hasRecords = status === 'hasRecords';
  const hasSubAction = isResumable;
  const [hover, setHover] = useState(false);

  const bg = useMemo(() => getBg(status), [status]);
  const fg = useMemo(() => getMessageColor(status), [status]);

  const messageElement = useMemo(() => {
    if (hasRecords) {
      return message;
    }

    return (
      <Paragraph
        as="div"
        size="xs"
        flexGrow={0}
      >
        {message}
      </Paragraph>
    );
  }, [message, hasRecords]);

  const subAction = useMemo(() => {
    if (hasSubAction) {
      const { subTooltipLabel, subButtonLabel, handleSubAction } = props;

      return (
        <Tooltip
          label={subTooltipLabel}
          placement="top-start"
          hasArrow
          openDelay={1000}
        >
          <Button
            leftIcon={<TrashcanIcon />}
            buttonStyle={hover ? 'fill' : 'tonal'}
            size="medium"
            color="negative"
            label={subButtonLabel}
            onClick={handleSubAction}
          />
        </Tooltip>
      );
    }
    return null;
  }, [hasSubAction, props, hover]);

  return (
    <Box
      borderRadius="24"
      bg="grey.0"
      maxW={{ md: '20rem' }}
      border="1px solid"
      borderColor="border.light.transparent"
      width="100%"
      _hover={{ boxShadow: 'high.below' }}
      _focus={{
        boxShadow: 'high.below',
        outline: 'none',
      }}
      transition="box-shadow 0.14s"
      data-status={status}
      // on mouse enter -> set hover to true
      onMouseEnter={() => setHover(true)}
      // on mouse leave -> set hover to false
      onMouseLeave={() => setHover(false)}
    >
      <Box px="12" pt="12">
        <Flex flexDir="column" alignItems="flex-start" borderRadius="12" bg={bg}>
          <Flex as={handleMemberSheet ? 'button' : 'div'} alignItems="center" justifyContent="flex-start" gap="12" p={12} onClick={handleMemberSheet}>
            <Box flexShrink={1}>
              <Avatar name={name} imageURL={avatarURL} size="large" />
            </Box>
            <Label size="large" as="div" noOfLines={2} wordBreak="break-all">{name}</Label>
          </Flex>
          <Flex color={fg} px="12" py={hasRecords ? '0' : '14px'}>
            {messageElement}
          </Flex>
        </Flex>
      </Box>
      <Flex flexDir="row" justifyContent="flex-end" alignItems="flex-end" gap="8" px="16" py="12">
        {subAction}
        <Tooltip
          label={tooltipLabel}
          placement="top-start"
          hasArrow
          openDelay={1000}
        >
          <Button
            leftIcon={<DialogueIcon />}
            size="medium"
            buttonStyle={hover ? 'fill' : 'tonal'}
            color={!hasRecords ? 'primary' : 'accent'}
            label={buttonLabel}
            onClick={handleAction}
          />
        </Tooltip>

      </Flex>
    </Box>
  );
}
