import { forwardRef, NamedExoticComponent } from 'react';
import { Flex, Box, FlexProps } from '@chakra-ui/react';

// アプリケーションを構成する大きな画面枠
// Header + Main で、Main が目一杯高さ広がる、な想定
export const AppCell = forwardRef<HTMLDivElement, FlexProps>(({
  children,
  ...props
}, ref) => (
  <Flex ref={ref} {...props} minHeight="calc(var(--emochan-vh, 1vh) * 100)">
    {children}
  </Flex>
)) as NamedExoticComponent<FlexProps> & {
  Item: typeof Box;
};

AppCell.Item = Box;
