// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const TranscriptIcon = createIcon({
  displayName: 'TranscriptIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11.0898 8.89442C10.8934 9.25913 11.0299 9.71397 11.3946 9.91035C11.7593 10.1067 12.2141 9.97028 12.4105 9.60558L13.0058 8.5H17.4945L18.0898 9.60558C18.2862 9.97028 18.741 10.1067 19.1057 9.91035C19.4704 9.71397 19.6069 9.25913 19.4105 8.89442L15.9105 2.39443C15.7797 2.15149 15.5261 2 15.2501 2C14.9742 2 14.7206 2.15149 14.5898 2.39443L11.0898 8.89442ZM16.6868 7H13.8135L15.25 4.33221L16.6868 7Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M12 2C12.5334 2 13.0425 2.10442 13.5078 2.29394L12.5482 4.07607C12.374 4.02652 12.1901 4 12 4C10.8954 4 10 4.89543 10 6V9.20719C9.99935 9.23475 9.99935 9.26228 10 9.28976V12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12V9.5H16V12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12V6C8 3.79086 9.79086 2 12 2Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M19 11C19.5523 11 20 11.4477 20 12C20 16.0793 16.9468 19.4455 13.001 19.938L13 21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21L11 19.9381C7.05371 19.446 4 16.0796 4 12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 11.4477 18.4477 11 19 11Z"
      />
    ),
  ],
});
