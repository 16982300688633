import { forwardRef } from 'react';
import { Box, Link, Flex, LinkProps } from '@chakra-ui/react';
import { Label } from '../../typography/Label';
import { Paragraph } from '../../typography/Paragraph';
import { Avatar } from '../../displays/Avatar';
import { EmoCard } from '../../emo/EmoCard';
import { EmoFeeling, EmoColors } from '../../../system/styles';

export interface ActivityCardProps extends LinkProps {
  emo: EmoFeeling,
  title: string
  formattedDate: string
  avatarURL: string
  name: string
  isFace?: boolean
}

export const ActivityCard = forwardRef<HTMLAnchorElement, ActivityCardProps>(({
  emo,
  title,
  formattedDate,
  avatarURL,
  name,
  isFace,
  ...props
}, ref) => (
  <Link
    ref={ref}
    display="inline-block"
    w="100%"
    bg="grey.0"
    p="12"
    borderRadius="24"
    border="1px solid"
    borderColor="border.light.transparent"
    overflow="hidden"
    maxW={{ md: '20rem' }}
    _hover={{ boxShadow: 'high.below' }}
    _focus={{
      boxShadow: 'high.below',
      outline: 'none',
    }}
    transition="all 0.14s"
    {...props}
  >

    <Flex as="figure" align="center" justify="center" bg={`${EmoColors[emo]}.5`} p="36" borderRadius={12}>
      <EmoCard
        tag="div"
        feeling={emo}
        user={isFace ? { name, imageURL: avatarURL } : undefined}
        showName={false}
        size="small"
      />
    </Flex>

    <Flex flexDir="column" pt="20" pl="8" pr="8" gap="8" wordBreak="break-word">
      <Label as="div" size="large">{title}</Label>
      <Paragraph as="div" size="small" color="grey.60">{formattedDate}</Paragraph>
      <Flex width="100%" gap="8" alignItems="center" pt="8" pb="8">
        <Box flexShrink={0}>
          <Avatar imageURL={avatarURL} name={name} size="small" />
        </Box>
        <Label
          size="small"
          maxW="100%"
          color="grey.60"
          noOfLines={1}
        >
          {name}
        </Label>
      </Flex>
    </Flex>
  </Link>
));

ActivityCard.displayName = 'ActivityCard';
