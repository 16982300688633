import { ClassNames } from '@emotion/react';
import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { getChildren } from '../../../utils/ReactChildren';

export const TextFieldGroup = forwardRef<FlexProps, 'div'>(({
  children,
  ...props
}, ref) => {
  const TextFieldGroupChildren = getChildren(children);

  const styles: { [key: string]: boolean } = {
    '--hasLeft': false,
    '--hasRight': false,
  };

  TextFieldGroupChildren.forEach((child) => {
    // child component 判定
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const name = (child.type as any).displayName;

    if (name === 'TextFieldLeft') {
      styles['--hasLeft'] = true;
    }

    if (name === 'TextFieldRight') {
      styles['--hasRight'] = true;
    }
  });

  return (
    <ClassNames>
      {({ cx }) => (
        <Flex
          ref={ref}
          w="100%"
          position="relative"
          className={cx(styles)}
          {...props}
        >
          {children}
        </Flex>
      )}
    </ClassNames>
  );
});
