import { ReactNode, createContext, useContext, useEffect, useId, useState } from 'react';
import { Box, BoxProps, Flex, TextareaProps, Divider, Grid, GridItem } from '@chakra-ui/react';
import { EditorContent } from '@tiptap/react';
import { Label, LabelProps } from '../../typography/Label';
import { Paragraph } from '../../typography/Paragraph';
import { Spinner } from '../../loading/Spinner';
import { Button, IconButton } from '../../buttons/Button';
import { Avatar } from '../../displays/Avatar';
import useRichTextEditor from '../../../hooks/useRichTextEditor';
import { EditorBubbleMenu } from './BubbleMenu';
import { InfoFilledIcon } from '@emochan-cabinet/icons';

export interface DialogueTextBoxContext {
  // HTML ID
  id?: string

  subtitle?: string,

  // 状態管理系
  isLoading?: boolean,
  isEditable?: boolean,
  isFocused?: boolean,
  isEmpty?: boolean,

  handleFocused(isFocused: boolean): void,
  handleUpdate?(val: string): void,
}

const DialogueTextBoxContext = createContext<DialogueTextBoxContext>({} as DialogueTextBoxContext);

export interface DialogueTextBoxProps extends TextareaProps, Omit<DialogueTextBoxContext, 'id' | 'isFocused' | 'handleFocused'> {}

export function DialogueTextBox({
  id,
  subtitle,
  isEditable = false,
  isLoading = false,
  isEmpty = false,
  children,
  as = 'section',
  handleUpdate,
  ...rest
}: DialogueTextBoxProps): JSX.Element {
  const rId = useId();
  const [isFocused, setFocused] = useState(false);
  const value = {
    id: id || rId,
    ...rest,
    subtitle,
    isLoading,
    isEditable,
    isFocused,
    isEmpty,
    handleUpdate,
    handleFocused: (focused: boolean) => {
      setFocused(focused);
    },
  };

  return (
    <DialogueTextBoxContext.Provider value={value}>
      <Box
        borderRadius="12"
        bg="grey.5"
        as={as}
        className="emochan-editor"
        flexGrow={1}
        boxShadow={value.boxShadow}
      >
        {children}
      </Box>
    </DialogueTextBoxContext.Provider>
  );
}

type AvatarsProps = { members: {name: string, avatarURL: string}[] };

DialogueTextBox.Avatars = function DialogueTextBoxAvatars({ members }: AvatarsProps) {
  const { isEmpty } = useContext(DialogueTextBoxContext);

  return (
    <Grid templateColumns={`repeat(${members.length}, 1.5rem)`} mr={members.length > 1 ? '-.25rem' : undefined}>
      {
        members.map((m, i) => (
          <GridItem
            key={m.name}
            ml={i === 1 ? '-.25rem' : undefined}
            pos={i === 0 ? 'relative' : undefined}
            zIndex={i === 0 ? 'normal' : undefined}
            opacity={isEmpty ? 0.5 : undefined}
          >
            <Avatar size="xs" imageURL={m.avatarURL} name={m.name} />
          </GridItem>
        ))
      }
    </Grid>
  );
};

interface DialogueTextBoxActionProps extends BoxProps {
  icon: JSX.Element,
  onClick: () => void,
  label: string,
};

DialogueTextBox.Action = function DialogueTextBoxAction({
  icon,
  onClick,
  label,
  ...rest
}: DialogueTextBoxActionProps) {
  const { isLoading } = useContext(DialogueTextBoxContext);

  return (
    <Box pos="absolute" right={0} px="6" {...rest}>
      <IconButton
        icon={icon}
        onClick={onClick}
        label={label}
        buttonStyle="ghost"
        size="medium"
        // disabled={isLoading}
      />
    </Box>
  );
};

interface DialogueTextBoxHeaderProps extends LabelProps {}

/**
 * DialogueTextBox.Header
 * patterns:
 * - avatars + subtitle
 * - avatars + subtitle + IconButton
 * - emotag
 */
DialogueTextBox.Header = function DialogueTextBoxHeader({ children, as = 'h3' }: DialogueTextBoxHeaderProps) {
  const { subtitle, isEmpty } = useContext(DialogueTextBoxContext);

  return (
    <Flex
      as="header"
      pos="relative"
      alignItems="center"
      gap="12"
      h="3.75rem"
      py="12"
      pl="16"
      pr="48"
    >
      {children}
      {subtitle && <Label as={as} size="medium" flexGrow={1} color={isEmpty ? 'grey.30' : undefined}>{subtitle}</Label>}
    </Flex>
  );
};

DialogueTextBox.Divider = function DialogueTextBoxDivider() {
  const { isFocused } = useContext(DialogueTextBoxContext);

  return <Divider borderColor={isFocused ? 'transparent' : undefined} />;
};

DialogueTextBox.Body = function DialogueTextBoxBody({ children }: {
  children?: ReactNode,
}) {
  const { isLoading } = useContext(DialogueTextBoxContext);

  return (
    <Box>
      { isLoading && <Box p="12" textAlign="center"><Spinner /></Box> }
      { !isLoading && children }
    </Box>
  );
};

/**
 * DialogueTextBox.Footer
 */
DialogueTextBox.Footer = function DialogueTextBoxFooter({ children }: LabelProps) {
  const { isEmpty, isEditable } = useContext(DialogueTextBoxContext);

  if (isEmpty || isEditable) return null;

  return (
    <Flex
      as="footer"
      alignItems="center"
      justifyContent="flex-end"
      h="3rem"
    >
      {children}
    </Flex>
  );
};

DialogueTextBox.BodyError = function DialogueTextBoxBodyError({
  message,
  action,
}: {
  message?: ReactNode,
  action?: ReactNode,
}) {
  return (
    <Flex gap="12" p="12" alignItems="center" justifyContent="center" flexDir="column">
      <InfoFilledIcon color="red.50" size="xxxl" />
      <Paragraph color="red.50" size="small">{message}</Paragraph>
      {action}
    </Flex>
  );
};

DialogueTextBox.Editable = function DialogueTextBoxEditable({
  htmlText,
  placeholder,
}: {
  htmlText: string,
  placeholder?: string,
}) {
  const replacedHtml = htmlText.replace('<p></p>', '');
  const {
    isEditable,
    handleFocused,
    handleUpdate,
  } = useContext(DialogueTextBoxContext);

  const { editor } = useRichTextEditor(
    replacedHtml,
    (body) => {
      handleUpdate?.(body);
    },
    () => {
      handleFocused(true);
    },
    () => {
      handleFocused(false);
    },
    false,
    placeholder,
  );

  useEffect(() => {
    if (isEditable) {
      editor?.setEditable(true);
    }
  }, [isEditable, editor, replacedHtml]);

  if (!isEditable) {
    return (
      <Paragraph
        as="div"
        className="emochan-memo"
        size="medium"
        color={!replacedHtml ? 'grey.60' : 'grey.90'}
        width="100%"
        maxWidth="100%"
        overflowX="auto"
        p="16"
        whiteSpace={!replacedHtml ? 'pre-wrap' : 'normal'}
        dangerouslySetInnerHTML={{
          __html: (!replacedHtml)
            ? placeholder || ''
            : replacedHtml,
        }}
      />
    );
  }

  return (
    <>
      <Paragraph
        className="emochan-memo"
        as="div"
        size="medium"
        color="grey.90"
        width="100%"
        maxWidth="100%"
        overflowX="auto"
        sx={{ caretColor: 'var(--emochan-colors-blue-50)' }}
      >
        <EditorContent editor={editor} />
      </Paragraph>
      <EditorBubbleMenu editor={editor} />
    </>
  );
};

DialogueTextBox.displayName = 'DialogueTextBox';
