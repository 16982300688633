import appConfigJa from '~/application/translations/app/ja.json';
import navigationJa from '~/application/translations/navigation/ja.json';
import authJa from '~/application/translations/auth/ja.json';
import authActionJa from '~/application/translations/auth/action/ja.json';
import authPasswordJa from '~/application/translations/auth/password/ja.json';
import errorJa from '~/application/translations/error/ja.json';
import emoJa from '~/application/translations/emo/ja.json';
import activityJa from '~/application/translations/activity/ja.json';
import invitationJa from '~/application/translations/invitation/ja.json';
import dialogueJa from '~/application/translations/1on1/dialogue/ja.json';
import memoJa from '~/application/translations/1on1/memo/ja.json';
import summaryJa from '~/application/translations/1on1/summary/ja.json';
import workspaceJa from '~/application/translations/workspace/ja.json';
import welcomeJa from '~/application/translations/workspace/welcome/ja.json';
import wslistJa from '~/application/translations/wslist/ja.json';

export default {
  ...appConfigJa,
  ...navigationJa,
  ...authJa,
  ...authActionJa,
  ...authPasswordJa,
  ...errorJa,
  ...emoJa,
  ...activityJa,
  ...invitationJa,
  ...dialogueJa,
  ...memoJa,
  ...summaryJa,
  ...workspaceJa,
  ...welcomeJa,
  ...wslistJa,
};
