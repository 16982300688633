import { cloneElement, forwardRef, useMemo } from 'react';
import { ClassNames } from '@emotion/react';
import { Flex, VisuallyHidden, useTheme, ResponsiveValue } from '@chakra-ui/react';
import { Label, LabelSize } from '../../typography/Label';
import { BeatLoader } from '../../loading/BeatLoader';

import { BGColorFoundations, FGColorFoundations, ColorFoundations, Colors, TonalBGColorFoundations, OutlineBorderColorFoundations } from '../../../system/styles';
import {
  ButtonSx,
  ButtonProps,
  ButtonSizes,
  ButtonShapes,
  ButtonStyles,
  ButtonLabelSizes,
  ButtonRadiuses,
  ButtonSize,
} from './ButtonBase';

type WidthProps = {
  floated?: boolean
  label?: string
  size?: ButtonSize | null,
  full?: boolean | null,
}

function getWidth({ floated, label, size, full } : WidthProps) {
  if (!label) {
    if (floated || size === ButtonSizes.xl) return '56';
    if (size === ButtonSizes.large) return '48';
    if (size === ButtonSizes.medium) return '40';
    if (size === ButtonSizes.small) return '32';

    return '24';
  }

  if (full) {
    return '100%';
  }

  if (size === ButtonSizes.xs) {
    return 'auto';
  }

  return 'auto';
}

function getHeight(size?: ButtonSize) {
  if (size === ButtonSizes.xl) {
    return '56';
  }
  if (size === ButtonSizes.large) {
    return '48';
  }
  if (size === ButtonSizes.medium) {
    return '40';
  }
  if (size === ButtonSizes.small) {
    return '32';
  }
  if (size === ButtonSizes.xs) {
    return '24';
  }

  return undefined;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  label,
  leftIcon,
  leftIconAlign,
  rightIcon,
  color = ColorFoundations.primary,
  size = ButtonSizes.large,
  shape = ButtonShapes.pill,
  buttonStyle = ButtonStyles.fill,
  disabled = false,
  isLoading = false,
  full = false,
  floated = false,
  as = 'button',
  noPadding,
  className,
  sx,
  ...props
}, ref) => {
  const { breakpoints } = useTheme();
  const buttonStyleType = buttonStyle;
  const filled = buttonStyleType === ButtonStyles.fill;
  const tonal = buttonStyleType === ButtonStyles.tonal;
  const pill = shape === ButtonShapes.pill;
  const iconOnly = !label;

  const accessaryElementMarginX = useMemo(() => {
    if (!label) return undefined;
    if (floated || size === ButtonSizes.xl) return '-0.5rem';
    if (size === ButtonSizes.large) return '-0.5rem';
    if (pill) return '-0.25rem';
    return undefined;
  }, [label, pill, floated, size]);

  const leftAccessary = useMemo(() => {
    if (!leftIcon) return null;

    const iconProps = { size: 'medium', fill: 'currentColor' };

    // ラベル有りボタン
    if (label) {
      if (size === ButtonSizes.small) iconProps.size = 'small';
      if (size === ButtonSizes.medium) iconProps.size = 'small';
      if (size === ButtonSizes.large) iconProps.size = 'large';
      if (size === ButtonSizes.xl) iconProps.size = 'large';
    }

    // アイコンオンリーはそのまま
    const iconComponent = !label ? leftIcon : cloneElement(leftIcon as JSX.Element, iconProps);

    return (
      <Flex
        as="span"
        display="inline-flex"
        marginLeft={accessaryElementMarginX}
        position={leftIconAlign === 'left' ? 'absolute' : undefined}
        pl={leftIconAlign === 'left' ? 'inherit' : undefined}
        left={leftIconAlign === 'left' ? '0' : undefined}
      >
        {iconComponent}
      </Flex>
    );
  }, [leftIcon, leftIconAlign, label, size, accessaryElementMarginX]);

  const rightAccessary = useMemo(() => {
    if (rightIcon === undefined) return null;

    const iconProps = { size: 'medium', fill: 'currentColor' };

    if (size === ButtonSizes.small || size === ButtonSizes.medium) {
      iconProps.size = 'small'; // べたがき〜
    }

    const iconComponent = cloneElement(rightIcon as JSX.Element, iconProps);

    return (
      <Flex as="span" display="inline-flex" marginRight={accessaryElementMarginX}>
        {iconComponent}
      </Flex>
    );
  }, [rightIcon, size, accessaryElementMarginX]);

  const buttonTextNode = useMemo(() => {
    if (label === undefined || label === '') return null;

    if (typeof size === 'string') {
      return (
        <Label as="span" size={ButtonLabelSizes[size]}>
          {label}
        </Label>
      );
    }

    if (Array.isArray(size)) {
      return (
        <Label as="span" size={size.map((s) => (s === null ? null : ButtonLabelSizes[s]))}>
          {label}
        </Label>
      );
    }

    const r: ResponsiveValue<LabelSize> = {};

    Object.entries(size).forEach(([key, value]) => {
      r[key] = value ? ButtonLabelSizes[value] : 'large';
    });

    return (
      <Label as="span" size={r}>
        {label}
      </Label>
    );
  }, [label, size]);

  const radius = useMemo(() => {
    if (iconOnly) return 'half';

    if (typeof size === 'string') {
      return ButtonRadiuses[shape][size];
    }

    if (Array.isArray(size)) {
      return size.map((s) => (s === null ? null : ButtonRadiuses[shape][s]));
    }

    const r: Record<string, string|undefined> = {};

    Object.entries(size).forEach(([key, value]) => {
      r[key] = value ? ButtonRadiuses[shape][value] : undefined;
    });

    return r;
  }, [iconOnly, shape, size]);

  const width = useMemo(() => {
    const r: Record<string, string|undefined> = {};

    if (typeof size === 'string') {
      if (typeof full === 'boolean') {
        return getWidth({ floated, label, size, full });
      }

      if (Array.isArray(full)) {
        return full.map((f) => (
          f === null
            ? null
            : getWidth({ floated, label, size, full: f }) || null
        ));
      }

      Object.entries(full).forEach(([key, value]) => {
        const val = getWidth({ floated, label, size, full: value });

        r[key] = val;
      });

      return r;
    }

    if (Array.isArray(size)) {
      if (typeof full === 'boolean') {
        return size.map((s) => (
          s === null
            ? null
            : getWidth({ floated, label, size: s, full }) || null
        ));
      }

      if (Array.isArray(full)) {
        return Object.keys(breakpoints)
          .map((_, i) => getWidth({ floated, label, size: size[i], full: full[i] }) || null);
      }

      return Object.keys(breakpoints)
        .map((k, i) => getWidth({ floated, label, size: size[i], full: full[k] }) || null);
    }

    if (typeof full === 'boolean') {
      Object.entries(size).forEach(([key, value]) => {
        if (!value) return;
        const val = getWidth({ floated, label, size: value, full });
        if (val === undefined) return;
        r[key] = val;
      });

      return r;
    }

    if (Array.isArray(full)) {
      return Object.keys(breakpoints)
        .map((k, i) => getWidth({ floated, label, size: size[k], full: full[i] }) || null);
    }

    return Object.keys(breakpoints)
      .map((k) => getWidth({ floated, label, size: size[k], full: full[k] }) || null);
  }, [floated, label, size, full, breakpoints]);

  const height = useMemo(() => {
    if (floated || size === ButtonSizes.xl) {
      return 56;
    }

    if (!size) return undefined;

    if (typeof size === 'string') {
      return getHeight(size);
    }
    if (Array.isArray(size)) {
      return size.map((s) => (s === null ? null : getHeight(s) || null));
    }

    const r: Record<string, string|undefined> = {};

    Object.entries(size).forEach(([key, value]) => {
      const val = getHeight(value);
      r[key] = val;
    });

    return r;
  }, [floated, size]);

  const paddingWrapperBlock = useMemo(() => {
    if (noPadding) return 0;
    if (size === ButtonSizes.xs) {
      return 12;
    }

    if (size === ButtonSizes.small) {
      return 8;
    }

    if (size === ButtonSizes.medium) {
      return 4;
    }
  }, [size, noPadding]);

  const paddingInline = useMemo(() => {
    if (floated || size === ButtonSizes.xl) {
      return 24;
    }

    if (size === ButtonSizes.large) {
      return 20;
    }

    if (size === ButtonSizes.medium) {
      return 16;
    }

    if (size === ButtonSizes.small) {
      return 12;
    }

    if (size === ButtonSizes.xs) {
      return 2;
    }

    return 16;
  }, [floated, size]);

  const afterHoverColor = useMemo(() => {
    if (disabled) return;
    if (filled) {
      if (color === ColorFoundations.primary) return 'transparent.white.8';
      if (color === ColorFoundations.secondary) return 'transparent.grey.4';
      if (color === ColorFoundations.accent) return 'transparent.white.8';
      if (color === ColorFoundations.negative) return 'transparent.white.8';
    }

    if (color === ColorFoundations.primary) return 'transparent.grey.4';
    if (color === ColorFoundations.secondary) return 'transparent.white.8';
    if (color === ColorFoundations.accent) return 'transparent.blue.4';
    if (color === ColorFoundations.negative) return 'transparent.red.4';
    if (color === ColorFoundations.positive) return 'transparent.green.4';
    if (color === ColorFoundations.warning) return 'transparent.yellow.4';

    return undefined;
  }, [color, filled, disabled]);

  const afterFocusColor = useMemo(() => {
    if (filled) {
      if (color === ColorFoundations.primary) return 'transparent.white.24';
      if (color === ColorFoundations.secondary) return 'transparent.grey.12';
      if (color === ColorFoundations.accent) return 'transparent.white.24';
      if (color === ColorFoundations.negative) return 'transparent.white.24';
    }

    if (color === ColorFoundations.primary) return 'transparent.grey.12';
    if (color === ColorFoundations.secondary) return 'transparent.white.24';
    if (color === ColorFoundations.accent) return 'transparent.blue.12';
    if (color === ColorFoundations.negative) return 'transparent.red.12';
    if (color === ColorFoundations.positive) return 'transparent.green.12';
    if (color === ColorFoundations.warning) return 'transparent.yellow.12';

    return undefined;
  }, [color, filled]);

  const fgColor = useMemo(() => {
    if (filled) {
      return FGColorFoundations[color];
    }

    if (size === ButtonSizes.xs && color === ColorFoundations.primary) {
      return Colors.grey60;
    }

    return BGColorFoundations[color];
  }, [size, color, filled]);

  const bgColor = useMemo(() => {
    if (filled) {
      return BGColorFoundations[color];
    }

    if (tonal) {
      return TonalBGColorFoundations[color];
    }
  }, [color, filled, tonal]);

  const gap = useMemo(() => {
    if (floated || size === ButtonSizes.xl) return 12;

    if (size === ButtonSizes.small) return 4;
    return 8;
  }, [floated, size]);

  const shadow = useMemo(() => {
    if (isLoading || disabled) return 'none';

    // return floated ? Shadows.middleBelow : undefined;
  }, [isLoading, disabled]);

  const styleProps = {
    bg: bgColor,
    borderRadius: radius,
    _before: {
      borderRadius: radius,
      boxShadow: (buttonStyleType === ButtonStyles.outline && !disabled) ? `inset 0 0 0 1px var(--emochan-colors-${OutlineBorderColorFoundations[color]}-20)` : undefined,
    },
    _after: { borderRadius: radius },
    _hover: { _after: { bg: afterHoverColor } },
    _focus: {
      outline: 'none',
      _after: { bg: afterFocusColor },
    },
  };

  const loadingProps = isLoading ? { 'data-loading': true } : {};

  return (
    <ClassNames>
      {({ cx }) => (
        <Flex display="inline-flex" py={paddingWrapperBlock} w={width}>
          <Flex
            sx={{ ...sx, ...ButtonSx }}
            position="relative"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            gap={gap}
            w={width}
            h={height}
            pl={paddingInline}
            pr={paddingInline}
            color={fgColor}
            whiteSpace="nowrap"
            userSelect="none"
            textDecoration="none"
            transition="opacity .5s ease-out"
            border="none"
            boxShadow={shadow}
            cursor={disabled ? 'not-allowed' : 'pointer'}
            ref={ref}
            as={as}
            className={cx(className, {
              '--fill': buttonStyle === ButtonStyles.fill,
              '--accent': color === ColorFoundations.accent && filled,
              '--primary': color === ColorFoundations.primary,
            })}
            aria-disabled={isLoading || disabled || undefined}
            {...styleProps}
            {...props}
            {...loadingProps}
          >
            { isLoading && (
              <Flex paddingInline={size === 'small' ? '16' : '20'}>
                <BeatLoader
                  type={!filled ? 'ghost' : 'fill'}
                  color={color}
                />
              </Flex>
            )}
            {!isLoading && leftAccessary}
            {!isLoading && buttonTextNode}
            {isLoading && <VisuallyHidden>{buttonTextNode}</VisuallyHidden>}
            {!isLoading && rightAccessary}
          </Flex>
        </Flex>
      )}
    </ClassNames>
  );
});

Button.displayName = 'Button';
