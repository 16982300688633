import { createIcon } from './create-icon';

export const GoogleIcon = createIcon({
  path: [
    <path
      d="M21.8003 12.2275C21.8003 11.5184 21.7366 10.8366 21.6185 10.182H12.2002V14.0503H17.5821C17.3502 15.3003 16.6457 16.3594 15.5866 17.0685V19.5776H18.8184C20.7094 17.8367 21.8003 15.273 21.8003 12.2275Z"
      fill="#4285F4"
    />,
    <path
      d="M12.2001 22C14.9001 22 17.1638 21.1045 18.8184 19.5772L15.5865 17.0681C14.691 17.6681 13.5456 18.0227 12.2001 18.0227C9.59555 18.0227 7.39098 16.2636 6.60461 13.8999H3.26367V16.4909C4.90914 19.7591 8.29099 22 12.2001 22Z"
      fill="#34A853"
    />,
    <path
      d="M6.60478 13.9C6.40478 13.3 6.29114 12.6591 6.29114 12C6.29114 11.3409 6.40478 10.7 6.60478 10.1V7.50908H3.26384C2.58656 8.85909 2.2002 10.3864 2.2002 12C2.2002 13.6137 2.58656 15.141 3.26384 16.491L6.60478 13.9Z"
      fill="#FBBC05"
    />,
    <path
      d="M12.2001 5.97731C13.6683 5.97731 14.9865 6.48186 16.0229 7.47278L18.8911 4.60457C17.1592 2.99092 14.8956 2 12.2001 2C8.29099 2 4.90914 4.24093 3.26367 7.50914L6.60461 10.1001C7.39098 7.73641 9.59555 5.97731 12.2001 5.97731Z"
      fill="#EA4335"
    />,
  ]
});
