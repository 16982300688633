// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const DocumentCrossIcon = createIcon({
  displayName: 'DocumentCrossIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M13 2H8.16146C7.63433 1.99998 7.17954 1.99997 6.80497 2.03057C6.40963 2.06287 6.01641 2.13419 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4.13419 4.01641 4.06287 4.40963 4.03057 4.80497C3.99997 5.17954 3.99998 5.63429 4 6.16142V17.8385C3.99998 18.3657 3.99997 18.8205 4.03057 19.195C4.06287 19.5904 4.13419 19.9836 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.01641 21.8658 6.40963 21.9371 6.80497 21.9694C7.17954 22 7.6343 22 8.16144 22H9C9.55229 22 10 21.5523 10 21C10 20.4477 9.55229 20 9 20H8.2C7.62345 20 7.25118 19.9992 6.96784 19.9761C6.69618 19.9539 6.59546 19.9162 6.54601 19.891C6.35785 19.7951 6.20487 19.6422 6.109 19.454C6.0838 19.4045 6.04613 19.3038 6.02393 19.0322C6.00078 18.7488 6 18.3766 6 17.8V6.2C6 5.62345 6.00078 5.25118 6.02393 4.96784C6.04613 4.69618 6.0838 4.59546 6.109 4.54601C6.20487 4.35785 6.35785 4.20487 6.54601 4.109C6.59546 4.0838 6.69618 4.04613 6.96784 4.02393C7.25118 4.00078 7.62345 4 8.2 4H12V7C12 8.65686 13.3431 10 15 10H18.2929C19.2357 10 20 9.2357 20 8.2929C20 7.84014 19.8201 7.40593 19.5 7.08579L15.4142 3C14.7739 2.35971 13.9055 2 13 2ZM14 7V4.41422L17.5858 8H15C14.4477 8 14 7.55229 14 7Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M18 16.585L15.7071 14.2929C15.3166 13.9024 14.6834 13.9024 14.2929 14.2929C13.9024 14.6834 13.9024 15.3166 14.2929 15.7071L16.585 18L14.2929 20.2929L14.2097 20.3871C13.9047 20.7794 13.9324 21.3466 14.2929 21.7071L14.3871 21.7903C14.7794 22.0953 15.3466 22.0676 15.7071 21.7071L18 19.415L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L19.415 18L21.7071 15.7071L21.7903 15.6129C22.0953 15.2206 22.0676 14.6534 21.7071 14.2929L21.6129 14.2097C21.2206 13.9047 20.6534 13.9324 20.2929 14.2929L18 16.585Z"
      />
    ),
  ],
});
