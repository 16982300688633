import { FC } from 'react';
import { Modal as ChakraModal, ModalProps, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ModalHeader, ModalBody, ModalDescription, ModalFooter } from './Modal';

export const ModalProvider: FC<ModalProps> & {
  Header: typeof ModalHeader
  Body: typeof ModalBody
  Description: typeof ModalDescription
  Footer: typeof ModalFooter
} = ({
  isOpen = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  children,
  ...props
}) => (
  <ChakraModal
    isOpen={isOpen}
    onClose={onClose}
    isCentered
    {...props}
  >
    <ModalOverlay />
    <ModalContent>
      {children}
    </ModalContent>
  </ChakraModal>
);

ModalProvider.Header = ModalHeader;
ModalProvider.Body = ModalBody;
ModalProvider.Description = ModalDescription;
ModalProvider.Footer = ModalFooter;
