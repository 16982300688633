import { useMemo } from 'react';
import { Flex, forwardRef } from '@chakra-ui/react';
import { Label } from '../../typography/Label';
import { useFormControl } from '.';

const BLOCK_NAME = 'FormLabel';

export const FormLabel = forwardRef<{ text: string }, 'label'>(({
  text,
  children,
}, ref) => {
  const { id, isDisabled } = useFormControl();

  const label = useMemo(() => (
    <Label as="label" htmlFor={id} color={isDisabled ? 'grey.30' : 'grey.90'} size="medium" ref={ref}>{text}</Label>
  ), [id, ref, text, isDisabled]);

  return (
    <Flex justify="space-between" gap="16" pb="8">
      {label}
      {children}
    </Flex>
  );
});

FormLabel.displayName = BLOCK_NAME;
