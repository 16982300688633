// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const AnalyticsIcon = createIcon({
  displayName: 'AnalyticsIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V8Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12C13 11.4477 12.5523 11 12 11Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M11 9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8 13C7.44772 13 7 13.4477 7 14V16C7 16.5523 7.44772 17 8 17C8.55228 17 9 16.5523 9 16V14C9 13.4477 8.55228 13 8 13Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M3.43597 5.18404C3 6.03969 3 7.15979 3 9.4V14.6C3 16.8402 3 17.9603 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C6.03969 21 7.15979 21 9.4 21H14.6C16.8402 21 17.9603 21 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C21 17.9603 21 16.8402 21 14.6V9.4C21 7.15979 21 6.03969 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C17.9603 3 16.8402 3 14.6 3H9.4C7.15979 3 6.03969 3 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404ZM14.6 5H9.4C8.24689 5 7.50235 5.00156 6.93567 5.04785C6.39235 5.09225 6.19091 5.1676 6.09202 5.21799C5.71569 5.40973 5.40973 5.71569 5.21799 6.09202C5.1676 6.19091 5.09225 6.39235 5.04785 6.93567C5.00156 7.50235 5 8.24689 5 9.4V14.6C5 15.7531 5.00156 16.4977 5.04785 17.0643C5.09225 17.6077 5.1676 17.8091 5.21799 17.908C5.40973 18.2843 5.71569 18.5903 6.09202 18.782C6.19091 18.8324 6.39235 18.9078 6.93567 18.9521C7.50235 18.9984 8.24689 19 9.4 19H14.6C15.7531 19 16.4977 18.9984 17.0643 18.9521C17.6077 18.9078 17.8091 18.8324 17.908 18.782C18.2843 18.5903 18.5903 18.2843 18.782 17.908C18.8324 17.8091 18.9078 17.6077 18.9521 17.0643C18.9984 16.4977 19 15.7531 19 14.6V9.4C19 8.24689 18.9984 7.50235 18.9521 6.93567C18.9078 6.39235 18.8324 6.19091 18.782 6.09202C18.5903 5.71569 18.2843 5.40973 17.908 5.21799C17.8091 5.1676 17.6077 5.09225 17.0643 5.04785C16.4977 5.00156 15.7531 5 14.6 5Z"
      />
    ),
  ],
});
