// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const ExitIcon = createIcon({
  displayName: 'ExitIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M10 3H7.7587C6.95374 2.99999 6.28937 2.99998 5.74818 3.04419C5.18608 3.09012 4.66937 3.18868 4.18404 3.43598C3.43139 3.81947 2.81947 4.43139 2.43598 5.18404C2.18868 5.66937 2.09012 6.18608 2.04419 6.74818C1.99998 7.28937 1.99999 7.95372 2 8.75869V15.2413C1.99999 16.0463 1.99998 16.7106 2.04419 17.2518C2.09012 17.8139 2.18868 18.3306 2.43598 18.816C2.81947 19.5686 3.43139 20.1805 4.18404 20.564C4.66937 20.8113 5.18608 20.9099 5.74818 20.9558C6.28936 21 6.95372 21 7.75868 21H10C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19H7.8C6.94342 19 6.36113 18.9992 5.91104 18.9624C5.47262 18.9266 5.24842 18.8617 5.09202 18.782C4.7157 18.5903 4.40973 18.2843 4.21799 17.908C4.1383 17.7516 4.07337 17.5274 4.03755 17.089C4.00078 16.6389 4 16.0566 4 15.2V8.8C4 7.94342 4.00078 7.36113 4.03755 6.91104C4.07337 6.47262 4.1383 6.24842 4.21799 6.09202C4.40973 5.7157 4.7157 5.40973 5.09202 5.21799C5.24842 5.1383 5.47262 5.07337 5.91104 5.03755C6.36113 5.00078 6.94342 5 7.8 5H10C10.5523 5 11 4.55229 11 4C11 3.44772 10.5523 3 10 3Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M16.3 7.69997C15.91 8.08997 15.91 8.70997 16.3 9.09997L18.2 11H9C8.45 11 8 11.45 8 12C8 12.55 8.45 13 9 13H18.2L16.3 14.9C15.91 15.29 15.91 15.91 16.3 16.3C16.69 16.69 17.31 16.69 17.7 16.3L21.29 12.71C21.68 12.32 21.68 11.69 21.29 11.3L17.7 7.69997C17.31 7.30997 16.69 7.30997 16.3 7.69997Z"
      />
    ),
  ],
});
