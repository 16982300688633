// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const CabinetIcon = createIcon({
  displayName: 'CabinetIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M5.56811 3C5.31573 2.99997 5.06993 2.99994 4.86178 3.01695C4.63318 3.03563 4.36345 3.07969 4.09202 3.21799C3.7157 3.40974 3.40974 3.7157 3.21799 4.09202C3.07969 4.36345 3.03563 4.63318 3.01695 4.86178C2.99994 5.06993 2.99997 5.31572 3 5.56809L3 8.4319C2.99997 8.68428 2.99994 8.93008 3.01695 9.13824C3.03563 9.36683 3.07969 9.63656 3.21799 9.90799C3.40974 10.2843 3.7157 10.5903 4.09202 10.782C4.36345 10.9203 4.63318 10.9644 4.86178 10.9831C5.06992 11.0001 5.3157 11 5.56807 11H8.4319C8.68427 11 8.93009 11.0001 9.13824 10.9831C9.36683 10.9644 9.63656 10.9203 9.90799 10.782C10.2843 10.5903 10.5903 10.2843 10.782 9.90799C10.9203 9.63656 10.9644 9.36683 10.9831 9.13824C11.0001 8.93009 11 8.68431 11 8.43194V5.56811C11 5.31574 11.0001 5.06992 10.9831 4.86178C10.9644 4.63318 10.9203 4.36345 10.782 4.09202C10.5903 3.7157 10.2843 3.40974 9.90799 3.21799C9.63656 3.07969 9.36683 3.03563 9.13824 3.01695C8.93008 2.99994 8.68429 2.99997 8.43192 3L5.56811 3ZM5.01031 5.02464L5.01145 5.01145L5.02464 5.01031C5.14122 5.00078 5.30348 5.00001 5.60001 5.00001H8.40001C8.69653 5.00001 8.85879 5.00078 8.97537 5.01031L8.98856 5.01145L8.9897 5.02464C8.99923 5.14122 9.00001 5.30348 9.00001 5.60001V8.40001C9.00001 8.69653 8.99923 8.85879 8.9897 8.97537L8.98856 8.98856L8.97537 8.9897C8.85879 8.99923 8.69653 9.00001 8.40001 9.00001H5.60001C5.30348 9.00001 5.14122 8.99923 5.02464 8.9897L5.01145 8.98856L5.01031 8.97537C5.00078 8.85879 5.00001 8.69653 5.00001 8.40001V5.60001C5.00001 5.30348 5.00078 5.14122 5.01031 5.02464Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M5.56811 13C5.31572 13 5.06993 12.9999 4.86178 13.017C4.63318 13.0356 4.36345 13.0797 4.09202 13.218C3.7157 13.4097 3.40974 13.7157 3.21799 14.092C3.07969 14.3634 3.03563 14.6332 3.01695 14.8618C2.99994 15.0699 2.99997 15.3157 3 15.5681L3 18.4319C2.99997 18.6843 2.99994 18.9301 3.01695 19.1382C3.03563 19.3668 3.07969 19.6366 3.21799 19.908C3.40974 20.2843 3.7157 20.5903 4.09202 20.782C4.36345 20.9203 4.63318 20.9644 4.86178 20.9831C5.06993 21.0001 5.31572 21 5.56811 21H8.4319C8.68429 21 8.93008 21.0001 9.13824 20.9831C9.36683 20.9644 9.63656 20.9203 9.90799 20.782C10.2843 20.5903 10.5903 20.2843 10.782 19.908C10.9203 19.6366 10.9644 19.3668 10.9831 19.1382C11.0001 18.9301 11 18.6843 11 18.4319V15.5681C11 15.3157 11.0001 15.0699 10.9831 14.8618C10.9644 14.6332 10.9203 14.3634 10.782 14.092C10.5903 13.7157 10.2843 13.4097 9.90799 13.218C9.63656 13.0797 9.36683 13.0356 9.13824 13.017C8.93008 12.9999 8.68429 13 8.4319 13H5.56811ZM5.01031 15.0246L5.01145 15.0115L5.02464 15.0103C5.14122 15.0008 5.30348 15 5.60001 15H8.40001C8.69653 15 8.85879 15.0008 8.97537 15.0103L8.98856 15.0115L8.9897 15.0246C8.99923 15.1412 9.00001 15.3035 9.00001 15.6V18.4C9.00001 18.6965 8.99923 18.8588 8.9897 18.9754L8.98856 18.9886L8.97537 18.9897C8.85879 18.9992 8.69653 19 8.40001 19H5.60001C5.30348 19 5.14122 18.9992 5.02464 18.9897L5.01145 18.9886L5.01031 18.9754C5.00078 18.8588 5.00001 18.6965 5.00001 18.4V15.6C5.00001 15.3035 5.00078 15.1412 5.01031 15.0246Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M15.5681 3H18.4319C18.6843 2.99997 18.9301 2.99994 19.1382 3.01695C19.3668 3.03563 19.6366 3.07969 19.908 3.21799C20.2843 3.40974 20.5903 3.7157 20.782 4.09202C20.9203 4.36345 20.9644 4.63318 20.9831 4.86178C21.0001 5.06993 21 5.31572 21 5.56811V8.4319C21 8.68429 21.0001 8.93008 20.9831 9.13824C20.9644 9.36683 20.9203 9.63656 20.782 9.90799C20.5903 10.2843 20.2843 10.5903 19.908 10.782C19.6366 10.9203 19.3668 10.9644 19.1382 10.9831C18.9301 11.0001 18.6843 11 18.4319 11H15.5681C15.3157 11 15.0699 11.0001 14.8618 10.9831C14.6332 10.9644 14.3634 10.9203 14.092 10.782C13.7157 10.5903 13.4097 10.2843 13.218 9.90799C13.0797 9.63656 13.0356 9.36683 13.017 9.13824C12.9999 8.93008 13 8.68429 13 8.4319V5.56811C13 5.31572 12.9999 5.06993 13.017 4.86178C13.0356 4.63318 13.0797 4.36345 13.218 4.09202C13.4097 3.7157 13.7157 3.40974 14.092 3.21799C14.3634 3.07969 14.6332 3.03563 14.8618 3.01695C15.0699 2.99994 15.3157 2.99997 15.5681 3ZM15.0115 5.01145L15.0103 5.02464C15.0008 5.14122 15 5.30348 15 5.60001V8.40001C15 8.69653 15.0008 8.85879 15.0103 8.97537L15.0115 8.98856L15.0246 8.9897C15.1412 8.99923 15.3035 9.00001 15.6 9.00001H18.4C18.6965 9.00001 18.8588 8.99923 18.9754 8.9897L18.9886 8.98856L18.9897 8.97537C18.9992 8.85879 19 8.69653 19 8.40001V5.60001C19 5.30348 18.9992 5.14122 18.9897 5.02464L18.9886 5.01145L18.9754 5.01031C18.8588 5.00078 18.6965 5.00001 18.4 5.00001H15.6C15.3035 5.00001 15.1412 5.00078 15.0246 5.01031L15.0115 5.01145Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M15.5681 13C15.3157 13 15.0699 12.9999 14.8618 13.017C14.6332 13.0356 14.3634 13.0797 14.092 13.218C13.7157 13.4097 13.4097 13.7157 13.218 14.092C13.0797 14.3634 13.0356 14.6332 13.017 14.8618C12.9999 15.0699 13 15.3157 13 15.5681V18.4319C13 18.6843 12.9999 18.9301 13.017 19.1382C13.0356 19.3668 13.0797 19.6366 13.218 19.908C13.4097 20.2843 13.7157 20.5903 14.092 20.782C14.3634 20.9203 14.6332 20.9644 14.8618 20.9831C15.0699 21.0001 15.3157 21 15.5681 21H18.4319C18.6843 21 18.9301 21.0001 19.1382 20.9831C19.3668 20.9644 19.6366 20.9203 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C20.9203 19.6366 20.9644 19.3668 20.9831 19.1382C21.0001 18.9301 21 18.6843 21 18.4319V15.5681C21 15.3157 21.0001 15.0699 20.9831 14.8618C20.9644 14.6332 20.9203 14.3634 20.782 14.092C20.5903 13.7157 20.2843 13.4097 19.908 13.218C19.6366 13.0797 19.3668 13.0356 19.1382 13.017C18.9301 12.9999 18.6843 13 18.4319 13H15.5681ZM15.0103 15.0246L15.0115 15.0115L15.0246 15.0103C15.1412 15.0008 15.3035 15 15.6 15H18.4C18.6965 15 18.8588 15.0008 18.9754 15.0103L18.9886 15.0115L18.9897 15.0246C18.9992 15.1412 19 15.3035 19 15.6V18.4C19 18.6965 18.9992 18.8588 18.9897 18.9754L18.9886 18.9886L18.9754 18.9897C18.8588 18.9992 18.6965 19 18.4 19H15.6C15.3035 19 15.1412 18.9992 15.0246 18.9897L15.0115 18.9886L15.0103 18.9754C15.0008 18.8588 15 18.6965 15 18.4V15.6C15 15.3035 15.0008 15.1412 15.0103 15.0246Z"
      />
    ),
  ],
});
