import { cloneElement, useMemo } from 'react';
import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { IconSize } from '@emochan-cabinet/icons';
import { useFormControl } from '../FormControl';
import { Colors } from '../../../system/styles';
import { getChildren } from '../../../utils/ReactChildren';

export interface TextFieldElementProps extends FlexProps {
  placement: 'left' | 'right'
}

export const TextFieldElement = forwardRef<TextFieldElementProps, 'div'>(({
  placement,
  bottom,
  children,
  ...props
}, ref) => {
  const { isDisabled, isInvalid, isValid } = useFormControl();
  const color = useMemo(() => {
    if (isDisabled) return Colors.grey30;
    if (isInvalid) return Colors.red50;
    if (isValid) return Colors.green50;

    return Colors.grey90;
  }, [isDisabled, isInvalid, isValid]);

  if (isDisabled && placement === 'right') {
    return null;
  }

  return (
    <Flex
      ref={ref}
      color={color}
      alignItems="center"
      justifyContent="center"
      position="absolute"
      zIndex="normal"
      left={placement === 'left' ? 0 : undefined}
      right={placement === 'right' ? 0 : undefined}
      bottom={bottom || 0}
      {...props}
    >
      {children}
    </Flex>
  );
});

export const TextFieldLeft = forwardRef<FlexProps, 'div'>(({
  children,
  ...props
}, ref) => (
  <TextFieldElement placement="left" {...props} ref={ref} p="12" pl="16">
    {children}
  </TextFieldElement>
));

TextFieldLeft.displayName = 'TextFieldLeft';

interface TextFieldRightProps extends FlexProps {
  size?: IconSize
}

export const TextFieldRight = forwardRef<TextFieldRightProps, 'div'>(({
  children,
  size = 'xs',
  ...props
}, ref) => {
  const buttons = getChildren(children);
  const customChildren = buttons.map((child) => (
    <Flex display="inline-flex" alignItems="center" justifyContent="center" w="48" h="48" key={child.key}>
      {cloneElement(child, { size })}
    </Flex>
  ));

  return (
    <TextFieldElement
      placement="right"
      h="48"
      ref={ref}
      {...props}
    >
      {customChildren}
    </TextFieldElement>
  );
});

TextFieldRight.displayName = 'TextFieldRight';
