import { useMemo, TextareaHTMLAttributes } from 'react';
import { forwardRef, Textarea as ChakraTextarea } from '@chakra-ui/react';
import { useFormControl, useFormTextInput } from '../FormControl';
import { TextFieldStyle, TextFieldSx } from './TextFieldStyle';

type TextareaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'disabled' | 'required'>;

export const Textarea = forwardRef<TextareaProps, 'textarea'>(({
  maxLength,
  ...props
}, ref) => {
  const initialMax = maxLength;
  const initialValue = props.value as string || '';

  // 判定は FormControl から注入する
  const {
    id,
    isDisabled,
    isInvalid,
    isValid,
    isRequired,
  } = useFormControl();
  const { feedbackId, onInput } = useFormTextInput<HTMLTextAreaElement>(initialValue, initialMax);

  const requiredProps = useMemo(() => {
    if (isRequired) {
      return {
        'aria-required': true,
        required: true,
      };
    }

    return {};
  }, [isRequired]);

  const invalidProps = useMemo(() => {
    if (isInvalid) {
      return {
        'aria-invalid': true,
        'aria-describedby': feedbackId,
      };
    }

    if (isValid) {
      return { 'aria-invalid': false };
    }

    return {};
  }, [feedbackId, isInvalid, isValid]);

  const textareaProps = {
    ...props,
    ...requiredProps,
    ...invalidProps,
  };

  return (
    <ChakraTextarea
      {...TextFieldStyle}
      paddingBlock="16"
      sx={TextFieldSx}
      id={props.id || id}
      {...textareaProps}
      ref={ref}
      onInput={onInput}
      disabled={isDisabled || props.disabled}
    />
  );
});

Textarea.displayName = 'Input';
