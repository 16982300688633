import { cloneElement } from 'react';
import { Flex, BoxProps, forwardRef, ComponentWithAs } from '@chakra-ui/react';
import { ListItemImage } from './ListItemImage';
import { ListItemContent } from './ListItemContent';
import { getChildren } from '../../../../utils/ReactChildren';

export const ListItem = forwardRef<BoxProps, 'div'>(({
  children,
  ...props
}, ref) => {
  const ListItemChildren = getChildren(children);

  let hasImage = false;

  const customChildren = ListItemChildren.map((child) => {
    // child component 判定
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const name = (child.type as any).displayName;

    if (name === 'ListItemImage') {
      hasImage = true;

      return child;
    }

    if (name === 'ListItemContent' && hasImage) {
      // padding 消したいだけ
      return cloneElement(child, { pl: 0 });
    }

    return child;
  });

  return (
    <Flex
      ref={ref}
      justifyContent="space-between"
      alignItems="stretch"
      w="100%"
      transition="background-color 80ms"
      _hover={{ backgroundColor: 'transparent.grey.4' }}
      _focus={{
        backgroundColor: 'transparent.grey.12',
        outline: 'none',
      }}
      _active={{
        bg: 'transparent.grey.8',
        outline: 'none',
        pointer: 'grabbing',
      }}
      sx={{
        '&.-draggable': { cursor: 'grab' },
        '&.-draggable:active': { cursor: 'grabbing' },
      }}
      {...props}
    >
      {customChildren}
    </Flex>
  );
}) as ComponentWithAs<'div', BoxProps> & {
  Image: typeof ListItemImage;
  Content: typeof ListItemContent;
};

ListItem.displayName = 'List.Item';

ListItem.Image = ListItemImage;
ListItem.Content = ListItemContent;
