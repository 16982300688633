import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { connectMicrophone } from './lib';

// 描画の設定
const styles = {
  lineWidth: 6,
  lineSpacing: 12,
  maxVolumeHeight: 0, // 最大線の高さをスケールに合わせて調整
};

export function VolumeVisualizer({
  stream,
  muted = false,
  isDouble = false,
}: {
  stream?: MediaStream | null,
  muted?: boolean,
  isDouble?: boolean,
}) {
  const [disabled, setDisabled] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  // デバイスのピクセル比に基づいてキャンバスのサイズを設定
  const dpr = useRef(2);
  const timerRef = useRef<number | null>(null);
  const lines: number[] = [...new Array(100).keys()].map(() => 0);
  let currentVolumes = useRef<number[]>([0]);
  let lastDrawTime = 0;

  useEffect(() => {
    if (!navigator.mediaDevices || !window.AudioContext) {
      setDisabled(true);
    }
    // デバイスのピクセル比に基づいてキャンバスのサイズを設定
    dpr.current = window.devicePixelRatio || 2;
  }, []);

  useEffect(() => {
    if (stream) {
      connectMicrophone(stream, (n) => {
        try {
          currentVolumes.current.push(n);
        } catch (e) {
          console.debug(currentVolumes.current)
          console.debug(e);
        }
      }, isDouble);
    }
  }, [stream]);

  // Main visualization function
  const visualizeMicrophoneInput = useCallback(async (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    muted: boolean,
  ) => {
    cancelAnimationFrame(timerRef.current!);
    // グラデーションを設定
    const gradient = ctx.createLinearGradient(0, 0, 0, 100);
    gradient.addColorStop(0, muted ? '#B3B4B7' : '#1238FF');
    gradient.addColorStop(1, muted ? '#B3B4B7' : '#3369FF');

    try {
      function draw() {
        timerRef.current = requestAnimationFrame(draw);
        const currentTime = Date.now();

        // 前回の描画からNms以上経過しているか確認
        if (currentTime - lastDrawTime > (1000 / 12)) {
          lastDrawTime = currentTime;

          const excludeZero = currentVolumes.current.filter((a) => a !== 0) || [0];
          const average = excludeZero.reduce((a, b) => a + b, 0) / excludeZero.length || 0;

          const lineHeight = Math.max(1, Math.floor(average) / 128 * styles.maxVolumeHeight);

          lines.push(average === 0 ? 0.01 : lineHeight);

          currentVolumes.current = [0]; // 音量の配列をリセット

          // 線のリストが長すぎる場合は、最初の要素を削除
          if (lines.length * (styles.lineWidth + styles.lineSpacing) > canvas.width / dpr.current) {
            lines.shift();
          }
        }

        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height); // キャンバスをクリア

          // 線を描画
          ctx.lineCap = 'round'; // 線の端を丸める

          for (let i = lines.length - 1; i >= 0; i--) {
            const x = canvas.width / dpr.current - ((lines.length - i) * (styles.lineWidth + styles.lineSpacing));
            const y = canvas.height / (2 * dpr.current);
            
            ctx.beginPath();
            ctx.moveTo(x, y - lines[i] / 2);
            ctx.lineTo(x, y + lines[i] / 2);
            ctx.strokeStyle = gradient; // グラデーションを適用
            ctx.lineWidth = styles.lineWidth;
            ctx.stroke();
          }
        }
      }

      draw();
    } catch (e) {
      console.debug(e)
    }
  }, []);

  const handleResize = useCallback(async () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (!canvas || !ctx) return;
    canvas.width = parseInt(getComputedStyle(canvas).width) * dpr.current;
    canvas.height = parseInt(getComputedStyle(canvas).height) * dpr.current;

    ctx.scale(dpr.current, dpr.current);

    styles.maxVolumeHeight = canvas.height / 2 - 16; // 最大線の高さをスケールに合わせて調整
  }, []);

  useEffect(() => {
    if (disabled) return;
    if (canvasRef.current === null) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    // デバイスのピクセル比に基づいてキャンバスのサイズを設定
    const dpr = window.devicePixelRatio || 1;

    if (!ctx) return;

    canvas.width = parseInt(getComputedStyle(canvas).width) * dpr;
    canvas.height = parseInt(getComputedStyle(canvas).height) * dpr;
    styles.maxVolumeHeight = canvas.height / 2 - 16

    ctx?.scale(dpr, dpr); // キャンバスの描画コンテキストをスケーリング

    visualizeMicrophoneInput(canvas, ctx, muted);

    if (muted) {
      stream?.getTracks().forEach((track) => track.enabled = false);
    } else {
      stream?.getTracks().forEach((track) => track.enabled = true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [muted]);

  return (
    <Flex pos="relative" w="100%" alignItems="center">
      <Box as="canvas" ref={canvasRef} width="100%"  h="33.33%" />
      <Box pos="absolute" inset={0} zIndex={1} bgImage="linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)" />
    </Flex>
  )
}
