import { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { Label } from '../../typography/Label';

interface ThemeCardProps extends FlexProps {
  isSelected?: boolean
  label: string
  readonly: boolean
}

/**
 * @deprecated
 * see also: https://www.figma.com/file/u3BG13Svf44oWQfNdT8IoG/%F0%9F%97%84-emochan-cabinet?node-id=2757%3A22010
 */
export const ThemeCard: FC<ThemeCardProps> = ({ isSelected, label, readonly, ...props }) => (
  <Flex
    display="inline-flex"
    as="button"
    type="button"
    pos={isSelected ? 'relative' : 'static'}
    p="4"
    borderWidth="4px"
    borderStyle="solid"
    borderRadius="32"
    borderColor={isSelected ? 'blue.50' : 'transparent'}
    _hover={readonly ? { cursor: 'initial' } : { borderColor: isSelected ? 'blue.50' : 'transparent.grey.10' }}
    _focus={readonly ? undefined : { borderColor: isSelected ? 'blue.50' : 'transparent.grey.30', outline: 'none' }}
    {...props}
    aria-disabled={readonly}
  >
    <Label
      as="span"
      size="large"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      align="center"
      bg={isSelected ? 'gradient.blue' : 'blue.5'}
      borderColor="blue.10"
      borderWidth={isSelected ? 0 : '0.25rem'}
      color={isSelected ? 'grey.0' : 'blue.50'}
      w="13.5rem"
      h="9.75rem"
      p="16"
      whiteSpace="pre-wrap"
      borderRadius="24"
      shadow={isSelected ? 'high.below' : 'low.below'}
      zIndex="normal"
    >
      {label}
    </Label>
  </Flex>
);
