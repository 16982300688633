import { forwardRef } from 'react';
import { Flex, VisuallyHidden } from '@chakra-ui/react';
import { EmoFeeling, EmoColors } from '../../../system/styles';
import { Avatar } from '../../displays/Avatar';
import { Label } from '../../typography/Label';

const BLOCK_NAME = 'MemberEmoTag';

export type MemberEmoTagProps = {
  name: string,
  avatarURL: string,
  emo: EmoFeeling,
  onomatope: string,
};

export const MemberEmoTag = forwardRef<HTMLDivElement, MemberEmoTagProps>(({
  name,
  avatarURL,
  emo,
  onomatope,
  ...rest
}, ref) => (
  <Flex
    ref={ref}
    as="span"
    display="inline-flex"
    alignItems="center"
    gap="8"
    pt="6"
    pb="6"
    pl="6"
    pr="12"
    bg={`gradient.${EmoColors[emo]}`}
    borderRadius="full"
    sx={{ '::selection': { backgroundColor: 'var(--emochan-colors-grey-0)' } }}
    {...rest}
  >
    <Avatar aria-hidden="true" size="xs" name={name} imageURL={avatarURL} />
    <VisuallyHidden>{name}</VisuallyHidden>
    <Label size="medium" color="grey.0" as="span">{onomatope}</Label>
  </Flex>
));

MemberEmoTag.displayName = BLOCK_NAME;
