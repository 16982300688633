import type { FC } from 'react';
import Script from 'next/script';
import { GA_TRACKING_ID, hasAnalyticsId } from '~/lib/gtag';

// Global Site Tag (gtag.js) - Google Analytics
const GtagInit: FC = () => (
  <>
    {hasAnalyticsId && (
      <>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          strategy="afterInteractive"
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </>
    )}
  </>
);

export default GtagInit;
