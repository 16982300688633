// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const MemberCrossIcon = createIcon({
  displayName: 'MemberCrossIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M16.413 14.999L17.999 13.413L19.586 15C19.9765 15.3905 20.6095 15.3905 21 15C21.3905 14.6096 21.3905 13.9765 21 13.586L19.414 12L21 10.414C21.3905 10.0236 21.3905 9.39049 21 9.00003C20.6095 8.60956 19.9765 8.60956 19.586 9.00003L18 10.586L16.414 9.00003C16.0235 8.60956 15.3905 8.60956 15 9.00003C14.6095 9.39049 14.6095 10.0236 15 10.414L16.585 11.999L14.999 13.585C14.6085 13.9755 14.6085 14.6086 14.999 14.999C15.3895 15.3895 16.0225 15.3895 16.413 14.999Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8 15C10.2091 15 12 16.7909 12 19C12 19.5523 12.4477 20 13 20C13.5523 20 14 19.5523 14 19C14 15.6863 11.3137 13 8 13C4.68629 13 2 15.6863 2 19C2 19.5523 2.44772 20 3 20C3.55228 20 4 19.5523 4 19C4 16.7909 5.79086 15 8 15Z"
      />
    ),
  ],
});
