const WORKLET_NAME = 'get-volume';
const AUDIO_SCRIPT = `
const SMOOTHING_FACTOR = 1;

class GetVolumeProcessor extends AudioWorkletProcessor {
  static get parameterDescriptors () {
    return [{
      name: 'volume',
      defaultValue: 0,
      minValue: 0,
      maxValue: 1,
      automationRate: "k-rate"
    }];
  }

  constructor(context) {
    super();
    this._options = context.processorOptions;
  }

  process(inputs, outputs, parameters) {
    const input = inputs[0];
    let volume = 0;
    const double = this._options.isDouble ? 6 : 1.5;

    if (input && input.length > 0) {
      const samples = input[0];
      let sum = 0;
      for (let i = 0; i < samples.length; ++i) {
        sum += (samples[i] * samples[i]);
      }

      const rms = Math.sqrt(sum / samples.length);
      volume = Math.max(rms, volume * SMOOTHING_FACTOR) * 180 * double;
    }

    if (volume <= 3) {
      volume = 0;
    } else if (volume > 100) {
      volume = 100;
    }

    // メインスレッドに音量を送信します。
    this.port.postMessage({ volume, input, parameters, options: this._options });

    // オーディオ処理を続けるためにtrueを返します。
    return true;
  }
}

registerProcessor('${WORKLET_NAME}', GetVolumeProcessor);
`;

export async function connectMicrophone(
  stream: MediaStream,
  onMessage: (n: number) => void,
  isDouble?: boolean,
): Promise<void> {
  // @ts-expect-error webkitAudio
  const AC = window.AudioContext || window.webkitAudioContext;
  const audioContext = new AC();

  // AudioWorkletProcessorを登録
  await audioContext.audioWorklet.addModule(
    'data:text/javascript,'
    + encodeURI(AUDIO_SCRIPT)
  );

  const volumeProcessorNode = new AudioWorkletNode(
    audioContext,
    WORKLET_NAME,
    { processorOptions: { isDouble } },
  );

  volumeProcessorNode.port.onmessage = (event) => {
    if (event.data.volume > 0) {
      // console.log(event.data)
    }
    onMessage(event.data.volume);
  };

  const input = audioContext.createMediaStreamSource(stream);
  input.connect(volumeProcessorNode)
}
