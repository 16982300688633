import { ReactNode, forwardRef, useMemo } from 'react';
import { CheckBoxBlankIcon, CheckBoxIndeterminateFilledIcon, CheckBoxSelectedFilledIcon, CheckIcon, ChevDIcon } from '@emochan-cabinet/icons';
import { Checkbox as ChakraCheckbox, CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react';
import { useFormControl } from '../FormControl';
import { Paragraph } from '../../typography/Paragraph';

interface CheckboxProps extends ChakraCheckboxProps {
  label: string | ReactNode
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  label,
  color = 'grey.60',
  ...props
}, ref) => {
  function CustomIcon(props: ChakraCheckboxProps) {
    const { isIndeterminate, isChecked } = props;
  
    if (isIndeterminate) {
      return <CheckBoxIndeterminateFilledIcon color="currentColor" />;
    }
  
    if (isChecked) {
      return <CheckBoxSelectedFilledIcon color="blue.50" />;
    }
  
    return <CheckBoxBlankIcon color="currentColor" />;
  }

  // 判定は FormControl から注入する
  const {
    id,
    isDisabled,
    isInvalid,
    isValid,
    isRequired,
  } = useFormControl();

  const requiredProps = useMemo(() => {
    if (isRequired) return { isRequired };

    return {};
  }, [isRequired]);

  const invalidProps = useMemo(() => {
    if (isInvalid) {
      return {
        isInvalid: true,
        'aria-describedby': `${id}-feedback`,
      };
    }

    if (isValid) return { _hover: { bg: 'green.5' } };

    return {};
  }, [isInvalid, isValid, id]);

  const styleProps = {
    ...props,
    ...requiredProps,
    ...invalidProps,
  };

  return (
    <ChakraCheckbox
      ref={ref}
      id={props.id || id}
      icon={<CustomIcon />}
      transition="background-color 0.1s ease-in-out"
      _hover={{ bg: 'transparent.grey.4' }}
      _active={{ bg: 'transparent.grey.12' }}
      py="12"
      borderRadius={12}
      color={color}
      {...styleProps}
      {...props}
    >
      <Paragraph as="span" size="small">{label}</Paragraph>
    </ChakraCheckbox>
  );
});

Checkbox.displayName = 'Checkbox';
