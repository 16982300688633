import { Recorder } from 'vmsg';
import { EE, eventEmit } from './ee';

const MIME_TYPE = 'audio/mpeg';

export interface RecorderObject {
  ee: EE<string>,
  isRecording: boolean;
  init(): Promise<void>;
  start(): void;
  stop(): Promise<void>;
}

export function createRecorder(
  onStop: (audio: string) => Promise<void>,
): RecorderObject | undefined {
  if (typeof window !== 'object') return;

  const recorder = new Recorder({ wasmURL: 'https://unpkg.com/vmsg@0.4.0/vmsg.wasm' });

  return {
    ee: eventEmit<string>(),
    isRecording: false,
    async init() {
      this.ee.on(async (key) => {
        console.debug(`[ASR] ${key}`)
        if (key === 'stop') {
          const blob = await recorder.stopRecording();
          this.isRecording = false;
          const reader = new FileReader();

          reader.addEventListener('loadend', () => {
            const t = reader.result as string;

            console.log(t)

            onStop(t.replace(`data:${MIME_TYPE};base64,`, ''));
          });

          reader.readAsDataURL(blob);

          const audio = document.createElement('audio');

          audio.controls = true;
          audio.style.display = 'none';

          audio.src = window.URL.createObjectURL(blob);

          document.body.append(audio);
        }
        if (key === 'start') {
          await recorder.initAudio();
          await recorder.initWorker();
          recorder.startRecording();
          this.isRecording = true;
        }
      });
    },
    start() {
      this.ee.emit('start');
    },
    async stop() {
      this.ee.emit('stop');
    },
  };
}
