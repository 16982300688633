// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const UnderlineIcon = createIcon({
  displayName: 'UnderlineIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M7.7375 15.275C8.89583 16.425 10.3167 17 12 17C13.6833 17 15.1042 16.425 16.2625 15.275C17.4208 14.125 18 12.7167 18 11.05V4C18 3.71667 17.9042 3.47917 17.7125 3.2875C17.5208 3.09583 17.2833 3 17 3C16.7167 3 16.4792 3.09583 16.2875 3.2875C16.0958 3.47917 16 3.71667 16 4V11.1C16 12.2 15.6167 13.125 14.85 13.875C14.0833 14.625 13.1333 15 12 15C10.8667 15 9.91667 14.625 9.15 13.875C8.38333 13.125 8 12.2 8 11.1V4C8 3.71667 7.90417 3.47917 7.7125 3.2875C7.52083 3.09583 7.28333 3 7 3C6.71667 3 6.47917 3.09583 6.2875 3.2875C6.09583 3.47917 6 3.71667 6 4V11.05C6 12.7167 6.57917 14.125 7.7375 15.275Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M6 19C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20C19 19.4477 18.5523 19 18 19H6Z"
      />
    ),
  ],
});
