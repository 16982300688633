import {
  makeOperation,
  cacheExchange,
  fetchExchange,
  Client,
} from 'urql';
import { AuthConfig, authExchange } from '@urql/exchange-auth';
import { auth } from './firebase';

const GRAPHQL_API_URL = process.env.NEXT_PUBLIC_GRAPHQL_API_URL || 'http://localhost:9090/gql/v1.0.0/query';
const API_KEY = process.env.API_KEY || 'emo-api-key';

// Create graphql client
// https://formidable.com/open-source/urql/docs/basics/core/#setting-up-the-client
export const graphqlClient = new Client({
  requestPolicy: 'cache-and-network',
  url: GRAPHQL_API_URL,
  exchanges: [
    cacheExchange,
    authExchange(async (utils) => {
      const config: AuthConfig = {
        addAuthToOperation(operation) {
          const token = global.localStorage?.getItem('token');
          console.debug('[AUTH] addAuthToOperation', token);

          if (!token) return operation;

          const fetchOptions = typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                'EMO-FB-IDTOKEN': token,
                'EMO-API-Authentication': API_KEY,
              },
            },
          });
        },

        async refreshAuth() {
          const refreshAuthToken = await auth.currentUser?.getIdToken(true);
          const refresTokenResult = await auth.currentUser?.getIdTokenResult(true)
            .catch(() => null);

          if (refreshAuthToken) {
            window.localStorage.setItem('token', refreshAuthToken);
          } else {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('expiration');
          }

          if (refresTokenResult) {
            const expiration = new Date(refresTokenResult.expirationTime);
            const needRefresh = (expiration.getTime() - Date.now()) <= 0;

            window.localStorage.setItem('expiration', `${expiration.getTime()}`);

            if (needRefresh) {
              console.debug('[AUTH] need to refresh');
              auth.currentUser?.getIdTokenResult(true)
                .catch(() => null);
            }
          }
        },

        didAuthError(error) {
          return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
        },

        // https://formidable.com/open-source/urql/docs/advanced/authentication/#configuring-willautherror
        willAuthError() {
          const token = global.localStorage?.getItem('token');

          const expiration = global.localStorage?.getItem('expiration');
          console.debug('[AUTH] willAuthError', expiration);

          if (expiration) {
            return (Number(expiration) - Date.now()) <= 0;
          }

          return !token;
        },
      };

      return config;
    }),
    fetchExchange,
  ],
  fetchOptions() {
    return { headers: { 'EMO-API-Authentication': API_KEY } };
  },
});
