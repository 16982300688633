import { FC, ReactNode, memo } from 'react';
import { Flex, Box, Divider, ChakraProps } from '@chakra-ui/react';
import { Label } from '../../../typography/Label';
import { Paragraph } from '../../../typography/Paragraph';

export interface ListItemContentProps {
  title: string | ReactNode
  subtitle?: string | ReactNode
  description?: string | ReactNode
  action?: ReactNode
  divider?: boolean
  size?: 'small' | 'medium'
  pl?: ChakraProps['pl']
  pr?: ChakraProps['pr']
}

export const ListItemContent: FC<ListItemContentProps> = memo(({
  title,
  subtitle,
  description,
  action,
  divider = true,
  pl = 0,
  pr = 0,
  size = 'medium',
  ...props
}) => (
  <Box pl={pl} pr={pr} flexGrow={1} pos="relative" {...props}>
    { divider && (
      <Divider
        pos="absolute"
        width={pl === 0 ? '100%' : 'calc(100% - var(--emochan-spaces-16))'}
      />
    )}
    <Flex gap="8" alignItems="center" h="100%">
      <Box flexGrow={1}>
        <Box py={size === 'small' ? '12' : '16'}>
          <Label as="div" size={size}>{title}</Label>
          { subtitle && (
            <Paragraph as="div" pt="4" size="small">{subtitle}</Paragraph>
          ) }
          { description && (
            <Paragraph as="div" size="small">{description}</Paragraph>
          ) }
        </Box>
      </Box>
      {action && (
        <Box flexGrow={0} px="16" py="12">
          {action}
        </Box>
      )}
    </Flex>
  </Box>
));

ListItemContent.displayName = 'ListItemContent';
