import { NamedExoticComponent } from 'react';
import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { Color } from '../../../system/styles';
import { ListHeader } from './ListHeader';
import { ListBody } from './ListBody';
import { ListItem } from './ListItem';

export interface ListProps extends BoxProps {
  color?: Color;
}

// if draggable, add classname "-draggable"
export const List = forwardRef<ListProps, 'div'>(({
  children,
  ...props
}, ref) => (
  <Box
    bg="grey.0"
    ref={ref}
    {...props}
  >
    {children}
  </Box>
)) as NamedExoticComponent<ListProps> & {
  Header: typeof ListHeader;
  Body: typeof ListBody;
  Item: typeof ListItem;
};

List.displayName = 'List';

List.Header = ListHeader;
List.Body = ListBody;
List.Item = ListItem;
