import { forwardRef, cloneElement, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { ButtonProps, ButtonSizes } from './ButtonBase';
import { Button } from './Button';

export type IconButtonProps = Omit<ButtonProps, 'shape' | 'rightIcon'> & {
  label: string
  icon: ReactNode | string
}

// アイコンを1つだけ格納した丸いボタン
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({
  label,
  icon,
  as,
  size = ButtonSizes.medium,
  ...props
}, ref) => {
  let iconSize = typeof size === 'string' ? `${size || 'medium'}` : size;

  if (size === ButtonSizes.xl) {
    iconSize = 'large';
  }

  const iconProps = { 'aria-hidden': 'true', size: iconSize };
  const iconStyle = props.disabled ? { opacity: '0.3' } : undefined; // むりやりだが

  // span の入れ子になるけど valid だったはず
  const child = typeof icon === 'string' ? (
    <Box
      as="span"
      fontSize={typeof size === 'string' ? `icon.${size || 'medium'}` : size}
      {...iconStyle}
    >
      {icon}
    </Box>
  ) : icon;

  const iconComponent = cloneElement(child as any, iconProps);

  return (
    <Button
      ref={ref}
      as={as}
      aria-label={label}
      leftIcon={iconComponent}
      shape="pill"
      size={size === ButtonSizes.xs ? ButtonSizes.small : size}
      {...props}
    />
  );
});
