import { FC } from 'react';
import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react';
import { BGColorFoundations, FGColorFoundations } from '../../../system/styles';

export const TooltipPlacement = {
  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
  LEFT: 'left',
  RIGHT: 'right',
} as const;
export type TooltipPlacement = typeof TooltipPlacement[keyof typeof TooltipPlacement];

export interface TooltipProps extends ChakraTooltipProps {
  dark?: boolean
}

export const Tooltip: FC<TooltipProps> = ({
  placement,
  dark = false,
  label,
  hasArrow = false,
  children,
  ...props
}) => {
  const type = dark ? 'secondary' : 'primary';

  const fg = FGColorFoundations[type];
  const bg = BGColorFoundations[type];

  const offsetPosition = hasArrow ? 12 : 8;

  return (
    <ChakraTooltip
      label={label}
      hasArrow={hasArrow}
      placement={placement}
      bg={bg}
      color={fg}
      py={8}
      px={12}
      borderRadius={8}
      shadow="high.below"
      fontSize="label.xs"
      fontWeight="label"
      lineHeight="label.xs"
      zIndex={5000}
      offset={[0, offsetPosition]}
      arrowPadding={16}
      arrowSize={6}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};
