export const EmoOnomatope = {
  joy: {
    label: 'ウキウキ',
    paths: [
      'M49.359 83.4632L43.7695 79.2948L47.0853 74.8421L45.1906 73.4211L47.4643 70.3895L49.359 71.8106L52.8643 67.1685C54.0011 68.0211 54.9485 68.779 55.8958 69.7263C57.5064 71.4316 58.9274 73.4211 59.3064 75.7895C59.8748 79.0106 58.4537 82.6106 56.7485 85.3579L53.2432 84.3158C54.8537 81.7579 56.559 78.4421 55.4222 75.4106C54.9485 74.2737 54.2853 73.1369 53.2432 72.379L48.7906 78.4421L51.538 80.5263L49.359 83.4632Z',
      'M71.6214 57.2211L76.3583 53.1474L75.0319 51.5368L70.8635 55.2316L68.5898 52.579L72.6635 48.979L70.9583 46.9895L73.8951 44.4316L75.6004 46.3263L79.7688 42.7263L82.0425 45.379L77.9688 48.979L79.3898 50.5895L83.9372 46.6105L86.2109 49.2632L81.7583 53.1474L84.9793 56.8421L81.9477 59.3053L78.6319 55.8L73.8951 59.8737L71.6214 57.2211Z',
      'M203.308 48.6L207.381 42.9158L211.929 46.1368L213.35 44.2421L216.382 46.421L214.96 48.3158L219.697 51.7263C218.845 52.8631 218.087 53.8105 217.139 54.8526C215.529 56.5579 213.445 57.9789 211.076 58.3579C207.855 59.021 204.255 57.6 201.508 55.9895L202.55 52.4842C205.108 54.0947 208.518 55.7052 211.55 54.4737C212.687 54 213.729 53.2421 214.487 52.2L208.424 47.8421L206.434 50.6842L203.308 48.6Z',
      'M229.739 70.4842L233.907 75.1263L235.423 73.7053L231.728 69.6316L234.381 67.3579L238.075 71.4316L239.97 69.7263L242.528 72.6632L240.633 74.3684L244.328 78.4421L241.675 80.7158L237.981 76.6421L236.37 78.0632L240.444 82.5158L237.791 84.7895L233.812 80.3369L230.118 83.6527L227.654 80.7158L231.254 77.4948L227.086 72.8527L229.739 70.4842Z',
    ],
  },
  trust: {
    label: 'ニコニコ',
    paths: [
      'M46.6087 80.2421L43.6719 78.0632L51.535 67.3579L54.4719 69.5369L46.6087 80.2421ZM53.335 86.8737L50.3982 84.6948L59.8719 71.9053L62.8087 74.0842L53.335 86.8737Z',
      'M76.0722 62.0527L73.8932 59.1158L82.3248 53.0527L78.4406 47.6527L70.1985 53.5264L68.0195 50.5895L79.4827 42.3474L87.7248 53.7158L76.0722 62.0527Z',
      'M199.797 52.5789L201.976 49.5474L214.86 58.8316L212.681 61.8631L199.797 52.5789ZM206.334 45.8526L208.418 42.9158L219.218 50.6842L217.134 53.621L206.334 45.8526Z',
      'M225 74.9369L227.937 72.758L234.095 81.0948L239.495 77.1159L233.432 68.9685L236.368 66.7896L244.705 78.0632L233.432 86.4001L225 74.9369Z',
    ],
  },
  fear: {
    label: 'ビクビク',
    paths: [
      'M57.2213 83.5579C55.895 85.3579 54.2844 86.1158 52.295 84.6L42.7266 77.4948L45.0003 74.3685L48.695 77.1158C49.5476 74.8422 50.116 72.5685 50.495 70.0106L54.3792 70.6737C53.8108 73.8 52.8634 76.5474 51.7266 79.2948L53.8108 80.9053C54.3792 81.2843 54.4739 81.2843 54.8529 80.7158L57.7897 76.7369C58.6424 75.6 59.4002 74.4632 59.8739 73.0422L63.095 75.1264C62.6213 76.0737 62.1476 76.9264 61.4844 77.779L57.2213 83.5579ZM48.5055 69.0632L48.7897 67.3579C50.1161 67.3579 51.4423 67.6421 52.7686 67.9264L52.4844 69.8211C51.1581 69.3474 49.8318 69.1579 48.5055 69.0632ZM49.4529 66.6001L49.7371 64.8C51.1582 64.8 52.3897 65.0843 53.716 65.3685L53.4318 67.2632C52.1055 66.8843 50.7792 66.6948 49.4529 66.6001Z',
      'M69.8203 54.8527C70.8624 52.579 70.9572 50.4 70.4835 47.9369L79.7677 41.2106C82.4203 45.4737 83.9362 50.2106 82.4204 55.1369C81.6625 57.7895 79.9572 60.0632 78.0624 62.0527L74.8414 60.1579C78.7256 56.3685 80.8098 51.6316 78.2519 46.4211L74.3677 49.1685C74.4625 51.4422 74.1782 53.4316 73.3256 55.5158L69.8203 54.8527Z',
      'M203.209 56.3684C201.409 55.0421 200.652 53.4316 202.073 51.4421L209.083 41.7789L212.209 44.0526L209.557 47.7474C211.83 48.6 214.104 49.0737 216.662 49.4526L216.094 53.3368C212.967 52.7684 210.22 51.9158 207.378 50.7789L205.862 52.9579C205.483 53.5263 205.483 53.621 206.052 54L210.03 56.9368C211.167 57.7895 212.399 58.4526 213.725 59.021L211.641 62.2421C210.694 61.7684 209.841 61.2947 208.988 60.7263L203.209 56.3684ZM217.609 47.4631L219.409 47.7474C219.409 49.0737 219.22 50.4 218.841 51.7263L216.946 51.4421C217.325 50.2105 217.515 48.8842 217.609 47.4631ZM220.073 48.4105L221.873 48.6947C221.873 50.1158 221.683 51.3474 221.304 52.6737L219.409 52.3895C219.788 51.0631 219.978 49.8316 220.073 48.4105Z',
      'M232.012 68.5895C234.286 69.6316 236.465 69.6316 238.928 69.1579L245.749 78.3474C241.581 81.0947 236.844 82.6105 231.823 81.1895C229.17 80.4316 226.802 78.8211 224.812 76.9263L226.707 73.7053C230.497 77.4947 235.328 79.579 240.444 76.9263L237.602 73.1368C235.328 73.2316 233.339 73.0421 231.255 72.1895L232.012 68.5895Z',
    ],
  },
  surprise: {
    label: 'ドキドキ',
    paths: [
      'M56.4633 85.2632L43.4844 75.6L45.9475 72.2842L49.2633 74.7474C51.8212 72.9474 54.4738 71.3368 57.3159 70.1053L59.3054 74.0842C56.937 74.8421 54.5685 75.7895 52.3895 77.0211L58.9264 81.8526L56.4633 85.2632ZM48.5054 71.2421L48.8843 69.2526C50.3054 69.2526 51.6317 69.4421 52.9581 69.821L52.5791 72C51.158 71.5263 49.9264 71.3368 48.5054 71.2421ZM48.979 68.4L49.358 66.4105C50.7791 66.4105 52.1054 66.5053 53.4317 66.8842L53.0528 68.9684C51.7265 68.5895 50.4948 68.4 48.979 68.4Z',
      'M71.6214 57.2211L76.3582 53.1474L75.0319 51.5368L70.8634 55.2316L68.5898 52.579L72.6635 48.979L70.9583 46.9895L73.8951 44.4316L75.6003 46.3263L79.7687 42.7263L82.0424 45.379L77.9687 48.979L79.3897 50.5895L83.9372 46.6105L86.2109 49.2632L81.7582 53.1474L84.9792 56.8421L81.9477 59.3053L78.8214 55.7053L74.0845 59.7789L71.6214 57.2211Z',
      'M201.504 55.7053L210.978 42.6316L214.293 45L211.83 48.3158C213.725 50.779 215.336 53.4316 216.567 56.3684L212.588 58.3579C211.83 55.9895 210.788 53.6211 209.557 51.5369L204.82 58.0737L201.504 55.7053ZM215.43 47.5579L217.42 47.9369C217.42 49.3579 217.23 50.6842 216.946 52.0105L214.767 51.7263C215.146 50.2105 215.336 48.979 215.43 47.5579ZM218.272 48.0316L220.262 48.4105C220.262 49.8316 220.167 51.1579 219.883 52.4842L217.799 52.1053C218.083 50.779 218.272 49.4526 218.272 48.0316Z',
      'M229.739 70.4842L233.907 75.1263L235.423 73.7053L231.728 69.6316L234.381 67.3579L238.075 71.4316L239.97 69.7263L242.528 72.6632L240.633 74.3684L244.328 78.4421L241.675 80.7158L237.981 76.6421L236.37 78.0632L240.444 82.5158L237.791 84.7895L233.812 80.3369L230.118 83.6527L227.654 80.7158L231.254 77.4948L227.086 72.8527L229.739 70.4842Z',
    ],
  },
  sadness: {
    label: 'シクシク',
    paths: [
      'M45.0949 79.0106L43.4844 76.1685C45.1896 74.6527 46.6107 73.6106 48.6002 72.5685L50.3054 75.6948C48.316 76.5475 46.7054 77.6843 45.0949 79.0106ZM47.6528 82.8001L45.9475 79.958C47.5581 78.5369 49.0739 77.4948 51.0633 76.6422L52.8633 79.6738C51.0633 80.4317 49.2633 81.6633 47.6528 82.8001ZM52.2002 84.6948C53.5265 82.6106 54.8528 79.8632 54.8528 77.3053C54.7581 74.3685 52.9581 71.3369 51.2528 69.158L55.0423 67.9264C57.5054 71.3369 59.2107 75.3159 58.5475 79.579C58.1686 82.0422 57.1265 84.3159 55.9896 86.4948L52.2002 84.6948Z',
      'M69.8203 54.9474C70.8624 52.6737 70.9572 50.4948 70.4835 48.0316L79.7677 41.4C82.4203 45.6632 83.9361 50.4 82.4203 55.3264C81.6624 57.979 79.9572 60.2527 78.0624 62.2421L74.8414 60.3474C78.7256 56.5579 80.8098 51.8211 78.2519 46.6106L74.3677 49.3579C74.4624 51.6316 74.1782 53.6211 73.3256 55.7053L69.8203 54.9474Z',
      'M202.073 51.3474C204.252 52.6737 206.905 53.9053 209.463 53.9053C212.4 53.8105 215.431 51.9158 217.61 50.2105L218.842 54C215.431 56.4632 211.547 58.2632 207.189 57.6948C204.726 57.3158 202.452 56.3684 200.273 55.1369L202.073 51.3474ZM203.873 46.8947L206.621 45.1895C208.042 46.8 209.084 48.3158 210.031 50.2105L207 52.0105C206.337 50.2105 205.01 48.4105 203.873 46.8947ZM207.663 44.1474L210.505 42.5369C212.021 44.2421 213.063 45.5684 214.2 47.5579L211.168 49.2632C210.221 47.4632 208.989 45.8526 207.663 44.1474Z',
      'M232.106 68.5895C234.38 69.6316 236.559 69.6316 239.022 69.1579L245.843 78.3474C241.675 81.0947 236.938 82.6105 231.917 81.1895C229.264 80.4316 226.896 78.821 224.906 76.9263L226.801 73.7053C230.59 77.4947 235.422 79.5789 240.538 76.9263L237.696 73.1368C235.422 73.2316 233.433 73.0421 231.348 72.1895L232.106 68.5895Z',
    ],
  },
  disgust: {
    label: 'イヤイヤ',
    paths: [
      'M53.4303 73.7052L60.9145 79.2947L58.5461 82.5158L52.9566 78.3473C52.4829 80.9052 51.8198 82.8947 51.4409 84.1263L47.7461 83.7473C49.5461 78.6316 50.1145 73.421 48.8829 67.8315L52.6724 66.6C53.3356 68.9684 53.525 71.4316 53.4303 73.7052Z',
      'M68.8711 53.6211L71.7132 50.9685L69.3448 48.5053L72.1869 45.7579L74.65 48.3158L80.9974 42.4421C82.5132 44.5263 83.5553 46.6105 83.7448 48.7895C83.8395 50.2105 83.5553 51.5369 83.1764 52.7684L79.3869 52.3895C80.05 51.2527 80.429 49.7369 79.8606 48.3158L77.2079 50.8737L83.5553 57.6L80.6185 60.2527L74.3658 53.6211L71.6185 56.2737L68.8711 53.6211Z',
      'M213.062 52.579L207.567 60.1579L204.346 57.8842L208.42 52.2C205.862 51.8211 203.777 51.1579 202.641 50.779L203.02 47.0842C208.23 48.7895 213.346 49.2632 218.935 47.9369L220.262 51.7263C217.704 52.3895 215.335 52.579 213.062 52.579Z',
      'M233.334 67.6421L235.986 70.3895L238.449 68.021L241.292 70.8631L238.734 73.3263L244.702 79.5789C242.618 81.0947 240.534 82.2315 238.355 82.421C236.934 82.5158 235.607 82.3263 234.376 81.8526L234.66 78.0631C235.797 78.6316 237.313 79.0105 238.734 78.5368L236.081 75.8842L229.449 82.3263L226.797 79.3895L233.334 73.0421L230.681 70.3895L233.334 67.6421Z',
    ],
  },
  anger: {
    label: 'プンプン',
    paths: [
      'M45.5667 80.8105L42.5352 78.6315L48.8825 70.1052L54.6615 69.7263C57.2194 72.4736 58.6405 75.6 58.2615 79.4842C58.072 81.5684 57.5036 83.6526 56.5562 85.5473L52.7667 84.7894C53.5246 83.1789 54.1878 81.3789 54.472 79.5789C54.851 76.8315 53.7141 74.4631 51.8194 72.4736L45.5667 80.8105ZM49.9246 68.9684C48.7878 68.1157 48.5983 66.5052 49.4509 65.3684C50.3036 64.2315 51.9141 64.0421 52.9562 64.8C54.0931 65.6526 54.3773 67.2631 53.5247 68.3999C52.672 69.5368 51.0615 69.821 49.9246 68.9684ZM50.7773 67.8315C51.3457 68.2105 52.0089 68.1157 52.3878 67.5473C52.7668 67.0736 52.672 66.3157 52.1036 65.9368C51.6299 65.5578 50.872 65.6526 50.4931 66.221C50.1141 66.6947 50.2089 67.4526 50.7773 67.8315Z',
      'M68.9659 53.0526L68.4922 49.5473C70.7659 48.7894 72.8501 48.3157 75.2185 48.1263L75.5974 52.0105C73.229 52.0105 71.1448 52.3894 68.9659 53.0526ZM74.1764 59.6841C76.9238 57.221 79.4817 54.3789 79.5764 50.4947C79.6711 48.1263 79.1027 45.6631 78.1554 43.3894L82.1343 43.2947C83.3658 46.7052 83.9343 50.5894 82.8922 54.1894C81.9448 57.221 79.6711 59.9684 77.208 62.4315L74.1764 59.6841Z',
      'M205.765 44.7158L207.944 41.6842L216.565 47.8421L217.039 53.6211C214.291 56.1789 211.165 57.6947 207.376 57.3158C205.291 57.1263 203.207 56.5579 201.312 55.7053L201.976 51.9158C203.586 52.6737 205.386 53.3368 207.186 53.5263C210.028 53.9053 212.302 52.6737 214.291 50.7789L205.765 44.7158ZM217.797 48.979C218.649 47.8421 220.165 47.5579 221.397 48.4105C222.533 49.2632 222.818 50.779 221.965 51.9158C221.112 53.0526 219.502 53.3369 218.365 52.4842C217.134 51.7263 216.944 50.1158 217.797 48.979ZM218.839 49.7368C218.46 50.3053 218.555 50.9684 219.123 51.3474C219.691 51.7263 220.355 51.6316 220.734 51.0632C221.112 50.4948 221.018 49.8316 220.449 49.4526C219.976 49.0737 219.218 49.2632 218.839 49.7368Z',
      'M227.274 73.1369C229.737 75.8842 232.674 78.3474 236.558 78.4421C238.926 78.4421 241.39 77.8737 243.568 76.9264L243.758 80.9053C240.347 82.2316 236.463 82.8948 232.863 81.8527C229.832 81 227.084 78.7263 224.621 76.2632L227.274 73.1369ZM233.905 67.8316L237.411 67.2632C238.168 69.4421 238.737 71.6211 238.926 73.8948L235.042 74.2737C234.947 72 234.568 70.0106 233.905 67.8316Z',
    ],
  },
  anticipation: {
    label: 'ワクワク',
    paths: [
      'M48.6951 82.9895L42.3477 78.3474L51.3477 66.2211L54.1898 68.3053C56.5582 70.0105 58.7372 72.8526 59.3056 75.7895C59.874 78.821 58.5477 82.3263 57.0319 84.979L53.5267 84.1263C54.853 81.9474 56.274 78.821 55.6108 76.2632C55.1371 74.3684 53.4319 72.7579 51.8213 71.5263L47.5582 77.3053L51.0635 79.9579L48.6951 82.9895Z',
      'M69.8203 54.9474C70.8624 52.6737 70.9572 50.4948 70.4835 48.0316L79.7677 41.4C82.4203 45.6632 83.9362 50.4 82.4204 55.3264C81.6625 57.979 79.9572 60.2527 78.0625 62.2421L74.8414 60.3474C78.7256 56.5579 80.8098 51.8211 78.2519 46.6106L74.3677 49.3579C74.4625 51.6316 74.1783 53.6211 73.3256 55.7053L69.8203 54.9474Z',
      'M203.683 47.9368L208.325 41.5895L220.546 50.4L218.462 53.2421C216.756 55.6105 214.009 57.8842 211.072 58.4526C208.041 59.021 204.441 57.7895 201.883 56.2737L202.641 52.7684C204.914 54.0947 207.946 55.4211 210.599 54.7579C212.493 54.1895 214.104 52.4842 215.241 50.9684L209.367 46.7053L206.809 50.3052L203.683 47.9368Z',
      'M232.013 68.5895C234.286 69.6316 236.465 69.6316 238.928 69.1579L245.749 78.3474C241.581 81.0947 236.844 82.6105 231.823 81.1895C229.17 80.4316 226.802 78.821 224.812 76.9263L226.707 73.7053C230.497 77.4947 235.328 79.5789 240.444 76.9263L237.602 73.1368C235.328 73.2316 233.339 73.0421 231.255 72.1895L232.013 68.5895Z',
    ],
  },
  joker: {
    label: 'その他',
    paths: [
      'M52.7699 79.7684C50.9699 78.4421 51.1594 76.8316 51.3488 75.6C51.4436 74.8421 51.4436 74.1789 50.8751 73.8C50.1173 73.2316 49.4541 73.7052 48.8857 74.3684C48.1278 75.3158 48.033 76.6421 48.412 77.9684L44.7173 77.3052C44.5278 75.6 44.9067 73.421 46.3278 71.621C48.1278 69.1579 50.6857 68.021 53.1488 69.821C54.7594 70.9579 54.6646 72.3789 54.5699 73.7052C54.3804 74.9368 54.2857 75.9789 55.3278 76.7368L55.8015 77.1158L53.5278 80.2421L52.7699 79.7684ZM54.3804 81L56.8436 77.6842L59.6857 79.7684L57.2225 83.0842L54.3804 81Z',
      'M76.8347 54.8526C75.5084 53.0526 76.1716 51.6315 76.74 50.4947C77.0242 49.7368 77.3084 49.1684 76.8347 48.6C76.2663 47.8421 75.5084 48.0315 74.7505 48.5052C73.7084 49.2631 73.2347 50.4947 73.14 51.821L69.8242 50.1158C70.2032 48.4105 71.2453 46.5158 73.14 45.1894C75.6032 43.3894 78.4453 43.1052 80.2453 45.5684C81.3821 47.1789 80.9084 48.5052 80.4347 49.6421C79.9611 50.7789 79.3926 51.7263 80.1505 52.7684L80.5295 53.3368L77.4032 55.6105L76.8347 54.8526ZM77.9716 56.6526L81.2874 54.2842L83.3716 57.221L80.0558 59.5894L77.9716 56.6526Z',
      'M205.674 53.5263L208.99 55.8947L206.906 58.8316L203.59 56.4631L205.674 53.5263ZM207 51.9158C208.327 50.1158 209.937 50.3052 211.074 50.4C211.832 50.4947 212.495 50.4947 212.874 49.9263C213.442 49.1684 212.969 48.4105 212.306 47.9368C211.264 47.1789 209.937 47.1789 208.706 47.4631L209.274 43.7684C210.979 43.5789 213.158 43.9579 215.053 45.2842C217.516 47.0842 218.748 49.6421 216.853 52.1052C215.716 53.7158 214.295 53.7158 212.969 53.5263C211.737 53.4316 210.695 53.2421 209.937 54.2842L209.558 54.8526L206.432 52.5789L207 51.9158Z',
      'M230.494 76.7368L232.958 80.0526L230.115 82.1368L227.652 78.8211L230.494 76.7368ZM232.2 75.6C234 74.2737 235.421 74.9368 236.558 75.4105C237.315 75.6947 237.884 75.979 238.452 75.5053C239.21 74.9368 239.021 74.179 238.452 73.4211C237.694 72.4737 236.463 72 235.137 71.9053L236.842 68.5895C238.547 68.8737 240.442 69.9158 241.863 71.8105C243.663 74.2737 244.042 77.0211 241.579 78.9158C239.968 80.0526 238.642 79.6737 237.505 79.1053C236.368 78.6316 235.421 78.1579 234.379 78.9158L233.905 79.2947L231.631 76.1684L232.2 75.6Z',
    ],
  },
};

export const EmoNameJa = {
  joy: {
    label: '喜び',
    paths: [
      'M103.635 71.5479H98.1193V71.0019H95.9073V71.5479H90.3633V73.0459H95.9073V73.5499H91.1893V74.8239H102.823V73.5499H98.1193V73.0459H103.635V71.5479ZM102.417 75.2719H91.5953V78.1699H93.1773C93.2613 78.3939 93.3313 78.5899 93.4013 78.8139H90.3633V80.3399H103.635V78.8139H100.653C100.723 78.6039 100.793 78.3799 100.849 78.1699H102.417V75.2719ZM100.345 76.4059V76.9939H93.6673V76.4059H100.345ZM98.6233 78.1699C98.5673 78.4079 98.5253 78.5899 98.4273 78.8139H95.6553C95.5853 78.6039 95.5013 78.3799 95.4173 78.1699H98.6233ZM102.515 80.7739H91.4693V84.0919H102.515V80.7739ZM100.387 81.9359V82.8179H93.6113V81.9359H100.387Z',
      'M123.843 71.0719C124.361 72.0799 124.655 72.7799 124.935 73.8719L126.069 73.3819C125.747 72.3739 125.439 71.5199 124.893 70.6099L123.843 71.0719ZM121.897 71.3939C122.429 72.3879 122.709 73.1019 123.003 74.1939L124.123 73.6899C123.829 72.7099 123.493 71.8279 122.961 70.9319L121.897 71.3939ZM113.245 72.3179V74.5019L115.219 74.4599C114.043 75.8179 113.371 77.4279 113.371 79.2339C113.371 82.1459 115.303 83.6579 118.103 83.6579C121.393 83.6579 122.793 81.9779 122.793 78.7719C122.793 77.9739 122.723 77.3579 122.485 76.6019C123.115 77.3579 123.591 77.7219 124.473 78.1699L125.341 75.8879C123.115 74.9779 122.149 73.9139 121.281 71.6879L119.321 72.3179C119.461 72.6119 119.601 72.9059 119.727 73.1999C120.371 74.8239 120.763 76.7559 120.763 78.5059C120.763 80.3539 120.049 81.4739 118.075 81.4739C116.367 81.4739 115.499 80.5919 115.499 78.8979C115.499 77.0219 116.409 75.4539 117.865 74.3059V72.3179H113.245Z',
    ],
  },
  trust: {
    label: '安心',
    paths: [
      'M90.7133 72.066V75.524H92.8833V74.012H101.143V75.524H103.299V72.066H98.1193V71.03H95.9073V72.066H90.7133ZM96.5793 75.972C96.7893 75.524 96.9713 75.048 97.1533 74.572L94.7873 74.264C94.5773 74.852 94.3393 75.412 94.0733 75.972H90.3633V78.03H92.9953C92.4913 78.87 92.0433 79.542 91.3853 80.27C92.7573 80.592 94.1293 80.97 95.4593 81.418C93.8633 81.922 92.2393 82.062 90.5733 82.076L91.2733 84.33C93.4573 84.134 95.4593 83.714 97.4753 82.818C97.7273 82.706 97.9653 82.58 98.2033 82.426C99.5193 82.972 100.723 83.56 101.941 84.302L103.327 82.328C102.235 81.712 101.171 81.278 99.9953 80.858C100.709 79.976 101.143 79.108 101.493 78.03H103.635V75.972H96.5793ZM95.5293 78.03H99.1553C98.7913 78.842 98.3993 79.472 97.7693 80.116C96.7753 79.794 95.7673 79.514 94.7733 79.234C95.0533 78.842 95.3053 78.464 95.5293 78.03Z',
      'M116.241 71.03L115.443 73.004C117.683 73.452 119.139 73.816 121.211 74.852L122.149 72.696C119.937 71.814 118.593 71.436 116.241 71.03ZM116.115 74.88V82.622C116.115 83.616 116.507 84.148 117.557 84.148H120.441C121.239 84.148 121.729 83.994 122.079 83.224C122.443 82.426 122.611 80.998 122.695 80.116L120.539 79.458C120.469 80.606 120.441 81.264 120.329 81.628C120.245 81.908 120.091 82.006 119.853 82.006H118.649C118.341 82.006 118.299 81.922 118.299 81.614V74.88H116.115ZM123.703 74.88L121.645 75.58C122.569 77.848 123.115 80.144 123.409 82.58L125.705 82.034C125.341 79.584 124.711 77.162 123.703 74.88ZM115.415 75.664L113.301 75.44C113.203 77.316 112.937 79.318 112.111 81.026L114.057 82.58C115.051 80.396 115.303 78.03 115.415 75.664Z',
    ],
  },
  fear: {
    label: '恐れ',
    paths: [
      'M95.6973 78.744L94.8853 79.64C95.9073 80.046 96.8453 80.648 97.6853 81.334L99.0293 79.906C98.2873 79.304 97.4893 78.884 96.6493 78.436C97.3493 77.568 97.7273 76.672 97.8533 75.552C98.2173 76.154 98.4833 76.588 98.7633 77.246L99.8553 76.56V77.61C99.8553 78.394 100.219 78.674 100.975 78.674H102.473C103.117 78.674 103.439 78.394 103.607 77.834C103.775 77.26 103.789 76.574 103.817 74.978L102.375 74.67C102.375 74.824 102.389 74.992 102.389 75.146C102.389 76.476 102.361 76.714 102.179 76.714H102.011C101.815 76.714 101.759 76.574 101.759 76.406V71.408H96.0613V74.474C96.0613 75.23 95.9353 75.958 95.5293 76.602L95.4593 75.678C94.9973 75.79 94.5353 75.902 94.0593 75.986V73.41H95.5433V71.478H90.5593V73.41H92.0713V76.336C91.4973 76.42 90.9093 76.49 90.3353 76.532L90.4893 78.674C91.6933 78.492 93.4433 78.128 94.6193 77.806L95.6973 78.744ZM97.8953 74.516V73.34H99.8553V75.3C99.5473 74.824 99.2253 74.348 98.8333 73.914L97.8953 74.516ZM101.703 79.234L99.8833 80.158C100.765 81.32 101.367 82.65 101.759 84.05L103.803 82.874C103.369 81.698 102.473 80.228 101.703 79.234ZM93.4293 79.892L91.5813 79.388C91.3433 80.592 90.9093 81.67 90.1953 82.664L91.9593 83.994C92.6733 83.084 93.2333 81.04 93.4293 79.892ZM93.5833 80.228V83.084C93.5833 83.728 94.1713 84.148 94.8293 84.148H98.8473C100.275 84.148 100.485 82.524 100.611 81.446L98.6093 80.984C98.5113 81.992 98.4133 82.258 97.9513 82.258H96.0193C95.7253 82.258 95.6413 82.034 95.6413 81.88V80.228H93.5833Z',
      'M115.415 75.762V76.56C114.617 77.764 113.805 78.772 112.727 79.752L113.777 82.034C114.435 81.306 114.869 80.704 115.401 79.864C115.387 80.942 115.373 82.006 115.373 83.07V83.672H117.641C117.571 82.37 117.557 81.082 117.557 79.78C117.557 78.856 117.571 77.918 117.585 76.994C118.243 76.182 119.489 74.908 120.595 74.908C121.127 74.908 121.309 75.286 121.309 75.762V76.182L121.225 78.338C121.211 78.926 121.169 79.514 121.169 80.102C121.169 81.194 121.253 82.356 122.387 82.902C123.171 83.266 124.067 83.294 124.921 83.322L125.439 81.082C125.313 81.082 125.187 81.096 125.047 81.096C123.591 81.096 123.507 80.76 123.507 79.864C123.507 79.332 123.521 78.814 123.549 78.296L123.619 76.014C123.619 75.762 123.633 75.496 123.633 75.244C123.633 73.704 122.919 72.612 121.267 72.612C119.839 72.612 118.691 73.326 117.655 74.236C117.669 73.396 117.683 72.556 117.725 71.702H115.415V73.676H113.119V75.79L115.415 75.762Z',
    ],
  },
  surprise: {
    label: '動揺',
    paths: [
      'M97.4766 83.7001L98.1766 84.3161C98.7646 83.6441 99.1426 83.0421 99.5346 82.2441L99.8846 84.2881H101.313C102.349 84.2881 102.713 83.7981 102.965 82.8601C103.357 81.3621 103.357 75.3701 103.357 73.5081H100.809V71.0441H98.7786V73.5081H97.9526V73.1721H95.1106V72.6541C95.9786 72.5841 96.8466 72.5281 97.7146 72.3881L97.2246 70.9321C95.4046 71.2961 92.3946 71.3381 90.4766 71.3381L90.6586 72.7801C90.8406 72.7801 91.0086 72.7941 91.1906 72.7941C91.8766 72.7941 92.5766 72.7661 93.2626 72.7521V73.1721H90.3646V74.6281H93.2626V75.0621H90.7006V79.8221H93.2626V80.3121H90.6166V81.7121H93.2626V82.3561C92.4226 82.4121 91.5686 82.4261 90.7286 82.4261H90.3086L90.4206 84.0641C92.7726 83.9801 95.1246 83.8961 97.4766 83.7001ZM97.7846 81.0681V80.3121H95.1106V79.8221H97.6726V75.0621H95.1106V74.6281H97.9386V75.5801H98.7786C98.7506 77.3581 98.6246 79.4721 97.7846 81.0681ZM100.809 75.5801H101.439C101.411 76.9661 101.369 80.1861 101.187 81.4181C101.103 82.0201 101.061 82.1461 100.529 82.1461H99.5906C100.193 80.7881 100.431 79.6121 100.599 78.1421C100.697 77.2881 100.781 76.4341 100.809 75.5801ZM93.2626 76.1961V76.9381H92.3806V76.1961H93.2626ZM96.0206 76.1961V76.9381H95.1106V76.1961H96.0206ZM93.2626 77.8761V78.6041H92.3806V77.8761H93.2626ZM96.0206 77.8761V78.6041H95.1106V77.8761H96.0206ZM97.1266 82.1601C96.4546 82.2021 95.7826 82.2441 95.1106 82.2861V81.7121H97.4346C97.3366 81.8661 97.2386 82.0201 97.1266 82.1601Z',
      'M118.819 73.4101C121.059 73.2701 123.313 73.1581 125.511 72.6541L124.573 70.8901C122.879 71.3801 119.253 71.6321 117.447 71.6321H116.817L117.349 73.4381C117.783 73.4381 118.217 73.4241 118.651 73.4101L116.929 73.6621C117.223 74.3761 117.349 74.8801 117.475 75.6361L119.393 75.3141C119.267 74.6561 119.099 74.0121 118.819 73.4101ZM116.551 78.2261L116.481 76.8401C116.201 76.9381 115.921 77.0501 115.627 77.1481V75.2581H116.593V73.2841H115.627V71.0441H113.653V73.2841H112.365V75.2581H113.653V77.7361C113.191 77.8621 112.729 77.9741 112.253 78.0581L112.491 80.2001C112.883 80.1021 113.261 80.0041 113.653 79.8781V81.8101C113.653 82.1321 113.555 82.1601 113.317 82.1601H112.365L112.659 84.3161H114.325C115.137 84.3161 115.627 83.9661 115.627 83.0981V79.2061C115.935 79.0941 116.229 78.9681 116.523 78.8421V80.0461H120.051V82.2301H118.917V80.4661H116.915V84.0361H125.175V80.4661H123.201V82.2301H122.025V80.0461H125.609V78.2261H122.025V77.4701H125.315V75.6641H124.489C124.895 74.9501 125.245 74.2081 125.525 73.4381L123.495 73.0461C123.243 73.9701 122.935 74.8101 122.487 75.6641H116.817V77.4701H120.051V78.2261H116.551ZM121.591 73.2701L119.785 73.5361C120.023 74.2501 120.163 74.7961 120.261 75.5381L122.095 75.2301C121.983 74.5301 121.857 73.9281 121.591 73.2701Z',
    ],
  },
  sadness: {
    label: '悲しみ',
    paths: [
      'M83.3527 75.9861C82.0227 76.0701 80.7067 76.1541 79.3767 76.1681L79.5307 78.0301C80.5527 77.8621 81.5887 77.7221 82.6107 77.5121C82.0927 78.0161 81.1267 78.4501 80.4547 78.7021L81.8267 80.1021C84.1507 79.3041 85.5087 77.6941 85.5087 75.1881V71.0581H83.4507V71.8001H79.6847V73.5641H83.4507V74.0681H79.9787V75.5101H83.4087C83.3947 75.6781 83.3807 75.8181 83.3527 75.9861ZM86.5027 79.1221C86.1247 78.8701 85.8447 78.7161 85.4387 78.5201L84.0807 79.8641C85.0047 80.3121 85.8027 80.9561 86.5307 81.6841L88.0147 80.2141C87.6227 79.9201 87.2447 79.6401 86.8387 79.3461H88.5887V78.2401H92.6207V76.4341H88.5887V75.7621H92.0467V74.1381H88.5887V73.5641H92.3407V71.8001H88.5887V71.0581H86.5027V79.1221ZM89.1207 80.4941C89.8627 81.5721 90.3947 82.8041 90.7867 84.0641L92.7467 82.9861C92.4387 81.9361 91.5567 80.5081 90.9127 79.6121L89.1207 80.4941ZM80.5247 79.8361C80.2727 80.9561 79.8947 81.7821 79.2227 82.7201L80.9587 84.0641C81.6727 82.9861 82.1487 81.5721 82.3867 80.3121L80.5247 79.8361ZM82.5827 80.4661V83.1821C82.5827 83.8121 83.1427 84.1341 83.7167 84.1341H88.0847C89.4007 84.1341 89.6667 82.7761 89.7927 81.7541L87.7067 81.2081C87.6647 81.9641 87.5527 82.2161 87.1327 82.2161H84.9627C84.7107 82.2161 84.5987 82.1041 84.5987 81.8521V80.4661H82.5827Z',
      'M103.561 71.7581C103.547 74.0821 103.491 76.4061 103.491 78.7301C103.491 80.0321 103.603 81.6841 104.625 82.6361C105.507 83.4621 106.697 83.6721 107.873 83.6721C109.413 83.6721 110.757 83.3921 111.891 82.2861C113.053 81.1661 113.543 79.7521 113.865 78.2261L111.779 77.2321C111.625 78.0721 111.457 78.9821 111.051 79.7381C110.393 80.9701 109.301 81.5021 107.929 81.5021C106.025 81.5021 105.773 80.4521 105.773 78.8001C105.773 78.0301 105.801 77.2461 105.815 76.4761C105.857 74.9221 105.899 73.3541 105.983 71.8001L103.561 71.7581Z',
      'M134.269 79.7801C134.745 80.0321 135.207 80.3121 135.669 80.5781L136.397 78.2681C135.865 77.9321 135.403 77.7081 134.815 77.4701C134.997 76.3641 135.123 75.2581 135.207 74.1381L132.855 73.9561C132.785 74.8801 132.701 75.7901 132.575 76.7141C131.903 76.5181 131.119 76.3501 130.433 76.2801C130.629 74.8241 130.825 73.3821 131.007 71.9261H124.637V74.0681L128.515 74.0121C128.459 74.7121 128.375 75.5381 128.249 76.2241C125.995 76.3781 123.797 77.7361 123.797 80.2281C123.797 81.7821 124.749 82.9021 126.359 82.9021C128.809 82.9021 129.551 80.2281 130.041 78.2961C130.825 78.4221 131.413 78.6041 132.141 78.8561C132.001 79.3041 131.931 79.5561 131.707 79.9761C131.175 80.9561 130.237 81.6841 129.285 82.2301L130.727 83.8541C131.441 83.4761 132.071 83.0421 132.645 82.4681C133.499 81.6281 133.891 80.8861 134.269 79.7801ZM127.899 78.1841C127.745 78.7721 127.605 79.2901 127.367 79.8501C127.171 80.2841 126.877 80.8021 126.331 80.8021C125.911 80.8021 125.729 80.5081 125.729 80.1161C125.729 79.6401 125.939 79.1921 126.275 78.8561C126.751 78.3941 127.255 78.2681 127.899 78.1841Z',
    ],
  },
  disgust: {
    label: '不快',
    paths: [
      'M98.1053 76.4199C99.3233 78.2399 100.723 79.7659 102.501 81.0259L103.859 78.9539C101.955 77.7219 100.919 76.7559 99.5473 74.9639L98.1053 75.8039V74.8239H97.7413C97.9933 74.4179 98.2033 74.0119 98.3993 73.5919H103.621V71.4219H90.3773V73.5919H96.0893C94.9833 75.9299 92.4773 77.7639 90.1953 78.8559L91.3433 80.9839C93.0373 80.0179 94.5913 78.7719 95.8933 77.3159V84.3159H98.1053V76.4199Z',
      'M120.651 80.7319C121.603 82.5239 122.975 83.5599 124.823 84.3159L125.817 82.2159C123.955 81.6279 122.681 80.7459 121.785 78.9819H125.607V77.0359H124.949V72.8499H121.547V71.0439H119.489V72.8499H116.983V74.2219C116.857 73.9699 116.773 73.8299 116.605 73.5919L115.849 73.9979V71.0439H113.917V84.3159H115.849V82.3559L116.913 84.3719C118.649 83.5459 119.797 82.4539 120.651 80.7319ZM113.637 73.8299H112.419C112.419 74.9079 112.335 76.4759 112.013 77.4979L113.119 78.3519C113.553 76.8819 113.609 75.3559 113.637 73.8299ZM119.489 74.7399V75.9299C119.489 76.3079 119.461 76.6719 119.433 77.0359H116.255V78.9819H119.097C118.957 79.4299 118.789 79.8919 118.523 80.2699C117.865 81.2219 116.899 81.8379 115.849 82.2999V74.8659C116.101 75.4259 116.283 75.9299 116.409 76.5179L117.739 75.9019C117.599 75.5099 117.431 75.1319 117.249 74.7399H119.489ZM121.547 74.7399H122.919V77.0359H121.533C121.547 76.6859 121.547 76.3359 121.547 75.9719V74.7399Z',
    ],
  },
  anger: {
    label: '怒り',
    paths: [
      'M96.9013 79.0241C96.6633 78.8981 96.4113 78.7861 96.1593 78.6881L94.9413 80.0881C95.9773 80.6061 96.6213 81.0261 97.5313 81.7401L98.8333 80.1861C98.3573 79.8641 97.8813 79.5561 97.3773 79.2761C98.3013 78.9541 99.1133 78.5481 99.9113 78.0021C100.793 78.7021 101.647 79.1921 102.683 79.6541L103.859 77.5821C103.019 77.3021 102.305 77.0221 101.549 76.5461C102.389 75.5941 103.019 74.5861 103.327 73.3261V71.4221H96.9993V73.3541H101.087C100.863 74.1101 100.527 74.6561 100.009 75.2441C99.5193 74.6561 99.2253 74.1101 98.9453 73.3961L97.0693 73.9701C97.4193 74.9501 97.7833 75.6921 98.4273 76.5181C97.7833 76.8821 97.1533 77.1621 96.4253 77.3441L96.6493 77.0081C96.2433 76.7561 95.9353 76.5881 95.4873 76.3781C96.2013 75.0201 96.4953 73.5921 96.6493 72.0801H93.9193C94.0033 71.7581 94.0733 71.4361 94.1433 71.1001L91.9873 70.9741C91.9313 71.3381 91.8613 71.7161 91.7773 72.0801H90.4473V73.9281H91.2733C91.0353 74.7121 90.7413 75.4541 90.3913 76.2101C91.0913 76.4621 91.7633 76.7421 92.4353 77.0501C91.7213 77.5401 91.0353 77.7781 90.1953 77.9741L91.2033 79.9481C91.2313 79.9481 91.2733 79.9341 91.3153 79.9201C91.1193 80.8581 90.8253 81.8521 90.2233 82.6081L91.9453 84.0501C92.7293 83.0421 93.1073 81.5301 93.2753 80.2841L91.3713 79.8921C92.5053 79.4861 93.4713 78.9261 94.3113 78.0581C94.7173 78.2961 95.0813 78.5481 95.4593 78.8281L96.1873 77.7081L96.9013 79.0241ZM93.7793 75.5801C93.4713 75.4541 93.1493 75.3281 92.8273 75.2161C93.0233 74.7961 93.2053 74.3621 93.3593 73.9281H94.3673C94.2413 74.5441 94.0873 75.0341 93.7793 75.5801ZM103.789 82.9721C103.243 81.7261 102.725 80.7181 101.913 79.6121L100.037 80.4521C101.003 81.8521 101.297 82.3701 101.843 83.9941L103.789 82.9721ZM93.5413 80.5781V82.7901C93.5413 83.7701 94.0873 84.1761 95.0113 84.1761H98.8193C99.3513 84.1761 99.8273 84.0221 100.149 83.5881C100.485 83.1681 100.653 82.0761 100.709 81.5441L98.7493 81.0541C98.5953 82.1461 98.5113 82.2861 98.1473 82.2861H96.0753C95.7253 82.2861 95.5853 82.2301 95.5853 81.9081V80.5781H93.5413Z',
      'M114.799 71.7021C114.477 73.2701 114.309 74.8661 114.309 76.4761C114.309 77.5961 114.393 78.7021 114.547 79.8081L116.619 79.6961C116.689 78.9961 116.927 78.4921 117.263 77.8901C117.655 77.2041 118.103 76.5601 118.607 75.9581L117.305 75.0341C117.025 75.4261 116.773 75.8181 116.521 76.2241C116.577 74.6841 116.745 73.4241 117.123 71.9401L114.799 71.7021ZM120.931 71.8001C121.071 73.4241 121.183 75.0481 121.183 76.6861C121.183 77.8901 121.141 79.2621 120.301 80.2141C119.321 81.3201 117.767 81.8521 116.353 82.1741L118.033 83.8821C119.573 83.5181 121.155 82.9441 122.219 81.7261C123.465 80.2841 123.577 78.5201 123.577 76.7001C123.577 75.0621 123.479 73.4241 123.325 71.8001H120.931Z',
    ],
  },
  anticipation: {
    label: '期待',
    paths: [
      'M96.5256 82.9441L98.0796 84.3301C98.9476 83.1961 99.4516 81.4741 99.5916 80.0741H101.202V81.7961C101.202 82.1041 101.146 82.3001 100.796 82.3001H99.6056L99.9556 84.2741H101.846C102.784 84.2741 103.19 83.9801 103.19 83.0001V71.3521H97.8976V76.8261C97.8976 77.5541 97.8556 78.2821 97.7716 79.0101H96.9176V73.8021H97.6736V71.9961H96.9176V71.0721H94.9716V71.9961H93.1656V71.0721H91.2056V71.9961H90.5056V73.8021H91.2056V79.0101H90.3376V80.8021H92.0316C91.5416 81.5581 90.8976 82.4401 90.1836 82.9721L91.5836 84.3441C92.3396 83.7841 93.3336 82.4681 93.6696 81.6001L92.1996 80.8021H95.4196L94.1176 81.6841C94.6076 82.2301 95.2376 83.0841 95.6016 83.7141L96.5256 82.9441ZM101.202 73.3961V74.9221H99.7316V73.3961H101.202ZM94.9716 73.8021V74.4881H93.1656V73.8021H94.9716ZM94.9716 76.0141V76.7561H93.1656V76.0141H94.9716ZM101.202 76.7281V78.2121H99.6896L99.7316 76.7281H101.202ZM94.9716 78.1841V79.0101H93.1656V78.1841H94.9716ZM96.8056 82.2441C96.4836 81.7821 95.9936 81.1801 95.5876 80.8021H97.4216C97.2676 81.3201 97.0716 81.7681 96.8056 82.2441Z',
      'M114.396 70.9321C113.696 72.1081 113.234 72.6261 112.1 73.4101L112.926 75.4821C114.326 74.5441 115.628 73.0461 116.342 71.5341L114.396 70.9321ZM116.23 76.0001V76.9801H122.026V77.8621H116.328V79.8921H117.798L116.65 80.8721C117.322 81.3901 118.106 82.3141 118.596 83.0141L120.262 81.6001C119.758 80.9701 119.184 80.3961 118.554 79.8921H122.026V81.7821C122.026 82.1601 121.9 82.2301 121.536 82.2301H119.646L120.01 84.3021H122.628C123.622 84.3021 124.126 83.8261 124.126 82.8181V79.8921H125.512V77.8621H124.126V76.9801H125.624V75.0201H121.774V73.9561H125.12V72.0941H121.774V71.0441H119.702V72.0941H116.706V73.9561H119.702V75.0201H116.692C116.706 74.9781 116.72 74.9501 116.734 74.9081L114.914 74.1661C114.256 75.5521 113.36 76.6021 112.156 77.5541L112.73 80.0601C112.954 79.8921 113.178 79.7101 113.388 79.5141V84.3021H115.474V77.2041C115.74 76.8121 115.992 76.4201 116.23 76.0001Z',
    ],
  },
  joker: {
    label: 'その他',
    paths: [
      'M81.6318 71.9401V73.9841L86.2938 73.8021C84.1798 75.3001 82.4018 76.2241 81.9818 76.4341C81.3518 76.7421 81.0298 76.7841 79.8398 76.9241L80.0638 79.0521C82.2338 78.5481 84.3618 78.1561 85.8318 77.8901C84.9078 78.5201 83.6478 79.3881 83.6478 81.0121C83.6478 83.7001 87.7498 83.7841 90.1858 83.8401L90.5498 81.6561C90.0038 81.6701 89.5418 81.6841 88.4638 81.6421C86.3218 81.5581 86.0418 81.0541 86.0418 80.4381C86.0418 79.4861 87.0918 78.4361 88.3238 77.9881C88.9118 77.7781 89.3178 77.7221 90.5498 77.7221C90.9558 77.7221 91.4738 77.7361 91.9918 77.7501L91.9638 75.7061C88.8838 75.7621 86.9938 76.0281 85.3418 76.2661C87.2178 75.4261 89.0098 74.2361 89.7378 73.7321V71.9401H81.6318Z',
      'M108.532 83.6161C109.904 83.4761 111.304 83.0701 112.298 82.2301C114.034 80.7601 114.258 78.5901 114.258 77.5401C114.258 74.5021 112.41 71.8981 108.336 71.8981C104.878 71.8981 101.742 74.1381 101.742 78.2681C101.742 80.1721 102.596 82.4681 104.668 82.4681C108.476 82.4681 108.966 74.8941 109.008 74.0261C110.422 74.1661 111.906 75.0061 111.906 77.4421C111.906 80.5081 109.652 81.3201 107.804 81.4601L108.532 83.6161ZM106.866 74.1521C106.488 78.3101 105.55 80.0601 104.766 80.0601C104.038 80.0601 103.898 78.8001 103.898 78.3241C103.898 76.2521 105.102 74.5861 106.866 74.1521Z',
      'M129.678 71.9261H127.704V75.1321L126.612 75.3981L126.822 77.4981L127.704 77.2881V82.5381C127.704 83.6721 128.124 84.1341 129.426 84.1341H134.802C135.782 84.1341 136.454 84.0081 136.678 81.1521L134.942 80.4801C135.74 80.3821 135.964 79.7661 136.062 79.1221C136.244 77.8201 136.258 75.3281 136.258 73.1021L132.968 73.8861V71.0581H130.966V74.3621L129.678 74.6701V71.9261ZM129.678 76.8121L130.966 76.4901V81.2221H132.968V76.0141L134.27 75.6921C134.27 76.3781 134.242 77.1621 134.2 77.8481C134.158 78.4641 134.088 78.5341 133.682 78.5341H133.22L133.598 80.4941H134.704C134.634 81.9921 134.452 82.1461 133.92 82.1461H130.434C129.692 82.1461 129.678 81.9361 129.678 81.4321V76.8121ZM124.358 84.3021H126.346V74.5861C126.906 73.0321 127.074 71.8001 127.158 71.1841L125.128 71.0581C124.974 72.4721 124.358 74.4461 123.098 75.9861L123.658 78.9821C123.938 78.7301 124.05 78.6181 124.358 78.2541V84.3021Z',
    ],
  },
};

export const EmoFacePaths = {
  joy: {
    label: 'joy',
    paths: [
      'M72 114.558C82.8531 114.558 91.628 105.76 91.628 94.9301H52.372C52.372 105.76 61.17 114.558 72 114.558Z',
      'M50.7325 81.814H29.465V70.0372H39.5099L33.4599 61.2623L43.1584 54.5657L55.5818 72.5773C56.8287 74.3784 56.9673 76.7107 55.9512 78.6504C54.9352 80.6132 52.9262 81.814 50.7325 81.814Z',
      'M114.558 81.814H93.2906C91.0968 81.814 89.0879 80.5901 88.0718 78.6504C87.0558 76.7107 87.1944 74.3784 88.4413 72.5773L100.865 54.5657L110.563 61.2623L104.513 70.0372H114.558V81.814Z',
    ],
  },
  trust: {
    label: 'trust',
    paths: [
      'M72 102.758C67.6588 102.758 64.1488 99.2482 64.1488 94.907H52.372C52.372 105.76 61.17 114.535 72 114.535C82.83 114.535 91.628 105.737 91.628 94.907H79.8512C79.8512 99.2482 76.3412 102.758 72 102.758Z',
      'M40.9185 85.7396C47.4227 85.7396 52.6953 80.4669 52.6953 73.9628C52.6953 67.4586 47.4227 62.186 40.9185 62.186C34.4144 62.186 29.1418 67.4586 29.1418 73.9628C29.1418 80.4669 34.4144 85.7396 40.9185 85.7396Z',
      'M103.105 85.7396C109.609 85.7396 114.881 80.4669 114.881 73.9628C114.881 67.4586 109.609 62.186 103.105 62.186C96.6004 62.186 91.3278 67.4586 91.3278 73.9628C91.3278 80.4669 96.6004 85.7396 103.105 85.7396Z',
    ],
  },
  fear: {
    label: 'fear',
    paths: [
      'M72 88.372C61.1469 88.372 52.372 97.17 52.372 108H91.6511C91.6511 97.17 82.8531 88.372 72 88.372Z',
      'M46.4606 80.4977C52.6029 74.3553 52.6029 64.4259 46.4606 58.2835L24.2463 80.4977C30.3656 86.6171 40.3182 86.6171 46.4606 80.4977Z',
      'M97.5395 80.4978C103.682 86.6402 113.611 86.6402 119.754 80.4978L97.5395 58.2835C91.4202 64.4028 91.4202 74.3554 97.5395 80.4978Z',
    ],
  },
  surprise: {
    label: 'surprise',
    paths: [
      'M72 88.372C82.8531 88.372 91.6279 97.17 91.6279 108H52.372C52.372 97.17 61.1469 88.372 72 88.372Z',
      'M40.9185 85.7396C47.4227 85.7396 52.6953 80.4669 52.6953 73.9628C52.6953 67.4586 47.4227 62.186 40.9185 62.186C34.4144 62.186 29.1417 67.4586 29.1417 73.9628C29.1417 80.4669 34.4144 85.7396 40.9185 85.7396Z',
      'M103.105 85.7396C109.609 85.7396 114.881 80.4669 114.881 73.9628C114.881 67.4586 109.609 62.186 103.105 62.186C96.6004 62.186 91.3277 67.4586 91.3277 73.9628C91.3277 80.4669 96.6004 85.7396 103.105 85.7396Z',
    ],
  },
  sadness: {
    label: 'sadness',
    paths: [
      'M72 88.372C61.1469 88.372 52.372 97.17 52.372 108H91.6511C91.6511 97.17 82.8531 88.372 72 88.372Z',
      'M50.7326 81.814H29.4651V70.0372H39.51L33.4599 61.2854L43.1585 54.5888L55.5818 72.6004C56.8288 74.4015 56.9673 76.7569 55.9513 78.6735C54.9352 80.6132 52.9263 81.814 50.7326 81.814Z',
      'M114.558 81.814H93.2906C91.0969 81.814 89.0879 80.5901 88.0719 78.6504C87.0559 76.7107 87.1944 74.3785 88.4414 72.5773L100.865 54.5658L110.563 61.2624L104.513 70.0372H114.558V81.814Z',
    ],
  },
  disgust: {
    label: 'disgust',
    paths: [
      'M72.0001 100.149C67.6589 100.149 64.1489 103.659 64.1489 108H52.3721C52.3721 97.1469 61.1701 88.372 72.0001 88.372C82.8301 88.372 91.6281 97.17 91.6281 108H79.8513C79.8513 103.659 76.3413 100.149 72.0001 100.149Z',
      'M40.8954 85.7395C47.3996 85.7395 52.6722 80.4669 52.6722 73.9628C52.6722 67.4586 47.3996 62.186 40.8954 62.186C34.3913 62.186 29.1186 67.4586 29.1186 73.9628C29.1186 80.4669 34.3913 85.7395 40.8954 85.7395Z',
      'M103.082 85.7395C109.586 85.7395 114.858 80.4669 114.858 73.9628C114.858 67.4586 109.586 62.186 103.082 62.186C96.5774 62.186 91.3047 67.4586 91.3047 73.9628C91.3047 80.4669 96.5774 85.7395 103.082 85.7395Z',
    ],
  },
  anger: {
    label: 'anger',
    paths: [
      'M71.9999 88.3721C61.1467 88.3721 52.3719 97.17 52.3719 108H91.6509C91.6509 97.17 82.853 88.3721 71.9999 88.3721Z',
      'M34.0603 81.1212C40.2027 87.2636 50.1321 87.2636 56.2746 81.1212L34.0603 58.907C27.9179 65.0494 27.9179 75.0019 34.0603 81.1212Z',
      'M109.94 81.1212C116.082 74.9788 116.082 65.0494 109.94 58.907L87.7255 81.1212C93.8679 87.2636 103.82 87.2636 109.94 81.1212Z',
    ],
  },
  anticipation: {
    label: 'anticipation',
    paths: [
      'M72.0001 114.558C82.8532 114.558 91.6281 105.76 91.6281 94.9301H52.3491C52.3721 105.76 61.1701 114.558 72.0001 114.558Z',
      'M40.9185 85.7396C47.4226 85.7396 52.6952 80.4669 52.6952 73.9628C52.6952 67.4586 47.4226 62.186 40.9185 62.186C34.4143 62.186 29.1418 67.4586 29.1418 73.9628C29.1418 80.4669 34.4143 85.7396 40.9185 85.7396Z',
      'M103.104 85.7396C109.608 85.7396 114.881 80.4669 114.881 73.9628C114.881 67.4586 109.608 62.186 103.104 62.186C96.6002 62.186 91.3276 67.4586 91.3276 73.9628C91.3276 80.4669 96.6002 85.7396 103.104 85.7396Z',
    ],
  },
  joker: {
    label: 'joker',
    paths: [],
  },
};
