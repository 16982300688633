// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const ListIcon = createIcon({
  displayName: 'ListIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M3.5 5C3.5 4.17157 4.17157 3.5 5 3.5C5.82843 3.5 6.5 4.17157 6.5 5C6.5 5.82843 5.82843 6.5 5 6.5C4.17157 6.5 3.5 5.82843 3.5 5Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M9.5 4C8.94772 4 8.5 4.44772 8.5 5C8.5 5.55228 8.94771 6 9.5 6H19C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4H9.5Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M9.5 11C8.94772 11 8.5 11.4477 8.5 12C8.5 12.5523 8.94771 13 9.5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H9.5Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8.5 19C8.5 18.4477 8.94772 18 9.5 18H19C19.5523 18 20 18.4477 20 19C20 19.5523 19.5523 20 19 20H9.5C8.94771 20 8.5 19.5523 8.5 19Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M5 10.5C4.17157 10.5 3.5 11.1716 3.5 12C3.5 12.8284 4.17157 13.5 5 13.5C5.82843 13.5 6.5 12.8284 6.5 12C6.5 11.1716 5.82843 10.5 5 10.5Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M3.5 19C3.5 18.1716 4.17157 17.5 5 17.5C5.82843 17.5 6.5 18.1716 6.5 19C6.5 19.8284 5.82843 20.5 5 20.5C4.17157 20.5 3.5 19.8284 3.5 19Z"
      />
    ),
  ],
});
