import { useEffect, useMemo, useRef } from 'react';
import { RecoilRoot } from 'recoil';
import { Provider } from 'urql';
import type { AppProps } from 'next/app';
import { Global } from '@emotion/react';
import { IntlProvider } from 'react-intl';
import { ChakraProvider } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { theme, SnackbarProvider } from '@emochan-cabinet/ui';
import { graphqlClient } from '~/helpers/graphql-client';
import { auth, remoteConfig } from '~/helpers/firebase';
import GtagInit from '~/components/functional/GtagInit';
import { sendPageview } from '~/lib/gtag';
import ja from '~/application/translations/locales/ja';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { getDebugData } from '~/helpers/debug';

console.debug(`[BOOT] ${process.env.NODE_ENV}`);
console.debug(`[BOOT] ${process.env.NEXT_PUBLIC_COMMIT_SHA}`);

export default function EmochanApp({ Component, pageProps, router }: AppProps): JSX.Element {
  const locale = 'ja';

  const debugTimer = useRef<NodeJS.Timer|null>(null);
  const debugClickCount = useRef(0);

  const translations = useMemo(() => ({ ja }), []);

  const messages = useMemo(() => {
    switch (locale) {
      case 'ja':
        return translations.ja;
      default:
        return translations.ja;
    }
  }, [locale, translations.ja]);

  useEffect(() => {
    if (remoteConfig === null) return;
    const defaultV = (process.env.NEXT_PUBLIC_VERSION || '').replace(/^\w+ v/, '');

    if (remoteConfig) {
      remoteConfig.defaultConfig = { version: defaultV };
    }

    fetchAndActivate(remoteConfig)
      .then(() => {
        if (remoteConfig === null) return;
        const val = getValue(remoteConfig, 'version').asString();

        if (val !== defaultV) {
          // location.reload();
        }
      })
      .catch((err) => {
        // ...
        console.warn(err);
      });
  }, []);

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--emochan-vh', `${vh}px`);
    };

    const setVvh = () => {
      const vh = window.innerHeight * 0.01;
      const vvh = window.visualViewport ? window.visualViewport.height * 0.01 : vh;

      document.documentElement.style.setProperty('--emochan-vvh', `${vvh}px`);
    };

    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);
    window.visualViewport?.addEventListener('resize', setVvh);

    if (document.readyState === 'complete') {
      setVh();
      setVvh();
    }

    return () => {
      window.removeEventListener('load', setVh);
      window.removeEventListener('resize', setVh);
      window.visualViewport?.removeEventListener('resize', setVvh);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      auth.currentUser?.getIdToken(true)
        .then((token) => {
          console.debug('[AUTH] refresh', token);
          // console.debug(result.claims.workspaceIds);
        })
        .catch(() => null);
      sendPageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const debugHandler = () => {
      debugClickCount.current += 1;

      if (debugClickCount.current === 5) {
        debugClickCount.current = 0;
        getDebugData()
          .then((result) => {
            navigator.clipboard.writeText(result)
              .catch((err) => {
                console.debug(err);
              });
          })
          .catch((err) => {
            console.warn(err);
          });
      }

      debugTimer.current = setTimeout(() => {
        debugClickCount.current = 0;
      }, 2000);
    };

    document.body.addEventListener('click', debugHandler);

    return () => {
      document.body.removeEventListener('click', debugHandler);

      if (debugTimer.current) {
        clearTimeout(debugTimer.current);
      }
    };
  }, []);

  const customTheme = {
    ...theme,
    breakpoints: {
      ...theme.breakpoints,
      // home, mypage のカード並べレイアウト用
      'card.lg': '69rem',
      'card.xl': '91.25rem',
      'card.xxl': '113.5rem',
      // サマリーレイアウト用
      // see also: https://www.notion.so/kouinc/Responsive-windows-cd6221221c0e4acba6efa26421571e4c#90ca5cf8a8c4434ab9fc8baf8763bc6a
      'summary.s': '30rem',
      'summary.m': '45rem',
      'summary.l': '52rem',
    },
  };

  return (
    <IntlProvider locale={locale} defaultLocale="ja" messages={messages}>
      <ChakraProvider theme={customTheme}>
        <GtagInit />
        <Global
          styles={{
            '#__next': { height: '100%' },
            '#__next .chakra-container': { maxWidth: '100%' },
            // 処理が走らない時ように
            '--emochan-vh': '1vh',
          }}
        />
        <RecoilRoot>
          <SnackbarProvider>
            <Provider value={graphqlClient}>
              <AnimatePresence mode="wait">
                <Component {...pageProps} key={router.route} />
              </AnimatePresence>
            </Provider>
          </SnackbarProvider>
        </RecoilRoot>
      </ChakraProvider>
    </IntlProvider>
  );
}
