// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const MemberCheckIcon = createIcon({
  displayName: 'MemberCheckIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M15.994 12.584L19.586 8.99869C19.977 8.60845 20.6103 8.60919 21.0004 9.00035C21.3901 9.39123 21.3894 10.024 20.9987 10.414L16.3474 15.0572C16.1522 15.2521 15.8359 15.252 15.6408 15.057L13.2933 12.7109C12.9027 12.3205 12.9026 11.6874 13.2931 11.2969C13.6835 10.9065 14.3165 10.9065 14.7069 11.2969L15.994 12.584Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M12 19H14C14 15.6863 11.3137 13 8 13C4.68629 13 2 15.6863 2 19H4C4 16.7909 5.79086 15 8 15C10.2091 15 12 16.7909 12 19Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M2 19H4C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M12 19H14C14 19.5523 13.5523 20 13 20C12.4477 20 12 19.5523 12 19Z"
      />
    ),
  ],
});
