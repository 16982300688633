import { tokens } from '@emochan-cabinet/design-tokens';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { theme as defaultTheme } from '@chakra-ui/react';

const breakpoints = {
  sm: '22.5rem',
  md: '45rem',
  lg: '60rem',
  xl: '90.5rem',
};

function getSize(value: string) {
  if (value === 'full') {
    return {
      dialog: {
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': { minH: '-webkit-fill-available' },
        my: 0,
      },
    };
  }

  if (value === 'default') {
    return { maxW: 'min(calc(100% - 3rem), 30rem)' };
  }

  return { dialog: { maxW: value } };
}

export const theme = {
  config: { cssVarPrefix: 'emochan' },
  styles: {
    global: {
      '*, *::before, *::after': { boxSizing: 'border-box' },
      'html, body': { height: '100%' },
      'body, h1, h2, h3, h4, p, figure, blockquote, dl, dd': { margin: 0 },
      'ul[role="list"], ol[role="list"]': { listStyle: 'none' },
      html: { minHeight: '100vh' },
      body: {
        fontFamily: 'body',
        overscrollBehavior: 'none',
        textRendering: 'optimizeSpeed',
        color: 'grey.100',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        WebkitTextSizeAdjust: '100%',
      },
      '@supports (-webkit-touch-callout: none):': { 'html, body': { height: '-webkit-fill-available' } },
      'img, picture': { maxWidth: '100%', display: 'block' },
      'input, button, textarea, select': { font: 'inherit' },
      button: {
        margin: '0',
        background: 'none',
        border: 'none',
        padding: '0',
        textAlign: 'left',
        color: 'inherit',
      },
      'button:hover(not:disabled,[aria-disabled="true"])': { cursor: 'pointer' },
      a: { color: 'currentColor', textDecoration: 'inherit' },
      svg: { verticalAlign: 'top' },
      table: { width: '100%', borderCollapse: 'collapse', borderSpacing: '0' },
      'th, td': { padding: '0' },
      '::-webkit-scrollbar': { width: '0' },
      '@media (prefers-reduced-motion: reduce)': {
        'html:focus-within': { scrollBehavior: 'auto' },
        '*, *::before, *::after': {
          animationDuration: '0.01ms !important',
          animationIterationCount: '1 !important',
          transitionDuration: '0.01ms !important',
          scrollBehavior: 'auto !important',
        },
      },
      '::selection': { bg: 'rgba(51, 105, 255, 0.16)' },
      // Editor
      '.emochan-editor .ProseMirror': {
        p: '16',
        overflowY: 'auto',
        borderRadius: '12',
      },
      '.ProseMirror:focus': {
        outline: 'none',
        bg: 'grey.0',
        boxShadow: 'inset 0 0 0 1px var(--emochan-colors-blue-50)',
      },
      // empty <p />
      '.emochan-memo p:empty': { height: '1.5em' },
      // link color
      '.emochan-link': { color: 'var(--emochan-colors-blue-30)' },
      '.emochan-memo ul, .emochan-memo ol': { ml: '1.8em', mb: '1.5em' },
      '.emochan-memo ol': { listStyleType: 'decimal' },
      '.emochan-memo ol ol': { listStyleType: 'lower-alpha' },
      '.emochan-memo ol ol ol': { listStyleType: 'lower-roman' },
      '.emochan-memo ol ol ol ol': { listStyleType: 'decimal' },
      '.emochan-memo li + li': { marginTop: '.5em' },
      '.emochan-memo > * + *, .emochan-memo .ProseMirror > * + *': { marginTop: '.775em' },
      '.emochan-memo h2': {
        fontSize: 'heading.xs',
        lineHeight: 'heading.xs',
        fontWeight: 'bold',
        mb: 24,
      },
      '.emochan-memo h3': {
        fontSize: 'label.medium',
        lineHeight: 'label.medium',
        fontWeight: 'bold',
        mb: 12,
      },
      '.emochan-memo hr': {
        borderColor: 'border.light.selected',
        margin: '1.5em .5em',
      },
      '.emochan-memo p': { mb: 24 },
      '.emochan-memo li > p': { mb: 0 },
      '.emochan-memo blockquote': {
        fontStyle: 'italic',
        lineHeight: 'paragraph.medium',
        pl: 16,
        py: 4,
        mx: 12,
        borderInlineStartWidth: '0.25em',
        borderInlineStartColor: 'grey.50',
      },
      '.emochan-memo > *:last-child, .ProseMirror > *:last-child': {
        mb: 8,
      },
      '.emochan-memo th, .emochan-memo td': { padding: '8' },
      '.emochan-memo tbody tr': { borderBottomWidth: '1px', borderBottomColor: 'border.light.transparent' },
      '.emochan-memo:has(.ProseMirror) > *:last-child': {
        mb: 0,
      },
      // placeholder
      '.ProseMirror p.is-editor-empty:first-of-type::before': {
        display: 'inline-block',
        height: 'auto',
        content: 'attr(data-placeholder)',
        color: 'grey.60',
        pointerEvents: 'none',
      },
      '.ProseMirror.ProseMirror-focused p.is-editor-empty:first-of-type::before': {
        height: 0,
        float: 'left',
        color: 'grey.30',
      },
    },
  },
  components: {
    Button: { variants: { } },
    Container: {
      baseStyle(props: StyleFunctionProps) {
        const { maxW } = props;

        return {
          mx: undefined,
          pl: `max(var(--emochan-space-16), calc(50% - calc(var(--emochan-sizes-${maxW.replace('.', '-')}) / 2)))`,
          pr: `max(var(--emochan-space-16), calc(50% - calc(var(--emochan-sizes-${maxW.replace('.', '-')}) / 2)))`,
          maxW: '100%',
          maxWidth: '100%',
        };
      },
    },
    Modal: {
      ...defaultTheme.components.Modal,
      defaultProps: { size: 'default' },
      sizes: {
        default: getSize('default'),
        full: getSize('full'),
      },
      baseStyle(props: StyleFunctionProps) {
        const { isCentered, scrollBehavior } = props;

        return {
          overlay: {
            bg: 'transparent.grey.30',
            zIndex: 'modal',
          },
          dialogContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: isCentered ? 'center' : 'flex-start',
            overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
            zIndex: 'modal',
          },
          dialog: {
            maxW: 'min(calc(100% - 3rem), 30rem)',
            borderRadius: 24,
            bg: 'grey.0',
            color: 'inherit',
            boxShadow: 'high.below',
            overscrollBehavior: 'contain',
          },
          header: {
            pt: 24,
            px: 24,
            pb: 8,
            fontSize: 'heading.xs',
            lineHeight: 'heading.xs',
            fontWeight: 'heading',
          },
          body: {
            pt: 8,
            pb: 32,
            px: 24,
            flex: 1,
            overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
            maxHeight: '70vh',
            whiteSpace: 'pre-wrap',
          },
          footer: { justify: 'flex-end' },
        };
      },
    },
    Toolip: {
      baseStyle(props: StyleFunctionProps) {
        return {
          py: 8,
          px: 12,
          borderRadius: 8,
          shadow: 'high.below',
          zIndex: 'tooltip',
        };
      },
    },
    Divider: {
      ...defaultTheme.components.Divider,
      baseStyle: { borderColor: 'transparent.grey.10' },
    },
  },
  breakpoints,
  lineHeights: { ...tokens.emochan.line.height },
  colors: {
    ...tokens.emochan.color,
    primary: tokens.emochan.color.grey['100'],
    accent: tokens.emochan.color.blue['50'],
    negative: tokens.emochan.color.red['50'],
    warning: tokens.emochan.color.yellow['50'],
    positive: tokens.emochan.color.green['50'],
    border: {
      dark: {
        opaque: tokens.emochan.border.darkOpaque,
        selected: tokens.emochan.border.darkSelected,
        transparent: tokens.emochan.border.darkTransparent,
      },
      light: {
        opaque: tokens.emochan.border.lightOpaque,
        selected: tokens.emochan.border.lightSelected,
        transparent: tokens.emochan.border.lightTransparent,
      },
    },
    joy: 'var(--emochan-colors-gradient-yellow)',
    trust: 'var(--emochan-colors-gradient-lime)',
    fear: 'var(--emochan-colors-gradient-green)',
    surprise: 'var(--emochan-colors-gradient-aqua)',
    sadness: 'var(--emochan-colors-gradient-navy)',
    disgust: 'var(--emochan-colors-gradient-purple)',
    anger: 'var(--emochan-colors-gradient-red)',
    anticipation: 'var(--emochan-colors-gradient-orange)',
  },
  radii: { ...tokens.emochan.border.radius },
  fontSizes: { ...tokens.emochan.font.size },
  fonts: {
    heading: tokens.emochan.font.body,
    body: tokens.emochan.font.body,
    mono: tokens.emochan.font.mono,
  },
  fontWeights: { ...tokens.emochan.font.weight },
  space: {
    ...defaultTheme.space,
    ...tokens.emochan.space,
  },
  sizes: {
    ...tokens.emochan.size,
    container: { ...tokens.emochan.maxWidth },
  },
  shadows: { ...tokens.emochan.shadow },
  zIndices: { ...tokens.emochan.zIndex },
};
