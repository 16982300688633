import { useMemo, useCallback, ChangeEvent } from 'react';
import { forwardRef, Input as ChakraInput, InputProps as ChakraInputProps } from '@chakra-ui/react';
import { useFormControl, useFormTextInput } from '../FormControl';
import { TextFieldStyle, TextFieldSx } from './TextFieldStyle';

/**
 * Input Component です。
 * Size Variants は未定義です。
 */
export const Input = forwardRef<ChakraInputProps, 'input'>(({
  maxLength,
  onInput,
  ...props
}, ref) => {
  const initialMax = maxLength;
  const initialValue = props.defaultValue as string || '';

  // 判定は FormControl から注入する
  const {
    id,
    isDisabled,
    isInvalid,
    isValid,
    isRequired,
  } = useFormControl();
  const {
    feedbackId,
    onInput: onFormInput,
  } = useFormTextInput<HTMLInputElement>(initialValue, initialMax);

  const requiredProps = useMemo(() => {
    if (isRequired) {
      return { isRequired };
    }

    return {};
  }, [isRequired]);

  const invalidProps = useMemo(() => {
    if (isInvalid) {
      return {
        isInvalid: true,
        'aria-describedby': feedbackId,
      };
    }

    if (isValid) {
      return {
        bg: 'green.5',
        boxShadow: 'inset 0 0 0 2px var(--emochan-colors-green-50)',
        _hover: { bg: 'green.5' },
      };
    }

    return {};
  }, [feedbackId, isInvalid, isValid]);

  const inputProps = {
    ...props,
    ...requiredProps,
    ...invalidProps,
    sx: {
      ...TextFieldSx,
      caretColor: isValid ? 'var(--emochan-colors-green-50)' : TextFieldSx.caretColor,
    },
  };

  const handleInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onInput) {
      onInput(e);
    }
    onFormInput(e);
  }, [onFormInput, onInput]);

  return (
    <ChakraInput
      {...TextFieldStyle}
      id={props.id || id}
      {...inputProps}
      ref={ref}
      onInput={handleInput}
      disabled={isDisabled || props.disabled}
    />
  );
});

Input.displayName = 'Input';
