// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const VisibilityOnIcon = createIcon({
  displayName: 'VisibilityOnIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11.9997 9C10.3488 9 8.98344 10.359 8.98344 12C8.98344 13.642 10.3488 15 11.9997 15C13.6496 15 15.016 13.642 15.016 12C15.016 10.359 13.6496 9 11.9997 9Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M11.9997 5C4.36654 5 2.07249 11.617 2.05149 11.684C1.98289 11.8885 1.98247 12.1113 2.05049 12.316C2.07249 12.383 4.36654 19 11.9997 19C19.6329 19 21.9269 12.383 21.9479 12.316C22.0167 12.111 22.0171 11.8892 21.9489 11.684C21.9269 11.617 19.6329 5 11.9997 5ZM11.9997 17C7.15194 17 4.99455 13.8435 4.25832 12.3917C4.13329 12.1451 4.13334 11.8549 4.2584 11.6083C4.99581 10.1545 7.15365 7 11.9997 7C16.8475 7 19.0048 10.1565 19.7411 11.6083C19.8661 11.8549 19.8661 12.1451 19.741 12.3917C19.0036 13.8455 16.8457 17 11.9997 17Z"
      />
    ),
  ],
});
