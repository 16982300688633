// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const FaceSubtleSmileIcon = createIcon({
  displayName: 'FaceSubtleSmileIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M15.38 14.13C15.59 13.78 16.06 13.67 16.41 13.89C16.76 14.11 16.87 14.57 16.65 14.92C15.63 16.53 13.9 17.5 12 17.5C10.1 17.5 8.37 16.54 7.35 14.91C7.13 14.56 7.24 14.1 7.59 13.88C7.94 13.66 8.4 13.77 8.62 14.12C9.36 15.3 10.62 16 12 16C13.38 16 14.64 15.3 15.38 14.13Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
      />
    ),
  ],
});
