import { cloneElement, forwardRef, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { Avatar, AvatarProps } from '../../displays/Avatar';

export interface AvatarAndEditProps extends AvatarProps {
  iconElement: JSX.Element
}

// Profile / Workspace Edit
export const AvatarAndEdit = forwardRef<HTMLButtonElement, AvatarAndEditProps>(({
  name,
  kind = 'user',
  imageURL,
  iconElement,
}, ref) => {
  const iconButtonProps = useMemo(() => ({
    ref,
    color: 'secondary',
    w: '36',
    h: '36',
    margin: '0.375rem',
    bg: 'grey.0',
    boxShadow: 'low.below',
    _before: {
      boxShadow: 'inset 0 0 0 1px var(--emochan-colors-transparent-grey-10)',
      borderRadius: 'half',
    },
  }), [ref]);

  const element = useMemo(
    () => cloneElement(iconElement, iconButtonProps),
    [iconElement, iconButtonProps],
  );

  const iconButton = cloneElement(element, iconButtonProps);

  return (
    <Box
      display="inline-block"
      w="80"
      h="80"
      position="relative"
      sx={{ aspectRatio: '1 / 1' }}
    >
      <Avatar
        kind={kind}
        size="large"
        name={name}
        imageURL={imageURL}
      />
      <Box
        sx={{ '--AvatarAndEdit-position': 'calc(var(--emochan-sizes-16) * -1)' }}
        position="absolute"
        right="var(--AvatarAndEdit-position)"
        bottom="var(--AvatarAndEdit-position)"
      >
        {iconButton}
      </Box>
    </Box>
  );
});
