// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const TrashcanIcon = createIcon({
  displayName: 'TrashcanIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M10 11C9.44772 11 9 11.4477 9 12V16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16V12C11 11.4477 10.5523 11 10 11Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M14 11C13.4477 11 13 11.4477 13 12V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V12C15 11.4477 14.5523 11 14 11Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M20 6H17C17 5.07003 17 4.60504 16.8978 4.22354C16.6204 3.18827 15.8117 2.37962 14.7765 2.10222C14.395 2 13.93 2 13 2H11C10.07 2 9.60504 2 9.22354 2.10222C8.18827 2.37962 7.37962 3.18827 7.10222 4.22354C7 4.60504 7 5.07003 7 6H4C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H5V16.2414C4.99999 17.0463 4.99998 17.7107 5.04419 18.2519C5.09012 18.814 5.18868 19.3307 5.43598 19.816C5.81947 20.5687 6.43139 21.1806 7.18404 21.5641C7.66937 21.8114 8.18608 21.9099 8.74818 21.9559C9.28937 22.0001 9.95372 22.0001 10.7587 22.0001H13.2413C14.0463 22.0001 14.7106 22.0001 15.2518 21.9559C15.8139 21.9099 16.3306 21.8114 16.816 21.5641C17.5686 21.1806 18.1805 20.5687 18.564 19.816C18.8113 19.3307 18.9099 18.814 18.9558 18.2519C19 17.7107 19 17.0463 19 16.2413L19 8H20C20.5523 8 21 7.55228 21 7C21 6.44772 20.5523 6 20 6ZM11 4H13C13.4831 4 13.7787 4.00048 14.0028 4.0107C14.1904 4.01926 14.2518 4.03239 14.2588 4.03407C14.6039 4.12654 14.8735 4.39609 14.9659 4.74118C14.9639 4.73371 14.9796 4.7839 14.9893 4.99721C14.9995 5.22127 15 5.51689 15 6H9C9 5.51689 9.00048 5.22127 9.0107 4.99721C9.02043 4.7839 9.03608 4.73371 9.03407 4.74118C9.12654 4.39609 9.39609 4.12654 9.74118 4.03407C9.73371 4.03608 9.7839 4.02043 9.99721 4.0107C10.2213 4.00048 10.5169 4 11 4ZM17 8H7V16.2001C7 17.0566 7.00078 17.6389 7.03755 18.089C7.07337 18.5274 7.1383 18.7516 7.21799 18.908C7.40974 19.2844 7.7157 19.5903 8.09202 19.7821C8.24842 19.8618 8.47262 19.9267 8.91104 19.9625C9.36113 19.9993 9.94342 20.0001 10.8 20.0001H13.2C14.0566 20.0001 14.6389 19.9993 15.089 19.9625C15.5274 19.9267 15.7516 19.8618 15.908 19.7821C16.2843 19.5903 16.5903 19.2844 16.782 18.908C16.8617 18.7516 16.9266 18.5274 16.9624 18.089C16.9992 17.6389 17 17.0566 17 16.2001V8Z"
      />
    ),
  ],
});
