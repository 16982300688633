import {
  Popover,
  PopoverProps,
  PopoverTrigger,
  PopoverContent,
  PopoverAnchor,
} from '@chakra-ui/react';
import { FC } from 'react';
import { DropdownItem } from './DropdownItem';

export const Dropdown: FC<PopoverProps> & {
  Button: typeof PopoverTrigger;
  Menu: typeof PopoverContent;
  Item: typeof DropdownItem;
  Anchor: typeof PopoverAnchor;
} = ({
  children,
  ...props
}) => (
  <Popover {...props}>
    {children}
  </Popover>
);

Dropdown.displayName = 'Dropdown';
Dropdown.Button = PopoverTrigger;
Dropdown.Menu = PopoverContent;
Dropdown.Item = DropdownItem;
Dropdown.Anchor = PopoverAnchor;
