import { forwardRef, useMemo } from 'react';
import { Text, HTMLChakraProps, SystemProps, ResponsiveValue } from '@chakra-ui/react';

export const HeadingSizes = {
  xxl: 'xxl',
  xl: 'xl',
  large: 'large',
  medium: 'medium',
  small: 'small',
  xs: 'xs',
} as const;
export type HeadingSize = typeof HeadingSizes[keyof typeof HeadingSizes];

export interface HeadingProps extends Omit<HTMLChakraProps<'div'>, 'textAlign' | 'fontWeight'> {
  size?: ResponsiveValue<HeadingSize>,
  align?: SystemProps['textAlign']
}

export const Heading = forwardRef<HTMLDivElement, HeadingProps>(({
  size = 'large',
  align,
  children,
  ...rest
}, ref) => {
  const responsiveSize = useMemo(() => {
    if (!size) return size;

    if (typeof size === 'string') {
      return `heading.${size}`;
    }

    if (Array.isArray(size)) {
      return size.map((s) => `heading.${s}`);
    }

    const r: Record<string, string> = {};

    Object.entries(size).forEach(([key, value]) => {
      r[key] = `heading.${value}`;
    });

    return r;
  }, [size]);

  return (
    <Text
      ref={ref}
      fontSize={responsiveSize}
      lineHeight={responsiveSize}
      fontWeight="heading"
      textAlign={align}
      {...rest}
    >
      {children}
    </Text>
  );
});

Heading.displayName = 'Heading';
