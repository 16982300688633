import { useRef, useEffect, useState, FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type ClientOnlyPortalProps = {
  children: ReactNode
  selector: string
}

export const ClientOnlyPortal: FC<ClientOnlyPortalProps> = ({ children, selector }) => {
  const ref = useRef<Element|null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);

    if (ref.current === null) {
      throw new Error('ClientOnlyPortal: target element is not found.');
    }
    setMounted(true);
  }, [selector]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};
