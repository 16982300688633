// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const DialogueIcon = createIcon({
  displayName: 'DialogueIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M9.53571 17C10.1017 17.98 11.0697 18.6944 12.2196 18.9231C12.606 19 13.0707 19 14 19H14.6595C15.5103 19 16.3187 19.3718 16.8724 20.0178L17.4445 20.6852C19.009 22.5105 22 21.4041 22 19V12.4C22 10.1598 22 9.03969 21.564 8.18404C21.2097 7.48867 20.6604 6.91343 19.9853 6.52747C20 7.06745 20 7.66718 20 8.32292L20.0001 10.1678C20.0003 10.4595 20.0005 10.7356 19.9942 10.9983C19.9996 11.3886 20 11.8474 20 12.4V19C20 19.5473 19.3191 19.7991 18.963 19.3836L18.3909 18.7162C17.4572 17.6269 16.0942 17 14.6595 17H14C13.8606 17 13.7361 16.9998 13.6246 16.9994C13.4766 17.0003 13.3244 17.0002 13.1678 17.0001L9.53571 17Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8.4 4H11.6C12.7531 4 13.4977 4.00156 14.0643 4.04785C14.6077 4.09225 14.8091 4.1676 14.908 4.21799C15.2843 4.40973 15.5903 4.71569 15.782 5.09202C15.8324 5.19091 15.9078 5.39235 15.9521 5.93567C15.9984 6.50235 16 7.24689 16 8.4V10C16 11.0252 15.9903 11.2457 15.9616 11.3902C15.8038 12.1836 15.1836 12.8038 14.3902 12.9616C14.2457 12.9903 14.0252 13 13 13H9.34054C7.90584 13 6.54277 13.6269 5.60907 14.7162L5.03703 15.3836C4.68087 15.7991 4 15.5473 4 15V8.4C4 7.24689 4.00156 6.50235 4.04785 5.93567C4.09225 5.39235 4.1676 5.19091 4.21799 5.09202C4.40973 4.71569 4.7157 4.40973 5.09202 4.21799C5.19091 4.1676 5.39235 4.09225 5.93567 4.04785C6.50235 4.00156 7.24689 4 8.4 4ZM11.6 2H8.4C6.15979 2 5.03969 2 4.18404 2.43597C3.43139 2.81947 2.81947 3.43139 2.43597 4.18404C2 5.03969 2 6.15979 2 8.4V15C2 17.4041 4.99099 18.5105 6.55554 16.6852L7.12759 16.0178C7.68132 15.3718 8.48969 15 9.34054 15H13C13.9293 15 14.394 15 14.7804 14.9231C16.3671 14.6075 17.6075 13.3671 17.9231 11.7804C18 11.394 18 10.9293 18 10V8.4C18 6.15979 18 5.03969 17.564 4.18404C17.1805 3.43139 16.5686 2.81947 15.816 2.43597C14.9603 2 13.8402 2 11.6 2Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M6.5 7C6.5 6.58579 6.83579 6.25 7.25 6.25H12.75C13.1642 6.25 13.5 6.58579 13.5 7C13.5 7.41421 13.1642 7.75 12.75 7.75H7.25C6.83579 7.75 6.5 7.41421 6.5 7Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M6.5 10C6.5 9.58579 6.83579 9.25 7.25 9.25H12.75C13.1642 9.25 13.5 9.58579 13.5 10C13.5 10.4142 13.1642 10.75 12.75 10.75H7.25C6.83579 10.75 6.5 10.4142 6.5 10Z"
      />
    ),
  ],
});
