// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const FaceDisgustIcon = createIcon({
  displayName: 'FaceDisgustIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M15.38 16.1364C15.59 16.4864 16.06 16.5964 16.41 16.3764C16.76 16.1564 16.87 15.6964 16.65 15.3464C15.63 13.7364 13.9 12.7664 12 12.7664C10.1 12.7664 8.37 13.7264 7.35 15.3564C7.13 15.7064 7.24 16.1664 7.59 16.3864C7.94 16.6064 8.4 16.4964 8.62 16.1464C9.36 14.9664 10.62 14.2664 12 14.2664C13.38 14.2664 14.64 14.9664 15.38 16.1364Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
      />
    ),
  ],
});
