import { FC } from 'react';
import { Flex, Box, keyframes } from '@chakra-ui/react';
import { Label } from '../../typography/Label';
import { Logo } from '../../displays/Logo';

const loadingSpin = keyframes`
  0% {
    transform: rotate(0);
  }
  45% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  95% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const showDot = keyframes({ from: { width: 0 } });

export function EmochanSpinner({ text }: { text?: string }): JSX.Element {
  return (
    <Flex h="100%">
      <Flex
        w="4.5rem"
        flexDir="column"
        alignItems="center"
        m="auto"
        flexShrink={1}
        flexGrow={1}
        userSelect="none"
      >
        <Box animation={`${loadingSpin} 2.5s ease infinite`} aria-hidden="true">
          <Logo size="large" kind="emochanMark" />
        </Box>
        {
          text && (
            <Label pt="24" align="center" size="small" whiteSpace="pre-wrap">{text}</Label>
          )
        }
        {
          !text && (
            <Flex w="4.5rem" pt="24">
              <Label as="div" size="small">Loading</Label>
              <Box display="inline-block" w="1.1em" overflow="hidden" whiteSpace="nowrap" animation={`${showDot} 3s steps(4) infinite`}>
                <Label size="small">...</Label>
              </Box>
            </Flex>
          )
        }
      </Flex>
    </Flex>
  );
}
