// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const LinkDisabledIcon = createIcon({
  displayName: 'LinkDisabledIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M21 20.9999C21.3905 20.6094 21.3905 19.9762 21 19.5857L4.41421 2.99992C4.02369 2.6094 3.39052 2.6094 3 2.99992C2.60947 3.39045 2.60948 4.02361 3 4.41414L5.74467 7.15881C3.59084 7.71571 2 9.67203 2 11.9999C2 14.7613 4.23858 16.9999 7 16.9999H9C9.55228 16.9999 10 16.5522 10 15.9999C10 15.4476 9.55228 14.9999 9 14.9999H7C5.34315 14.9999 4 13.6568 4 11.9999C4 10.3431 5.34315 8.99991 7 8.99991H7.58577L9.58577 10.9999H8C7.44772 10.9999 7 11.4476 7 11.9999C7 12.5522 7.44772 12.9999 8 12.9999H11.5858L19.5858 20.9999C19.9763 21.3904 20.6095 21.3904 21 20.9999Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M18.7077 14.4668L20.1356 15.8947C21.2726 14.9782 22 13.574 22 11.9999C22 9.23849 19.7614 6.99991 17 6.99991H15C14.4477 6.99991 14 7.44763 14 7.99991C14 8.55219 14.4477 8.99991 15 8.99991H17C18.6569 8.99991 20 10.3431 20 11.9999C20 13.0223 19.4886 13.9252 18.7077 14.4668Z"
      />
    ),
  ],
});
