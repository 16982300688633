import { FC, ReactNode, memo } from 'react';
import { Flex } from '@chakra-ui/react';

export interface ListItemImageProps {
  children?: ReactNode
}

export const ListItemImage: FC<ListItemImageProps> = memo(({ children }) => (
  <Flex px="16" py="12" alignItems="center" justifyContent="center" minWidth="4.5rem">
    {children}
  </Flex>
));

ListItemImage.displayName = 'ListItemImage';
