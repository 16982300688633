function getSpeechRecognition(): {
  new (): SpeechRecognition;
  prototype: SpeechRecognition;
} | undefined {
  if (typeof window !== 'object') return;

  // https://developer.mozilla.org/ja/docs/Web/API/SpeechRecognition
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  return SpeechRecognition;
}

function getSpeechGrammarList(): {
  new (): SpeechGrammarList;
  prototype: SpeechGrammarList;
} | undefined {
  if (typeof window !== 'object') return;

  // https://developer.mozilla.org/ja/docs/Web/API/SpeechGrammarList
  const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;

  return SpeechGrammarList;
}

let r: SpeechRecognition;

function isAndroidBrowser() {
  if (typeof window !== 'object') return false;
  if (window.navigator && window.navigator.userAgent) {
    return /Mobile Safari\//.test(window.navigator.userAgent) && /Chrome\//.test(window.navigator.userAgent);
  }

  return false;
}

export function createSpeechRecognition(lang?: string): SpeechRecognition | undefined {
  const SpeechRecognition = getSpeechRecognition();
  if (!SpeechRecognition) return;

  if (r) {
    r.abort();
    r.stop();

    return r;
  }

  r = new SpeechRecognition();

  const GrammarList = getSpeechGrammarList();

  if (GrammarList) {
    const grammar = `
    #JSGF V1.0 JIS ja;
    grammar words;
    public <words> = emochan | えもちゃん | 1on1 | ワン・オン・ワン | ワンオンワン;
    `;
    const sgl = new GrammarList();
    sgl.addFromString(grammar, 10);
    r.grammars = sgl;
  }

  r.continuous = true;
  // Android Chrome だけ interimResults を true にすると result が二重に発火するからだめ
  r.interimResults = !isAndroidBrowser();
  r.lang = lang || 'ja-JP';

  return r;
}
