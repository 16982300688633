// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const ArrowUpCircleIcon = createIcon({
  displayName: 'ArrowUpCircleIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M15.7092 8.29502C15.804 8.3904 15.8757 8.50014 15.9241 8.61722C15.9727 8.73425 15.9996 8.8625 16 8.997L16 9V15C16 15.5523 15.5523 16 15 16C14.4477 16 14 15.5523 14 15V11.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L12.5858 10H9C8.44772 10 8 9.55228 8 9C8 8.44772 8.44772 8 9 8H14.9998C15.2749 8 15.5242 8.11106 15.705 8.29078L15.7092 8.29502Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
      />
    ),
  ],
});
