import { useRef, useState, useEffect } from 'react';
import { Box, Textarea, TextareaProps } from '@chakra-ui/react';
import { Paragraph } from '../../typography/Paragraph';

export interface EditableTranscriptProps extends TextareaProps {
  name: string,
  handleEnter?(val: string): Promise<void>,
  resetAfterEnter?: boolean,
}

export function EditableTranscript({
  name,
  handleEnter,
  resetAfterEnter = false,
  defaultValue,
  onFocus,
  onBlur,
  onChange,
  ...rest
}: EditableTranscriptProps): JSX.Element {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(defaultValue || '');
  const ref = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (editing || resetAfterEnter) return;

    if (value !== defaultValue && ref.current) {
      setValue(defaultValue || '');
      ref.current.value = defaultValue as string || '';
    }
  }, [editing, value, resetAfterEnter, defaultValue]);

  return (
    <Box pos="relative" w="100%">
      <Paragraph
        as="div"
        size="medium"
        overflow="hidden"
        visibility="hidden"
        whiteSpace="pre-wrap"
        overflowWrap="break-word"
        wordBreak="break-all"
        aria-hidden="true"
        sx={{ wordWrap: 'break-word' }}
      >
        {`${value || rest.placeholder}\u200b`}
      </Paragraph>
      <Paragraph as="div" w="100%" size="medium">
        <Textarea
          ref={ref}
          w="100%"
          h="100%"
          pos="absolute"
          top="50%"
          left={0}
          transform="translateY(-50%)"
          resize="none"
          m={0}
          border="none"
          _placeholder={{ color: 'grey.30' }}
          _focus={{ outline: 'none' }}
          sx={{ caretColor: 'var(--emochan-colors-blue-50)' }}
          name={name}
          defaultValue={defaultValue}
          onChange={(e) => {
            onChange?.(e);
            setValue(e.target.value);
          }}
          onFocus={(e) => {
            setEditing(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            onBlur?.(e);

            if (resetAfterEnter) return;

            if (e.currentTarget.value.trim() === defaultValue) {
              return;
            }

            handleEnter?.(e.currentTarget.value.trim())
              .catch((err) => {
                console.debug(err);
              })
              .finally(() => {
                setEditing(false);
              });
          }}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing || e.key !== 'Enter' || e.shiftKey) {
              return;
            }

            e.preventDefault();

            const prev = e.currentTarget.value;
            const currentValue = e.currentTarget.value.trim();

            if (currentValue === defaultValue || currentValue === '') {
              return;
            }

            handleEnter?.(e.currentTarget.value.trim())
              .catch((err) => {
                console.debug(err);
                if (!resetAfterEnter) return;

                // fail のときは値を戻す
                setValue(prev);
                if (ref?.current) {
                  ref.current.value = prev;
                }
              });

            if (!resetAfterEnter) return;
            // fail のときは値を戻すので、先に value を reset しちゃう
            setValue('');
            if (ref?.current) {
              ref.current.value = '';
            }
          }}
          {...rest}
        />
      </Paragraph>
    </Box>
  );
}
