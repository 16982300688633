// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const ShareAppleIcon = createIcon({
  displayName: 'ShareAppleIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11.8515 2.01102L11.91 2.004L12 2L12.0752 2.00279L12.2007 2.02024L12.3121 2.04974L12.4232 2.09367L12.5207 2.146L12.6254 2.21969L12.7071 2.29289L15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711C15.3166 7.09763 14.6834 7.09763 14.2929 6.70711L13 5.414V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V5.414L9.70711 6.70711C9.31658 7.09763 8.68342 7.09763 8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289L11.2929 2.29289C11.3283 2.2575 11.3657 2.22531 11.4047 2.19633L11.5159 2.12467L11.6287 2.07123L11.734 2.03585L11.8515 2.01102Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M3.43597 19.816C3 18.9603 3 17.8402 3 15.6V13C3 12.4477 3.44772 12 4 12C4.55228 12 5 12.4477 5 13V15.6C5 15.6721 5.00001 15.7425 5.00003 15.8115C5.00037 16.8455 5.00445 17.5331 5.04785 18.0643C5.05063 18.0983 5.05352 18.1309 5.05653 18.1623C5.10157 18.6324 5.17075 18.8153 5.21799 18.908C5.40973 19.2843 5.71569 19.5903 6.09202 19.782C6.19091 19.8324 6.39235 19.9078 6.93567 19.9521C7.50235 19.9984 8.24689 20 9.4 20H14.6C15.7531 20 16.4977 19.9984 17.0643 19.9521C17.6077 19.9078 17.8091 19.8324 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C18.8324 18.8091 18.9078 18.6077 18.9521 18.0643C18.9984 17.4977 19 16.7531 19 15.6V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V15.6C21 17.8402 21 18.9603 20.564 19.816C20.1805 20.5686 19.5686 21.1805 18.816 21.564C17.9603 22 16.8402 22 14.6 22H9.4C7.15979 22 6.03969 22 5.18404 21.564C4.43139 21.1805 3.81947 20.5686 3.43597 19.816Z"
      />
    ),
  ],
});
