// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const MemberMultipleIcon = createIcon({
  displayName: 'MemberMultipleIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7ZM9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M21 7C21 9.20914 19.2091 11 17 11C14.7909 11 13 9.20914 13 7C13 4.79086 14.7909 3 17 3C19.2091 3 21 4.79086 21 7ZM19 7C19 8.10457 18.1046 9 17 9C15.8954 9 15 8.10457 15 7C15 5.89543 15.8954 5 17 5C18.1046 5 19 5.89543 19 7Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M1 19C1 15.6863 3.68629 13 7 13C9.08697 13 10.9251 14.0655 12 15.6822C13.0749 14.0655 14.913 13 17 13C20.3137 13 23 15.6863 23 19V20C23 20.5523 22.5523 21 22 21C21.4477 21 21 20.5523 21 20V19C21 16.7909 19.2091 15 17 15C14.7909 15 13 16.7909 13 19V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V19C11 16.7909 9.20914 15 7 15C4.79086 15 3 16.7909 3 19V20C3 20.5523 2.55228 21 2 21C1.44772 21 1 20.5523 1 20V19Z"
      />
    ),
  ],
});
