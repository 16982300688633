import { Box, Flex, PositionProps } from '@chakra-ui/react';
import { forwardRef, HTMLAttributes, useEffect, useCallback, useMemo, useState, useId } from 'react';
import { WorkspaceIcon } from '@emochan-cabinet/icons';

export const AvatarSize = {
  xs: 'xs',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xl: 'xl',
} as const;
export type AvatarSize = typeof AvatarSize[keyof typeof AvatarSize];

export const AvatarSizePixel = {
  xs: 24,
  small: 32,
  medium: 40,
  large: 80,
  xl: 120,
} as const;
export type AvatarSizePixel = typeof AvatarSizePixel[keyof typeof AvatarSizePixel];

export const AvatarKind = {
  user: 'user',
  workspace: 'workspace',
} as const;
export type AvatarKind = typeof AvatarKind[keyof typeof AvatarKind];

export interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  imageURL?: string;
  size?: AvatarSize;
  kind?: AvatarKind;
}

function pickUpInitialLetter(str: string) {
  if (typeof str !== 'string' || !str) return '';
  // 💪🏾 のような1文字目だと文字化けするので、emoji 対応
  // 𠮷野家で𩸽でも同じく
  return Array.from(str)[0].toUpperCase();
}

function getSize(size?: AvatarSize) {
  switch (size) {
    case AvatarSize.xl: return 'var(--emochan-sizes-120)';
    case AvatarSize.large: return 'var(--emochan-sizes-80)';
    case AvatarSize.small: return 'var(--emochan-sizes-32)';
    case AvatarSize.xs: return 'var(--emochan-sizes-24)';
    default: return 'var(--emochan-sizes-40)';
  }
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({
  name,
  imageURL = '',
  size = AvatarSize.medium,
  kind = AvatarKind.user,
}, ref) => {
  const [noImage, setNoImage] = useState(true);
  const [failed, setFailed] = useState(false);
  const full = useMemo(() => AvatarSizePixel[size], [size]);

  // 画像を読み込み後、解除される場合の fallback 戻し。 例: avatar 変更プレビュー
  useEffect(() => {
    if (imageURL === '') {
      setNoImage(true);
    }
  }, [imageURL]);

  const geometry = useCallback((ops?: { fill: boolean }) => {
    const half = full * 0.5;
    const quarter = full * 0.25;

    const attr = ops?.fill
      ? { fill: 'var(--emochan-colors-grey-0)' }
      : {
        strokeWidth: 2,
        stroke: 'var(--emochan-colors-transparent-grey-10)',
        fill: ops?.fill ? 'var(--emochan-colors-grey-0)' : 'none',
      };

    if (kind === AvatarKind.user) {
      return (
        <circle
          cx={half}
          cy={half}
          r={half}
          {...attr}
        />
      );
    }

    return (
      <rect
        width={full}
        height={full}
        rx={quarter}
        ry={quarter}
        cy={half}
        x="0"
        y="0"
        {...attr}
      />
    );
  }, [kind, full]);

  const initialFallback = useMemo(() => {
    if (kind === AvatarKind.workspace || !noImage) return null;

    return (
      <Box
        as="text"
        sx={{
          fontSize: '50%',
          fill: 'var(--emochan-colors-grey.60)',
          userSelect: 'none',
        }}
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {pickUpInitialLetter(name)}
      </Box>
    );
  }, [kind, name, noImage]);

  const iconFallback = useMemo(() => {
    if (!noImage || kind === AvatarKind.user) return null;

    return (
      <Box
        zIndex="normal"
        fontSize="66.6667%"
        color="grey.40"
        lineHeight={0}
      >
        <WorkspaceIcon size={size === 'xs' ? 'small' : 'medium'} fontSize="inherit" />
      </Box>
    );
  }, [kind, noImage, size]);

  const maskName = useId();

  const imageElement = useMemo(() => {
    if (imageURL === '') return null;
    if (failed) return null;

    return (
      <image
        x="0"
        y="0"
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid slice"
        xlinkHref={imageURL}
        // eslint-disable-next-line react/no-unknown-property
        onLoad={() => setNoImage(false)}
        // eslint-disable-next-line react/no-unknown-property
        onError={() => {
          setNoImage(true);
          setFailed(true);
        }}
      />
    );
  }, [imageURL, failed]);

  const abs = {
    position: 'absolute',
    top: 0,
    left: 0,
  } as PositionProps;

  return (
    <Flex
      ref={ref}
      position="relative"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
      width={getSize(size)}
      height={getSize(size)}
      fontSize={getSize(size)}
      fontWeight="bold"
      verticalAlign="top"
      overflow="hidden"
      sx={{ aspectRatio: '1 / 1' }}
    >
      <Box as="svg" {...abs} viewBox={`0 0 ${full} ${full}`}>
        <mask id={maskName}>{geometry({ fill: true })}</mask>
        <Box as="g" mask={`url(#${maskName})`} {...abs}>
          <rect x="0" y="0" width={full} height={full} fill="var(--emochan-colors-grey-10)" />
          {imageElement}
          {geometry()}
          {initialFallback}
        </Box>
      </Box>
      {iconFallback}
    </Flex>
  );
});

Avatar.displayName = 'Avatar';
