// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const EnterIcon = createIcon({
  displayName: 'EnterIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M13 21L16.2413 21C17.0462 21 17.7107 21 18.2518 20.9558C18.8139 20.9099 19.3306 20.8113 19.816 20.564C20.5686 20.1805 21.1805 19.5686 21.564 18.816C21.8113 18.3306 21.9099 17.8139 21.9558 17.2518C22 16.7106 22 16.0463 22 15.2413V8.7587C22 7.95374 22 7.28936 21.9558 6.74817C21.9099 6.18608 21.8113 5.66937 21.564 5.18404C21.1805 4.43139 20.5686 3.81947 19.816 3.43597C19.3306 3.18868 18.8139 3.09012 18.2518 3.0442C17.7106 2.99998 17.0462 2.99999 16.2412 3H13C12.4477 3 12 3.44771 12 4C12 4.55228 12.4477 5 13 5L16.2 5C17.0566 5 17.6389 5.00078 18.089 5.03755C18.5274 5.07337 18.7516 5.1383 18.908 5.21799C19.2843 5.40973 19.5903 5.71569 19.782 6.09202C19.8617 6.24842 19.9266 6.47262 19.9624 6.91104C19.9992 7.36113 20 7.94342 20 8.8V15.2C20 16.0566 19.9992 16.6389 19.9624 17.089C19.9266 17.5274 19.8617 17.7516 19.782 17.908C19.5903 18.2843 19.2843 18.5903 18.908 18.782C18.7516 18.8617 18.5274 18.9266 18.089 18.9624C17.6389 18.9992 17.0566 19 16.2 19H13C12.4477 19 12 19.4477 12 20C12 20.5523 12.4477 21 13 21Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M10.3 7.69997C9.91 8.08997 9.91 8.70997 10.3 9.09997L12.2 11H3C2.45 11 2 11.45 2 12C2 12.55 2.45 13 3 13H12.2L10.3 14.9C9.91 15.29 9.91 15.91 10.3 16.3C10.69 16.69 11.31 16.69 11.7 16.3L15.29 12.71C15.68 12.32 15.68 11.69 15.29 11.3L11.7 7.69997C11.31 7.30997 10.69 7.30997 10.3 7.69997Z"
      />
    ),
  ],
});
