// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const PlusCircleIcon = createIcon({
  displayName: 'PlusCircleIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11L16 11C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13L13 13V16Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      />
    ),
  ],
});
