export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '';

export const hasAnalyticsId = GA_TRACKING_ID !== '';

interface Window {
  gtag(eventName: string, actionName: string, something: unknown): void;
}

declare const window: Window;

interface EventParams {
  action: string;
  options?: {
    // eslint-disable-next-line camelcase
    event_category?: string;
    // eslint-disable-next-line camelcase
    event_label?: string;
    value?: any;
    error?: unknown;
  };
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const sendPageview = (url: string): void => {
  if (hasAnalyticsId && window.gtag) {
    window.gtag('config', GA_TRACKING_ID, { page_path: url });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const sendEvent = ({ action, options }: EventParams): void => {
  if (hasAnalyticsId && window.gtag) {
    window.gtag('event', action, options);
  }
};
