// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const CabinetFilledIcon = createIcon({
  displayName: 'CabinetFilledIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M3.21799 4.09202C3 4.51984 3 5.0799 3 6.2V7.8C3 8.9201 3 9.48016 3.21799 9.90798C3.40973 10.2843 3.71569 10.5903 4.09202 10.782C4.51984 11 5.0799 11 6.2 11H7.8C8.9201 11 9.48016 11 9.90798 10.782C10.2843 10.5903 10.5903 10.2843 10.782 9.90798C11 9.48016 11 8.9201 11 7.8V6.2C11 5.0799 11 4.51984 10.782 4.09202C10.5903 3.71569 10.2843 3.40973 9.90798 3.21799C9.48016 3 8.9201 3 7.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M3.21799 14.092C3 14.5198 3 15.0799 3 16.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21H7.8C8.9201 21 9.48016 21 9.90798 20.782C10.2843 20.5903 10.5903 20.2843 10.782 19.908C11 19.4802 11 18.9201 11 17.8V16.2C11 15.0799 11 14.5198 10.782 14.092C10.5903 13.7157 10.2843 13.4097 9.90798 13.218C9.48016 13 8.9201 13 7.8 13H6.2C5.0799 13 4.51984 13 4.09202 13.218C3.71569 13.4097 3.40973 13.7157 3.21799 14.092Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M13 6.2C13 5.0799 13 4.51984 13.218 4.09202C13.4097 3.71569 13.7157 3.40973 14.092 3.21799C14.5198 3 15.0799 3 16.2 3H17.8C18.9201 3 19.4802 3 19.908 3.21799C20.2843 3.40973 20.5903 3.71569 20.782 4.09202C21 4.51984 21 5.0799 21 6.2V7.8C21 8.9201 21 9.48016 20.782 9.90798C20.5903 10.2843 20.2843 10.5903 19.908 10.782C19.4802 11 18.9201 11 17.8 11H16.2C15.0799 11 14.5198 11 14.092 10.782C13.7157 10.5903 13.4097 10.2843 13.218 9.90798C13 9.48016 13 8.9201 13 7.8V6.2Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M13.218 14.092C13 14.5198 13 15.0799 13 16.2V17.8C13 18.9201 13 19.4802 13.218 19.908C13.4097 20.2843 13.7157 20.5903 14.092 20.782C14.5198 21 15.0799 21 16.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V16.2C21 15.0799 21 14.5198 20.782 14.092C20.5903 13.7157 20.2843 13.4097 19.908 13.218C19.4802 13 18.9201 13 17.8 13H16.2C15.0799 13 14.5198 13 14.092 13.218C13.7157 13.4097 13.4097 13.7157 13.218 14.092Z"
      />
    ),
  ],
});
