// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const DownloadIcon = createIcon({
  displayName: 'DownloadIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11.8515 15.989L11.91 15.996L12 16L12.0752 15.9972L12.2007 15.9798L12.3121 15.9503L12.4232 15.9063L12.5207 15.854L12.6254 15.7803L12.7071 15.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929C15.3166 10.9024 14.6834 10.9024 14.2929 11.2929L13 12.586V3C13 2.44771 12.5523 2 12 2C11.4477 2 11 2.44771 11 3V12.586L9.70711 11.2929C9.31658 10.9024 8.68342 10.9024 8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L11.2929 15.7071C11.3283 15.7425 11.3657 15.7747 11.4047 15.8037L11.5159 15.8753L11.6287 15.9288L11.734 15.9642L11.8515 15.989Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M3.43597 19.816C3 18.9603 3 17.8402 3 15.6V13C3 12.4477 3.44772 12 4 12C4.55228 12 5 12.4477 5 13V15.6C5 15.6721 5.00001 15.7425 5.00003 15.8115C5.00037 16.8455 5.00445 17.5331 5.04785 18.0643C5.05063 18.0983 5.05352 18.1309 5.05653 18.1623C5.10157 18.6324 5.17075 18.8153 5.21799 18.908C5.40973 19.2843 5.71569 19.5903 6.09202 19.782C6.19091 19.8324 6.39235 19.9078 6.93567 19.9521C7.50235 19.9984 8.24689 20 9.4 20H14.6C15.7531 20 16.4977 19.9984 17.0643 19.9521C17.6077 19.9078 17.8091 19.8324 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C18.8324 18.8091 18.9078 18.6077 18.9521 18.0643C18.9984 17.4977 19 16.7531 19 15.6V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V15.6C21 17.8402 21 18.9603 20.564 19.816C20.1805 20.5686 19.5686 21.1805 18.816 21.564C17.9603 22 16.8402 22 14.6 22H9.4C7.15979 22 6.03969 22 5.18404 21.564C4.43139 21.1805 3.81947 20.5686 3.43597 19.816Z"
      />
    ),
  ],
});
