import { FC } from 'react';
import {
  Box,
  VisuallyHidden,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const Spinner: FC = () => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `0.5s linear 0s infinite normal none running ${spin}`;

  const spinStyle = {
    '--Spinner-size': '2.25rem',
    '--Spinner-border-size': '0.25rem',
  };

  return (
    <Box
      display="inline-block"
      w="var(--Spinner-size)"
      h="var(--Spinner-size)"
      m="18"
      color="blue.50"
      borderRadius="full"
      borderTop="var(--Spinner-border-size) solid currentcolor"
      borderRight="var(--Spinner-border-size) solid currentcolor"
      borderBottomStyle="solid"
      borderLeftStyle="solid"
      borderBottomWidth="var(--Spinner-border-size)"
      borderLeftWidth="var(--Spinner-border-size)"
      borderBottomColor="grey.10"
      borderLeftColor="grey.10"
      borderRightColor="grey.10"
      animation={animation}
      sx={spinStyle}
    >
      <VisuallyHidden>Loading</VisuallyHidden>
    </Box>
  );
};
