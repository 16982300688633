import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  ChangeEvent,
  ReactNode,
  useEffect,
  useId,
} from 'react';
import { Box } from '@chakra-ui/react';

type TextLength = {
  length: number
  maxLength: number
}

export type FormControlContext = {
  // HTML ID
  id?: string

  // 状態管理系
  // 必須
  isRequired?: boolean
  // エラー。赤くなる
  isInvalid?: boolean
  // OKグリーン
  isValid?: boolean
  isDisabled?: boolean

  // テキスト入力系ヘルパー
  // 文字数 / MAX
  showLength?: boolean;
  textLength?: TextLength
  setInvalid(invalid: boolean): void
  updateTextLength(options: TextLength): void
}

const FormControlContext = createContext<FormControlContext>({} as FormControlContext);

FormControlContext.displayName = 'FormControlContext';

interface FormControlProps extends Omit<FormControlContext, 'setInvalid' | 'updateTextLength'> {
  children: ReactNode
}

export const FormControl: FC<FormControlProps> = ({ id, children, ...props }) => {
  const [isInvalid, setIsInvalid] = useState(props.isInvalid);
  const [textLength, setTextLength] = useState<TextLength>({ length: 0, maxLength: 0 });

  useEffect(() => {
    setIsInvalid(props.isInvalid);
  }, [props.isInvalid]);

  const setInvalid = useCallback((invalid: boolean) => {
    setIsInvalid(invalid);
  }, []);

  const updateTextLength = useCallback((options: TextLength) => {
    setTextLength(options);
  }, []);

  const controlId = useId();

  const contextValue = {
    ...props,
    id: id ?? controlId,
    isInvalid,
    setInvalid,
    textLength,
    updateTextLength,
  };

  return (
    <FormControlContext.Provider value={contextValue}>
      <Box role="group" w="100%" position="relative">
        {children}
      </Box>
    </FormControlContext.Provider>
  );
};

FormControl.displayName = 'FormControl';

export function useFormControl(): FormControlContext {
  return useContext(FormControlContext);
}

interface UseFormTextInput<T extends HTMLInputElement|HTMLTextAreaElement> {
  feedbackId: string
  value: string
  onInput: (e: ChangeEvent<T>) => void
}

// 文字数カウント用。必須チェックや valid email 的なものは別で
export function useFormTextInput<T extends HTMLInputElement|HTMLTextAreaElement>(
  text: string,
  maxLength = 0,
): UseFormTextInput<T> {
  const { id, updateTextLength, setInvalid, isRequired } = useFormControl();
  const [value, update] = useState(text);

  useEffect(() => {
    if (maxLength) {
      updateTextLength({
        length: text.length,
        maxLength,
      });
    }
  }, [text, maxLength, updateTextLength]);

  const onInput = useCallback((e: ChangeEvent<T>) => {
    const newVal = e.currentTarget.value;
    const newCount = Array.from(newVal).length;

    update(newVal);

    if (maxLength) {
      updateTextLength({ length: newCount, maxLength });
      setInvalid(newCount > maxLength);
    }

    if (isRequired && newCount === 0) {
      setInvalid(true);
    }
  }, [updateTextLength, setInvalid, maxLength, isRequired]);

  return {
    feedbackId: `${id}-feedback`,
    value,
    onInput,
  };
}
