// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const AiFilledIcon = createIcon({
  displayName: 'AiFilledIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M5.78003 3.0536C5.62095 3.13603 5.51008 3.41715 5.28833 3.97938L5.04001 4.60902C4.99509 4.72292 4.97262 4.77988 4.93823 4.8279C4.90774 4.87047 4.87047 4.90774 4.8279 4.93823C4.77988 4.97262 4.72293 4.99508 4.60902 5.04001L3.97938 5.28833C3.41715 5.51008 3.13603 5.62095 3.0536 5.78003C2.98213 5.91797 2.98213 6.08203 3.0536 6.21997C3.13603 6.37905 3.41715 6.48992 3.97938 6.71167L4.60902 6.95999C4.72293 7.00492 4.77988 7.02738 4.8279 7.06177C4.87047 7.09226 4.90774 7.12953 4.93823 7.1721C4.97262 7.22012 4.99508 7.27707 5.04001 7.39098L5.28833 8.02062C5.51008 8.58285 5.62095 8.86397 5.78003 8.9464C5.91797 9.01787 6.08203 9.01787 6.21997 8.9464C6.37905 8.86397 6.48992 8.58285 6.71167 8.02062L6.95999 7.39098C7.00492 7.27707 7.02738 7.22012 7.06177 7.1721C7.09226 7.12953 7.12953 7.09226 7.1721 7.06177C7.22012 7.02738 7.27707 7.00492 7.39098 6.95999L8.02062 6.71167C8.58285 6.48992 8.86397 6.37905 8.9464 6.21997C9.01787 6.08203 9.01787 5.91797 8.9464 5.78003C8.86397 5.62095 8.58285 5.51008 8.02062 5.28833L7.39098 5.04001C7.27707 4.99508 7.22012 4.97262 7.1721 4.93823C7.12953 4.90774 7.09226 4.87047 7.06177 4.8279C7.02738 4.77988 7.00492 4.72293 6.95999 4.60902L6.71167 3.97938C6.48992 3.41715 6.37905 3.13603 6.21997 3.0536C6.08203 2.98213 5.91797 2.98213 5.78003 3.0536Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M12.4134 5.14294C11.9892 5.36275 11.6935 6.11239 11.1022 7.61168L10.44 9.29072C10.3202 9.59447 10.2603 9.74634 10.1686 9.87441C10.0873 9.98792 9.98792 10.0873 9.87441 10.1686C9.74634 10.2603 9.59447 10.3202 9.29072 10.44L7.61168 11.1022C6.11239 11.6935 5.36275 11.9892 5.14294 12.4134C4.95235 12.7813 4.95235 13.2187 5.14294 13.5866C5.36275 14.0108 6.11239 14.3065 7.61168 14.8978L9.29072 15.56C9.59447 15.6798 9.74634 15.7397 9.87441 15.8314C9.98792 15.9127 10.0873 16.0121 10.1686 16.1256C10.2603 16.2537 10.3202 16.4055 10.44 16.7093L11.1022 18.3883C11.6935 19.8876 11.9892 20.6372 12.4134 20.8571C12.7813 21.0476 13.2187 21.0476 13.5866 20.8571C14.0108 20.6372 14.3065 19.8876 14.8978 18.3883L15.56 16.7093C15.6798 16.4055 15.7397 16.2537 15.8314 16.1256C15.9127 16.0121 16.0121 15.9127 16.1256 15.8314C16.2537 15.7397 16.4055 15.6798 16.7093 15.56L18.3883 14.8978C19.8876 14.3065 20.6372 14.0108 20.8571 13.5866C21.0476 13.2187 21.0476 12.7813 20.8571 12.4134C20.6372 11.9892 19.8876 11.6935 18.3883 11.1022L16.7093 10.44C16.4055 10.3202 16.2537 10.2603 16.1256 10.1686C16.0121 10.0873 15.9127 9.98792 15.8314 9.87441C15.7397 9.74634 15.6798 9.59447 15.56 9.29072L14.8978 7.61168C14.3065 6.11239 14.0108 5.36275 13.5866 5.14294C13.2187 4.95235 12.7813 4.95235 12.4134 5.14294Z"
      />
    ),
  ],
});
