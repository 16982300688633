import { ReactElement, Children, forwardRef } from 'react';
import { Icon, IconProps } from './Icon';

type CreateIconOptions = {
  // @default "0 0 24 24"
  viewBox?: string
  path?: ReactElement | ReactElement[]
  // 1 path だけなら、 d 指定で OK
  d?: string
  displayName?: string
}

export function createIcon(options: CreateIconOptions) {
  const {
    viewBox = '0 0 24 24',
    d: pathDefinition,
    displayName,
  } = options;

  const path = Children.toArray(options.path)

  const Comp = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
    <Icon ref={ref} viewBox={viewBox} {...props}>
      {path.length ? path : <path fill="currentColor" fillRule="evenodd" d={pathDefinition} />}
    </Icon>
  ));

  if (displayName) {
    Comp.displayName = displayName;
  }

  return Comp;
}
