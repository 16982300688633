export function getUserMedia(audioConstraints?: MediaTrackConstraints)
  : Promise<MediaStream> | undefined {
  if (!navigator.mediaDevices) return;

  return navigator.mediaDevices.getUserMedia({
    audio: {
      channelCount: 1,
      echoCancellation: false,
      noiseSuppression: true,
      autoGainControl:false,
      ...audioConstraints,
    },
    video: false,
  });
}

export async function getMicPermissionStatus(): Promise<PermissionStatus | null> {
  // old Safari
  if (!navigator.permissions) {
    return null;
  }

  try {
    // Firefox 未実装
    // @ts-expect-error microphone
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' });

    if (permissionStatus && permissionStatus.state) {
      return permissionStatus;
    }
  } catch (e) {
    return null;
  }

  return null;
}

type EnumerateMic = MediaDeviceInfo & { isActive: boolean };

export async function getEnumerateMics(): Promise<EnumerateMic[]> {
  if (!navigator.permissions) {
    return [];
  }

  const stream = await getUserMedia();

  if (stream === undefined) return [];

  const devices = await navigator.mediaDevices.enumerateDevices();
  const audioInputDevices = devices
    .filter((device) => device.kind === 'audioinput')
    .map(((device) => ({
      ...device,
      isActive: device.deviceId === stream.getAudioTracks()[0].getSettings().deviceId,
    })));

  return audioInputDevices;
}

export function selectMicrophoneById(deviceId?: string): Promise<MediaStream> | undefined {
  return getUserMedia({ deviceId: deviceId ? { exact: deviceId } : undefined });
}
