// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const VolumeOffIcon = createIcon({
  displayName: 'VolumeOffIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M2.1071 3.49309C1.71658 3.88361 1.71658 4.51678 2.1071 4.9073L6.10244 8.90264C6.0038 8.92843 5.90368 8.94923 5.80243 8.9649C5.59211 8.99744 5.36362 9.00018 4.69602 9.00018L4.66006 8.99994C4.53479 8.99877 4.2804 8.99639 4.04723 9.07032C3.58232 9.21772 3.2181 9.58194 3.0707 10.0468C2.99678 10.28 2.99915 10.5344 3.00032 10.6597L3.00056 10.6956V13.3047L3.00032 13.3407C2.99915 13.466 2.99678 13.7203 3.0707 13.9535C3.2181 14.4184 3.58232 14.7826 4.04723 14.93C4.2804 15.004 4.53479 15.0016 4.66006 15.0004L4.69602 15.0002C5.36362 15.0002 5.59211 15.0029 5.80243 15.0355C6.27745 15.109 6.72785 15.2955 7.11571 15.5794C7.28745 15.7051 7.45094 15.8648 7.92301 16.3368L8.95409 17.3679C9.14525 17.5591 9.33315 17.7471 9.49785 17.885C9.64479 18.008 9.95423 18.2547 10.3829 18.2884C10.8615 18.3261 11.3293 18.1324 11.6412 17.7672C11.9204 17.4403 11.9648 17.047 11.9817 16.8561C12.0006 16.6422 12.0006 16.3764 12.0006 16.106V14.8008L15.3346 18.1348C15.2092 18.2015 15.081 18.265 14.95 18.3252C14.7 18.4418 14.5166 18.621 14.4 18.8627C14.2833 19.1044 14.2666 19.3502 14.35 19.6002C14.45 19.8668 14.6291 20.0585 14.8875 20.1752C15.1458 20.2919 15.4083 20.2919 15.675 20.1752C16.0654 20.0021 16.4369 19.8069 16.7894 19.5896L19.0777 21.8779C19.4682 22.2684 20.1014 22.2684 20.4919 21.8779C20.8824 21.4873 20.8824 20.8542 20.4919 20.4636L3.52132 3.49309C3.13079 3.10256 2.49763 3.10256 2.1071 3.49309ZM10.0006 12.8008L7.64098 10.4412C7.162 10.689 6.64454 10.8584 6.10825 10.9414C5.78101 10.992 5.44652 10.9991 5.00056 11.0001V13.0003C5.44652 13.0012 5.78101 13.0083 6.10825 13.059C6.89995 13.1815 7.65061 13.4924 8.29704 13.9656C8.60833 14.1935 8.88482 14.4701 9.28475 14.8701L10.0006 15.586V12.8008Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M14 11.146L16.2677 13.4137C16.4225 12.9609 16.5 12.4897 16.5 12.0002C16.5 11.3002 16.3416 10.6335 16.025 10.0002C15.7083 9.36685 15.2916 8.84185 14.775 8.42518C14.6083 8.32518 14.4375 8.32102 14.2625 8.41268C14.0875 8.50435 14 8.65018 14 8.85018V11.146Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M18.1666 15.3127L19.6251 16.7712C20.5417 15.3266 21 13.7279 21 11.9752C21 10.1752 20.5166 8.53768 19.55 7.06268C18.5833 5.58768 17.2916 4.49185 15.675 3.77518C15.4083 3.65852 15.1458 3.65852 14.8875 3.77518C14.6291 3.89185 14.45 4.08352 14.35 4.35018C14.2666 4.60018 14.2833 4.84602 14.4 5.08768C14.5166 5.32935 14.7 5.50852 14.95 5.62518C16.1833 6.19185 17.1666 7.04602 17.9 8.18768C18.6333 9.32935 19 10.5919 19 11.9752C19 13.1792 18.7222 14.2917 18.1666 15.3127Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M9.22162 6.36768L12.0006 9.14662V7.89431C12.0006 7.62393 12.0006 7.35817 11.9817 7.1442C11.9648 6.95331 11.9204 6.56006 11.6412 6.23311C11.3293 5.868 10.8615 5.67423 10.3829 5.71191C9.95423 5.74564 9.64479 5.99235 9.49785 6.11537C9.41122 6.18789 9.31818 6.27427 9.22162 6.36768Z"
      />
    ),
  ],
});
