import { useMemo } from 'react';
import { forwardRef } from '@chakra-ui/react';
import { Paragraph, ParagraphSizes } from '../../typography/Paragraph';
import { useFormControl } from '.';
import { Colors } from '../../../system/styles';

export const FormHelper = forwardRef<{ text: string }, 'div'>(({ text }, ref) => {
  const { id, isDisabled, isInvalid } = useFormControl();

  const color = useMemo(() => {
    if (isDisabled) return Colors.grey30;
    if (isInvalid) return Colors.red50;

    return Colors.grey90;
  }, [isDisabled, isInvalid]);

  const textProps = useMemo(() => {
    const defaultTextProps = {
      ref,
      size: ParagraphSizes.xs,
      color,
    };

    // エラーメッセのa11y
    // https://developers.google.com/web/fundamentals/accessibility/semantics-aria/hiding-and-updating-content?hl=ja
    if (isInvalid) {
      return {
        ...defaultTextProps,
        'aria-live': 'polite' as const,
        id: `${id}-feedback`,
      };
    }

    return defaultTextProps;
  }, [id, ref, isInvalid, color]);

  return <Paragraph mt="8" {...textProps}>{text}</Paragraph>;
});

FormHelper.displayName = 'FormHelper';
