import { useRef, useCallback, useEffect, useState } from 'react';
import { getMicPermissionStatus, getUserMedia } from './microphone';

export interface useMicrophoneReturnValue {
  getMic: () => Promise<MediaStream> | undefined,
  leaveMic: (stream?: MediaStream) => void,
  permissionState: PermissionState | undefined | null,
}

type MicPermissionState = PermissionState | undefined | null;

export function useMicrophone(): useMicrophoneReturnValue {
  const permissionStatusRef = useRef<PermissionStatus | undefined>(undefined);
  const [permissionState, updatePermissionState] = useState<MicPermissionState>(undefined);

  // granted なら許可モーダルは不要
  // denined ならヘルプ参照
  useEffect(() => {
    if (permissionStatusRef.current) return;

    getMicPermissionStatus().then((result) => {
      if (result == null) {
        console.debug('🎤 COULD NOT GET');
        updatePermissionState(null);
        return;
      }

      permissionStatusRef.current = result;
      console.debug(`🎤 GET: ${result.state}`);

      updatePermissionState(result.state);
    });
  }, [updatePermissionState]);

  useEffect(() => {
    const ps = permissionStatusRef.current;

    function handleEvent() {
      console.debug(`🎤 CHANGE: ${ps?.state}`)
      updatePermissionState(ps?.state);
    }

    ps?.addEventListener('change', handleEvent);

    return () => {
      ps?.removeEventListener('change', handleEvent);
    };
  }, [permissionState]);

  const getMic = useCallback(getUserMedia, []);

  const leaveMic = useCallback((stream?: MediaStream) => {
    if (!stream || !stream.getTracks) return;

    stream.getTracks().forEach((t) => {
      t.stop();
      stream.removeTrack(t);
    });
  }, []);

  return {
    permissionState,
    getMic,
    leaveMic,
  };
}
