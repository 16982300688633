import { useState, useEffect } from 'react';

const MAX_SMALL_WIDTH_PX = 360;
const MAX_MEDIUM_WIDTH_PX = 720;
const MAX_LARGE_WIDTH_PX = 960;
const MAX_XLARGE_WIDTH_PX = 1448;

// see also: https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
export function useBetterMediaQuery(mediaQueryString: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const listener = () => setMatches(!!mediaQueryList.matches);

    listener();

    mediaQueryList.addEventListener('change', listener);

    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }, [mediaQueryString]);

  return matches;
}

export function useMedia(): {
  isSmall: boolean,
  isMedium: boolean,
  isLarge: boolean,
  isXLarge: boolean,
  } {
  const isSmall = useBetterMediaQuery(`(max-width: ${MAX_SMALL_WIDTH_PX / 16}rem)`);
  const isMedium = useBetterMediaQuery(`(max-width: ${MAX_MEDIUM_WIDTH_PX / 16}rem)`);
  const isLarge = useBetterMediaQuery(`(min-width: ${MAX_LARGE_WIDTH_PX / 16}rem)`);
  const isXLarge = useBetterMediaQuery(`(min-width: ${MAX_XLARGE_WIDTH_PX / 16}rem)`);

  return { isSmall, isMedium, isLarge, isXLarge };
}
