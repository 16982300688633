import { Box, TextareaProps, Textarea } from '@chakra-ui/react';
import { Label } from '../../typography/Label';
import { Spinner } from '../../loading/Spinner';

export interface DialogueLargeTextBoxProps extends TextareaProps {
  text: string,
  isEditable?: boolean,
  isLoading?: boolean,
  placeholder?: string,
}

function EditableDialogueLargeTextBox({
  text,
  ...rest
}: DialogueLargeTextBoxProps) {
  return (
    <Label as="div" size="large">
      <Textarea
        rows={1}
        w="100%"
        h="100%"
        resize="none"
        maxLength={256}
        m={0}
        border="none"
        color="grey.100"
        borderRadius="12"
        px="16"
        py="24"
        textAlign="center"
        bg="grey.5"
        _placeholder={{ color: 'grey.30' }}
        _focus={{ outline: 'none', bg: 'grey.0', boxShadow: 'inset 0 0 0 1px var(--emochan-colors-blue-50)' }}
        sx={{ caretColor: 'var(--emochan-colors-blue-50)' }}
        value={text}
        {...rest}
      />
    </Label>
  );
}

export function DialogueLargeTextBox({
  text,
  isEditable = false,
  isLoading = false,
  ...rest
}: DialogueLargeTextBoxProps): JSX.Element {
  if (isEditable) {
    return <EditableDialogueLargeTextBox text={text} {...rest} />;
  }

  return (
    <Box
      px="16"
      py="24"
      borderRadius="12"
      bg="grey.5"
      textAlign="center"
    >
      { isLoading && <Spinner /> }
      { !isLoading && <Label as="h3" size="large">{text}</Label> }
    </Box>
  );
}

DialogueLargeTextBox.displayName = 'DialogueLargeTextBox';
