// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const CheckCircleIcon = createIcon({
  displayName: 'CheckCircleIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M17.2372 10.1757C17.6103 9.76861 17.5828 9.13604 17.1757 8.76285C16.7686 8.38966 16.136 8.41716 15.7628 8.82428L10.9686 14.0544L8.20711 11.2929C7.81658 10.9024 7.18342 10.9024 6.79289 11.2929C6.40237 11.6834 6.40237 12.3166 6.79289 12.7071L10.2929 16.2071C10.4858 16.4 10.749 16.5057 11.0217 16.4998C11.2944 16.4938 11.5528 16.3768 11.7372 16.1757L17.2372 10.1757Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      />
    ),
  ],
});
