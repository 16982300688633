import { createContext, useMemo, useContext, useState, FC, ReactNode, useCallback, useId, useRef } from 'react';
import { Flex, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import { CrossIcon } from '@emochan-cabinet/icons';
import { ClientOnlyPortal } from '../../../utils/ClientOnlyPortal';
import { Paragraph } from '../../typography/Paragraph';
import { Button } from '../../buttons/Button';
import { IconButton } from '../../buttons/Button/IconButton';
import { BGColorFoundations, FGColorFoundations } from '../../../system/styles';

const SNACKBAR_DURATION = 5000;

export const SnackbarType = {
  /** ライト背景用 */
  LIGHT: 'primary',
  /** ダーク背景用 */
  DARK: 'secondary',
  /** 情報メッセージ */
  ACCENT: 'accent',
  /** サクセスメッセージ */
  POSITIVE: 'positive',
  /** エラーメッセージ */
  NEGATIVE: 'negative',
  /** 警告メッセージ */
  WARNING: 'warning',
} as const;

export type SnackbarType = typeof SnackbarType[keyof typeof SnackbarType];

export const SnackbarStatus = {
  active: 'active',
  completed: 'completed',
  done: 'done',
} as const;

export type SnackbarStatus = typeof SnackbarStatus[keyof typeof SnackbarStatus];

export interface SnackbarState {
  status: SnackbarStatus,
  message: string,
  isClosable?: boolean,
  type: SnackbarType,
  buttonLabel?: string,
  actionIcon?: ReactNode,
  onClick?: () => void,
}

type Actionable = { onAction: () => void };
type ShowSnackBar = Omit<SnackbarState, 'status'> & { shouldAutoDismiss?: boolean };

const show = keyframes`
  0% {
    transform: translate(-50%, 100%);
  }
  40% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

const complete = keyframes`
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
`;

export const SnackbarMessage: FC<SnackbarState & Actionable> = ({
  status,
  type,
  message,
  isClosable = false,
  buttonLabel,
  onAction,
  onClick,
  actionIcon = <CrossIcon />,
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const fg = FGColorFoundations[type];
  const bg = BGColorFoundations[type];
  const snackbarLabeledBy = useId();

  const actionLabelButton = useMemo(() => {
    if (!buttonLabel) return null;

    const c = type === 'secondary' ? 'primary' : 'accent';

    return (
      <Button
        size="small"
        buttonStyle="ghost"
        label={buttonLabel}
        color={c}
        onClick={() => {
          onClick?.();
          onAction();
        }}
      />
    );
  }, [buttonLabel, type, onClick, onAction]);

  const actionButton = useMemo(() => {
    if (!isClosable) return null;

    const c = type === 'secondary' ? 'primary' : 'accent';

    return (
      <IconButton
        size="small"
        icon={actionIcon}
        label={buttonLabel || 'close'}
        color={c}
        buttonStyle="ghost"
        onClick={() => {
          onClick?.();
          onAction();
        }}
      />
    );
  }, [isClosable, type, onAction, buttonLabel, actionIcon, onClick]);

  if (status === SnackbarStatus.done) return null;

  const isActive = status === SnackbarStatus.active;
  const isCompleted = status === SnackbarStatus.completed;

  const showAnimation = `${show} 1s forwards`;

  const completeAnimation = prefersReducedMotion
    ? undefined
    : `${complete} .75s forwards`;

  return (
    <Flex
      alignItems="center"
      color={fg}
      bg={bg}
      borderRadius="12"
      boxShadow="high.below"
      role="alert"
      aria-labelledby={snackbarLabeledBy}
      position="fixed"
      bottom="calc(env(safe-area-inset-bottom) + 1.5rem)"
      left="50%"
      zIndex="snackbar"
      w="calc(100% - var(--emochan-sizes-32))"
      padding="2px 6px"
      // eslint-disable-next-line no-nested-ternary
      animation={isActive ? showAnimation : isCompleted ? completeAnimation : undefined}
      transform={isCompleted ? 'translate(-50%, 0)' : 'translate(-50%, 100%)'}
      opacity={isCompleted ? 1 : 0}
      boxSizing="content-box"
      maxW="container.small"
    >
      <Paragraph id={snackbarLabeledBy} as="span" flexGrow={1} size="small" py="12" px="8">
        {message}
      </Paragraph>
      {actionLabelButton}
      {actionButton}
    </Flex>
  );
};

interface SnackbarContext {
  status: SnackbarStatus
  showSnackbar: (options: ShowSnackBar) => void
}

const SnackbarContext = createContext<SnackbarContext>({} as SnackbarContext);

SnackbarContext.displayName = 'SnackbarContext';

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, update] = useState<SnackbarState>({
    status: 'done',
    message: '',
    isClosable: false,
    type: SnackbarType.POSITIVE,
    buttonLabel: undefined,
    onClick: undefined,
  });

  const animationTimer = useRef<NodeJS.Timeout | number | null>(null);

  const onAction = useCallback(() => {
    if (animationTimer.current) {
      clearTimeout(animationTimer.current);
    }

    update({ ...state, status: 'completed' });

    animationTimer.current = setTimeout(
      () => update({ ...state, status: 'done', message: '' }),
      SNACKBAR_DURATION * 0.5,
    );
  }, [state]);

  const showSnackbar = (options: ShowSnackBar) => {
    update({ ...options, status: 'active' });

    if (options.isClosable || options.buttonLabel !== undefined) {
      if (!options.shouldAutoDismiss) {
        return;
      }
    }

    if (animationTimer.current) {
      clearTimeout(animationTimer.current);
    }

    animationTimer.current = setTimeout(() => update({ ...options, status: 'completed' }), SNACKBAR_DURATION * 0.5);
    setTimeout(() => update({ ...options, status: 'done', message: '' }), SNACKBAR_DURATION);
  };

  return (
    <>
      <SnackbarContext.Provider value={{
        status: state.status,
        showSnackbar,
      }}
      >
        {children}
      </SnackbarContext.Provider>
      <ClientOnlyPortal selector="#portal">
        <SnackbarMessage {...state} onAction={onAction} />
      </ClientOnlyPortal>
    </>
  );
};

export function useSnackbar(): SnackbarContext {
  const c = useContext(SnackbarContext);
  if (c === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return c;
}
