import { Box, ChakraProps } from '@chakra-ui/react';
import { cloneElement, forwardRef, ReactNode } from 'react';
import { ButtonSx, ButtonProps } from './ButtonBase';

export type AvatarButtonProps = ButtonProps & {
  avatar: ReactNode
  __css?: ChakraProps['__css']
}

// アイコンを1つだけ格納した丸いボタン
export const AvatarButton = forwardRef<HTMLButtonElement, AvatarButtonProps>(({
  avatar,
  __css,
  ...props
}, ref) => {
  const avatarProps = { size: 'small' };
  const avatarComponent = cloneElement(avatar, avatarProps);

  return (
    <Box
      position="relative"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      whiteSpace="nowrap"
      userSelect="none"
      textDecoration="none"
      transition="opacity .5s ease-out"
      border="none"
      sx={ButtonSx}
      ref={ref}
      w="36"
      h="36"
      p="2px"
      borderRadius="half"
      overflow="hidden"
      _hover={{ _after: { bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04))' } }}
      _focus={{ _after: { bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12))' } }}
      __css={__css}
      {...props}
    >
      {avatarComponent}
    </Box>
  );
});
