import { SystemStyleObject } from '@chakra-ui/react';

// input & textarea style alias
export const TextFieldStyle = {
  w: '100%',
  pt: 12,
  pb: 12,
  pl: 16,
  pr: 16,
  margin: 0,
  fontSize: 'paragraph.medium',
  lineHeight: 'paragraph.medium',
  bg: 'grey.0',
  border: 'none',
  borderRadius: 12,
  boxShadow: 'inset 0 0 0 1px var(--emochan-colors-grey-30)',
  color: 'grey.100',
  transition: 'background-color 80ms, box-shadow 80ms',
  overscrollBehavior: 'contain',
  _placeholder: { color: 'grey.60' },
  _hover: { bg: 'transparent.grey.4' },
  _focus: {
    bg: 'grey.0',
    boxShadow: 'inset 0 0 0 2px var(--emochan-colors-blue-50)',
    outline: 'none',
  },
};

export const TextFieldSx: SystemStyleObject = {
  caretColor: 'var(--emochan-colors-blue-50)',
  WebkitAppearance: 'none',
  '&[aria-invalid="true"]': {
    bg: 'red.5',
    boxShadow: 'inset 0 0 0 2px var(--emochan-colors-red-50)',
    caretColor: 'var(--emochan-colors-red-50)',
  },
  '&[aria-invalid="false"]': {
    bg: 'green.5',
    boxShadow: 'inset 0 0 0 2px var(--emochan-colors-green-50)',
    caretColor: 'var(--emochan-colors-green-50)',
  },
  '&[disabled]': {
    boxShadow: 'inset 0 0 0 1px var(--emochan-colors-grey-5)',
    cursor: 'not-allowed',
  },
  '&[disabled]::placeholder': { color: 'grey.30' },
  '.--hasLeft > &': { paddingLeft: '48' },
  '.-hasRight > &': { paddingRight: '48' },
};
