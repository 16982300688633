import { useMemo, memo, ReactNode } from 'react';
import { Box, Flex, forwardRef } from '@chakra-ui/react';
import { Heading } from '../../typography/Heading';
import { Paragraph } from '../../typography/Paragraph';

const BLOCK_NAME = 'List.Header';

export interface ListHeaderProps {
  title: string | ReactNode
  subtitle?: string | ReactNode
  description?: string | ReactNode
  children?: ReactNode
}

export const ListHeader = memo(forwardRef<ListHeaderProps, 'div'>(({
  title,
  subtitle,
  description,
  children,
}, ref) => {
  const sub = useMemo(() => (subtitle ? (
    <Paragraph size="medium" color="grey.60" pt="4">{subtitle}</Paragraph>
  ) : null), [subtitle]);

  const desc = useMemo(() => (description ? (
    <Paragraph size="small" color="grey.60" pt="4">{description}</Paragraph>
  ) : null), [description]);

  return (
    <Flex as="header" gap="8" ref={ref} pt="20" px="16" pb="12">
      <Box flexGrow={1}>
        <Heading as="h3" size="xs" pt="4">{title}</Heading>
        {sub}
        {desc}
      </Box>
      <Box flexGrow={0}>
        <Box>
          {children}
        </Box>
      </Box>
    </Flex>
  );
}));

ListHeader.displayName = BLOCK_NAME;
