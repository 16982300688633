// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const MemberCardIcon = createIcon({
  displayName: 'MemberCardIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11.715 10C11.715 11.151 10.866 12 9.71503 12C8.56303 12 7.71503 11.151 7.71503 10C7.71503 8.849 8.56403 8 9.71503 8C10.866 8 11.715 8.849 11.715 10Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M14 9H18V11H14V9Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M18 13H15V15H18V13Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M13.43 16C13.43 14.626 11.754 12.75 9.715 12.75C7.676 12.75 6 14.626 6 16H13.43Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M2 10.4C2 8.15979 2 7.03969 2.43597 6.18404C2.81947 5.43139 3.43139 4.81947 4.18404 4.43597C5.03969 4 6.15979 4 8.4 4H15.6C17.8402 4 18.9603 4 19.816 4.43597C20.5686 4.81947 21.1805 5.43139 21.564 6.18404C22 7.03969 22 8.15979 22 10.4V13.6C22 15.8402 22 16.9603 21.564 17.816C21.1805 18.5686 20.5686 19.1805 19.816 19.564C18.9603 20 17.8402 20 15.6 20H8.4C6.15979 20 5.03969 20 4.18404 19.564C3.43139 19.1805 2.81947 18.5686 2.43597 17.816C2 16.9603 2 15.8402 2 13.6V10.4ZM8.4 6H15.6C16.7531 6 17.4977 6.00156 18.0643 6.04785C18.6077 6.09225 18.8091 6.1676 18.908 6.21799C19.2843 6.40973 19.5903 6.71569 19.782 7.09202C19.8324 7.19091 19.9078 7.39235 19.9521 7.93567C19.9984 8.50235 20 9.24689 20 10.4V13.6C20 14.7531 19.9984 15.4977 19.9521 16.0643C19.9078 16.6077 19.8324 16.8091 19.782 16.908C19.5903 17.2843 19.2843 17.5903 18.908 17.782C18.8091 17.8324 18.6077 17.9078 18.0643 17.9521C17.4977 17.9984 16.7531 18 15.6 18H8.4C7.24689 18 6.50235 17.9984 5.93567 17.9521C5.39235 17.9078 5.19091 17.8324 5.09202 17.782C4.71569 17.5903 4.40973 17.2843 4.21799 16.908C4.1676 16.8091 4.09225 16.6077 4.04785 16.0643C4.00156 15.4977 4 14.7531 4 13.6V10.4C4 9.24689 4.00156 8.50235 4.04785 7.93567C4.09225 7.39235 4.1676 7.19091 4.21799 7.09202C4.40973 6.71569 4.71569 6.40973 5.09202 6.21799C5.19091 6.1676 5.39235 6.09225 5.93567 6.04785C6.50235 6.00156 7.24689 6 8.4 6Z"
      />
    ),
  ],
});
