import { Box } from '@chakra-ui/react';
import { forwardRef, useId } from 'react';

type EmochanAILogoProps = {
  width?: string
  height?: string
}

export const EmochanAILogo = forwardRef<SVGSVGElement, EmochanAILogoProps>(({
  width = '175px',
  height = '32px',
}, ref) => {
  const id = useId();

  return (
    <Box
      ref={ref}
      as="svg"
      focusable={false}
      width={width}
      height={height}
      viewBox="0 0 176 32"
      fill={`url(#${id})`}
    >
      <title>emochan ai</title>

      <linearGradient id={id} x1="0" y1="0" x2="11.3077" y2="61.9355" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1238FF" />
        <stop offset="1" stopColor="#3369FF" />
      </linearGradient>

      <path d="M11.6287 7.51563L13.1719 9.05802L9.05752 13.1716L7.51432 11.6292L11.6287 7.51563Z" />
      <path d="M23.7578 10.6662C23.7578 9.05984 22.4554 7.75664 20.8482 7.75664C19.2426 7.75664 17.9394 9.05984 17.9394 10.6662C17.9394 12.2726 19.2426 13.575 20.8482 13.575C22.4554 13.575 23.7578 12.2726 23.7578 10.6662Z" />
      <path d="M10.6666 17.9389C12.2738 17.9389 13.5762 19.2413 13.5762 20.8477C13.5762 22.4541 12.2738 23.7565 10.6666 23.7565C9.06021 23.7565 7.75781 22.4541 7.75781 20.8477C7.75781 19.2413 9.06021 17.9389 10.6666 17.9389Z" />
      <path d="M25.4544 18.1811C25.4544 22.1979 22.1984 25.4539 18.1816 25.4539V23.2715C20.9936 23.2715 23.2728 20.9923 23.2728 18.1811H25.4544Z" />
      <path d="M22.0552 0H9.7976C6.5488 0.0096 5.2888 0.3488 4.0792 0.9776L3.9688 1.036C2.7048 1.712 1.7128 2.704 1.036 3.9688C0.36 5.2328 0 6.4864 0 9.9448V22.2016C0.0104 25.4504 0.3488 26.7104 0.9784 27.9208L1.036 28.0312C1.7128 29.2952 2.7048 30.2872 3.9688 30.9632C5.2328 31.6392 6.4864 32 9.9448 32L22.3692 31.999C25.0375 31.9811 26.3294 31.7318 27.391 31.2745L27.6324 31.1653L27.8688 31.0488L28.0312 30.9632C29.2952 30.2872 30.2872 29.2952 30.964 28.0312C31.5552 26.9257 31.9047 25.8275 31.9831 23.2558L31.996 22.6594L32 22.0544V9.9448C32 7.05443 31.7487 5.70398 31.2712 4.60056L31.1655 4.36743L31.0488 4.1312L30.964 3.9688C30.2872 2.704 29.2952 1.712 28.0312 1.036C26.7672 0.36 25.5136 0 22.0552 0ZM9.8071 2.90885H22.0551L22.7062 2.91301C24.676 2.93815 25.5717 3.09136 26.3613 3.45295L26.5655 3.55205L26.6591 3.60165C27.4159 4.00565 27.9935 4.58325 28.3983 5.34005C28.9111 6.29765 29.0903 7.23125 29.0903 9.94485L29.0895 22.4425C29.074 24.5973 28.9242 25.5374 28.547 26.3608L28.4479 26.5649L28.3983 26.6593C27.9935 27.4169 27.4159 27.9937 26.6591 28.3985C25.7015 28.9105 24.7687 29.0905 22.0551 29.0905H9.9447L9.5567 29.0889C7.58144 29.0753 6.62745 28.9478 5.84754 28.6365L5.63851 28.547L5.4343 28.4481L5.3407 28.3985C4.5831 27.9937 4.0063 27.4169 3.6015 26.6593C3.1135 25.7473 2.92951 24.8673 2.91111 22.4489L2.90951 22.1929V9.94405L2.91295 9.29375C2.94093 7.15259 3.11883 6.27984 3.5519 5.43445L3.6015 5.34005C4.0063 4.58325 4.5831 4.00565 5.3407 3.60165C6.2519 3.11365 7.1327 2.92965 9.5503 2.91045L9.8071 2.90885Z" />
      <path d="M103.369 22.3723H107.395V16.4502C107.395 15.1515 108.407 13.9567 109.654 13.9567C110.823 13.9567 111.316 14.6061 111.316 16.4242V22.3723H115.341V15.697C115.341 12.9178 114.173 10.8398 111.186 10.8398C109.784 10.8398 108.096 11.697 107.395 12.9177V6.78788H103.369V22.3723Z" />
      <path d="M83.1894 10.7359C86.7993 10.7359 89.6561 13.3074 89.6561 16.7619C89.6561 20.2684 86.7993 22.7879 83.1894 22.7879C79.5536 22.7879 76.7228 20.2684 76.7228 16.7619C76.7228 13.3074 79.5536 10.7359 83.1894 10.7359ZM83.1894 19.9827C84.6697 19.9827 85.7086 18.8918 85.7086 16.7619C85.7086 14.658 84.6697 13.5411 83.1894 13.5411C81.7091 13.5411 80.6703 14.658 80.6703 16.7619C80.6703 18.8918 81.7351 19.9827 83.1894 19.9827Z" />
      <path d="M48.0252 10.8398C50.9339 10.8398 53.2193 12.6061 53.2193 15.3593C53.2193 15.9307 53.1414 16.8139 53.0375 17.2035H46.1553C46.1813 18.632 47.0123 19.9567 49.4536 19.9567C50.7261 19.9567 52.1805 19.4372 53.0115 18.7619V21.3593C51.9727 22.1645 50.3625 22.684 48.2329 22.684C44.649 22.684 42.1818 20.1645 42.1818 16.684C42.1818 13.0996 45.0126 10.8398 48.0252 10.8398ZM48.1031 13.1255C47.1682 13.1255 46.441 13.697 46.2332 15.3333H49.7133C49.973 14.1645 49.4276 13.1255 48.1031 13.1255Z" />
      <path d="M55.5875 22.3723H59.6129V16.4502C59.6129 14.9178 60.5219 13.9567 61.6646 13.9567C62.8332 13.9567 63.1449 14.684 63.1449 16.2424V22.3723H67.1703V16.4502C67.1703 14.9178 68.0793 13.9567 69.222 13.9567C70.3647 13.9567 70.7023 14.684 70.7023 16.2424V22.3723H74.7277V15.697C74.7277 12.9178 73.8187 10.8398 70.8321 10.8398C69.0661 10.8398 67.6378 11.8268 66.8587 13.3074C66.4172 11.8528 65.3524 10.8398 63.2488 10.8398C61.7684 10.8398 60.2102 11.645 59.483 13.1515L59.3532 11.1515H55.5875V22.3723Z" />
      <path d="M101.156 21.5671C100.273 22.2684 98.7926 22.684 97.0526 22.684C93.7284 22.684 91.0794 20.2424 91.0794 16.7359C91.0794 13.2294 93.7284 10.8398 97.0526 10.8398C98.7926 10.8398 100.273 11.2814 101.156 11.9567V14.8139C100.247 14.0606 99.2601 13.7749 98.1693 13.7749C96.4293 13.7749 95.1048 14.8918 95.1048 16.7359C95.1048 18.632 96.4293 19.7489 98.1693 19.7489C99.2601 19.7489 100.299 19.4372 101.156 18.684V21.5671Z" />
      <path d="M130.364 11.1515H126.65L126.546 12.7359C125.585 11.3853 123.897 10.8398 122.598 10.8398C119.586 10.8398 117.404 13.3074 117.404 16.7619C117.404 20.2424 119.586 22.684 122.598 22.684C123.897 22.684 125.585 22.1385 126.546 20.7879L126.65 22.3723H130.364V11.1515ZM126.468 16.7619C126.468 18.71 125.377 19.8268 123.949 19.8268C122.521 19.8268 121.43 18.71 121.43 16.7619C121.43 14.8139 122.521 13.697 123.949 13.697C125.377 13.697 126.468 14.8139 126.468 16.7619Z" />
      <path d="M133.119 22.3723H137.144V16.4502C137.144 15.1515 138.157 13.9567 139.403 13.9567C140.572 13.9567 141.065 14.6061 141.065 16.4242V22.3723H145.091V15.697C145.091 12.9178 143.922 10.8398 140.936 10.8398C139.429 10.8398 137.689 11.671 137.014 13.1515L136.884 11.1515H133.119V22.3723Z" />
      <path d="M174.094 4.36364C173.375 4.36364 172.716 4.96086 172.626 5.67753C172.536 6.39418 173.076 6.9914 173.795 6.9914C174.514 6.9914 175.173 6.39418 175.263 5.67753C175.353 4.96086 174.814 4.36364 174.094 4.36364Z" />
      <path d="M171.1 16.1969C170.663 17.8786 170.229 19.544 170.229 20.6677C170.229 21.892 171.008 22.6684 172.117 22.6684C172.896 22.6684 173.585 22.4295 174.244 22.0413V21.1754C173.975 21.265 173.645 21.3545 173.345 21.3545C172.776 21.3545 172.417 21.0858 172.417 20.399C172.417 19.2081 172.867 17.477 173.313 15.7626L173.313 15.7623L173.313 15.7617C173.751 14.0803 174.184 12.415 174.184 11.2914C174.184 10.0671 173.405 9.2907 172.297 9.2907C171.518 9.2907 170.829 9.52959 170.17 9.91778V10.7837C170.439 10.6942 170.769 10.6046 171.068 10.6046C171.638 10.6046 171.997 10.8733 171.997 11.5601C171.997 12.751 171.547 14.4822 171.101 16.1966L171.1 16.1969Z" />
      <path d="M155.042 15.8303C155.701 12.0081 158.907 9.17126 162.652 9.17126C163.94 9.17126 165.288 9.64903 165.978 10.2761L166.816 9.2907H168.075L166.217 19.7719C166.007 20.9365 166.337 21.3545 167.056 21.3545C167.356 21.3545 167.685 21.265 167.955 21.1754V22.0414C167.296 22.4594 166.607 22.6684 165.828 22.6684C164.689 22.6684 163.91 21.9816 164 20.7275C163.071 21.892 161.453 22.7879 159.686 22.7879C156.031 22.7879 154.413 19.5032 155.042 15.8303ZM164.539 17.2039L165.468 11.8587C164.869 11.3511 164 10.8136 162.472 10.8136C159.746 10.8136 157.738 13.1428 157.229 15.9198C156.72 18.7566 157.708 21.1455 160.405 21.1455C162.113 21.1455 164.15 19.4434 164.539 17.2039Z" />
    </Box>
  );
});
