import { Flex } from '@chakra-ui/react';
import { useTranscriptBox } from './TranscriptBoxProvider';
import { EditableTranscriptProps, EditableTranscript } from './EditableTranscript';
import { Avatar } from '../../displays/Avatar';

export interface TranscriptTextInputProps extends EditableTranscriptProps {
  text?: string,
  avatarURL?: string,
  name: string,
}

export function TranscriptTextInput({
  text,
  avatarURL,
  name,
  ...rest
}: TranscriptTextInputProps): JSX.Element | null {
  const { status } = useTranscriptBox();

  if (status === 'waiting') return null;

  return (
    <Flex gap="16" alignItems="flex-start">
      {avatarURL && <Avatar size="xs" imageURL={avatarURL} name={name} /> }
      <EditableTranscript
        {...rest}
        name={name}
        defaultValue={text}
      />
    </Flex>
  );
}
