import { forwardRef, useMemo } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { EmoFeeling } from '../../../system/styles';
import { EmoFacePaths } from '../../../constants/emo';

export interface EmoFaceProps extends BoxProps {
  feeling: EmoFeeling;
  filled?: boolean;
}

export const EmoFace = forwardRef<HTMLDivElement, EmoFaceProps>(({
  feeling,
  filled = false,
  ...props
}, ref) => {
  const fillVar = useMemo(() => {
    switch (feeling) {
      case 'joy': {
        return 'yellow.50';
      }
      case 'trust': {
        return 'lime.50';
      }
      case 'fear': {
        return 'green.50';
      }
      case 'surprise': {
        return 'aqua.50';
      }
      case 'sadness': {
        return 'navy.50';
      }
      case 'disgust': {
        return 'purple.50';
      }
      case 'anger': {
        return 'red.50';
      }
      case 'anticipation': {
        return 'orange.50';
      }
      case 'joker': {
        return 'grey.50';
      }
      default: {
        break;
      }
    }
  }, [feeling]);

  const filledFace = useMemo(
    () => (!filled ? null : <Box as="ellipse" fill={fillVar} cx="72" cy="72" rx="72" ry="72" />),
    [fillVar, filled],
  );

  return (
    <Box
      as="svg"
      ref={ref}
      viewBox="0 0 144 144"
      fill="currentColor"
      focusable="false"
      {...props}
    >
      <title>{EmoFacePaths[feeling].label}</title>
      {filledFace}
      {EmoFacePaths[feeling].paths.map((path) => <path key={path} d={path} />)}
      <path d="M72 11.7768C105.206 11.7768 132.223 38.7941 132.223 72C132.223 105.206 105.206 132.223 72 132.223C38.7941 132.223 11.7768 105.206 11.7768 72C11.7999 38.7941 38.7941 11.7768 72 11.7768ZM72 0C32.236 0 0 32.236 0 72C0 111.764 32.236 144 72 144C111.764 144 144 111.764 144 72C144.023 32.236 111.787 0 72 0Z" />
    </Box>
  );
});
