import { FC, useRef } from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import { Flex } from '@chakra-ui/react';
import { BoldIcon, ItalicIcon, ListIcon, NumberedListIcon, StrikeIcon, UnderlineIcon } from '@emochan-cabinet/icons';
import { FormatButton } from './FormatButton';
import { useMedia } from '../../../hooks/useMedia';

export type EditorBubbleMenuProps = {
  editor: Editor | null
}

export const EditorBubbleMenu: FC<EditorBubbleMenuProps> = ({ editor }) => {
  const { isMedium } = useMedia();
  const ref = useRef(null);

  return (
    <div ref={ref}>
      {editor && ref.current && (
        <BubbleMenu
          editor={editor}
          // https://atomiks.github.io/tippyjs/v6/all-props/
          tippyOptions={{
            duration: 0,
            placement: isMedium ? 'bottom' : 'top',
            // https://atomiks.github.io/tippyjs/v6/accessibility/#interactivity
            appendTo: ref.current,
          }}
        >
          <Flex
            bg="grey.0"
            mx="12"
            borderRadius="12"
            borderWidth="1"
            borderColor="light.transparent"
            boxShadow="middle.below"
            role="toolbar"
            aria-label="emochan editor toolbar"
          >
            <FormatButton
              label="bold"
              icon={<BoldIcon />}
              onClick={() => {
                editor?.chain().focus().toggleBold().run();
              }}
              isActive={editor?.isActive('bold')}
            />
            <FormatButton
              label="italic"
              icon={<ItalicIcon />}
              onClick={() => {
                editor?.chain().focus().toggleItalic().run();
              }}
              isActive={editor?.isActive('italic')}
            />
            <FormatButton
              label="underline"
              icon={<UnderlineIcon />}
              onClick={() => {
                editor?.commands?.toggleUnderline();
              }}
              isActive={editor?.isActive('underline')}
            />
            <FormatButton
              label="strike"
              icon={<StrikeIcon />}
              onClick={() => {
                editor?.chain().focus().toggleStrike().run();
              }}
              isActive={editor?.isActive('strike')}
            />
            <FormatButton
              label="bullet list"
              icon={<ListIcon />}
              onClick={() => {
                editor?.commands.toggleBulletList();
              }}
              isActive={editor?.isActive('bulletList')}
            />
            <FormatButton
              label="numbered list"
              icon={<NumberedListIcon />}
              onClick={() => {
                editor?.commands.toggleOrderedList();
              }}
              isActive={editor?.isActive('ordererdList')}
            />
          </Flex>
        </BubbleMenu>
      )}
    </div>
  );
};
