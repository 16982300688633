import { FC } from 'react';
import { Flex, Box, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';

const themeBlue1 = ['blue-10', 'blue-20', 'blue-30'];
const themeBlue2 = ['blue-30', 'blue-20', 'blue-10'];
const themeRed1 = ['red-10', 'red-20', 'red-30'];
const themeRed2 = ['red-30', 'red-20', 'red-10'];
const themeGrey1 = ['grey-20', 'grey-30', 'grey-40'];
const themeGrey2 = ['grey-40', 'grey-30', 'grey-20'];

type BeatLoaderType = 'fill' | 'ghost';

const fillColors = {
  accent: themeBlue1,
  accentSecondary: themeBlue2,
  primary: themeGrey2,
  secondary: themeGrey1,
  negative: themeRed1,
};

const ghostColors = {
  accent: themeBlue2,
  accentSecondary: themeBlue1,
  primary: themeGrey1,
  secondary: themeGrey2,
  negative: themeRed2,
};

export type BeatLoaderColor = keyof typeof fillColors;

const blinkTime = 0.75;

type BeatLoaderProps = {
  type?: BeatLoaderType
  color?: BeatLoaderColor
}

export const BeatLoader: FC<BeatLoaderProps> = ({ type = 'fill', color = 'accent' }) => {
  const colors = type === 'fill' ? fillColors : ghostColors;
  const t = colors[color];

  const blink = keyframes({
    '0%': { backgroundColor: `var(--emochan-colors-${t[0]})` },
    '25%': { backgroundColor: `var(--emochan-colors-${t[1]})` },
    '50%, 100%': { backgroundColor: `var(--emochan-colors-${t[2]})` },
  });

  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `1.2s linear 0s infinite normal both running ${blink}`;

  const blinkStyle = {
    display: 'inline-block',
    w: '.5rem',
    h: '.5rem',
    margin: 'auto .125rem',
    borderRadius: 'full',
  };

  return (
    <Flex
      display="inline-flex"
      alignItems="center"
      padding=".125rem"
      margin="auto"
      fontSize="1rem"
      aria-label="loading"
    >
      <Box as="span" {...blinkStyle} animation={animation} />
      <Box as="span" {...blinkStyle} animation={`1.2s linear ${blinkTime / 2}s infinite normal both running ${blink} `} />
      <Box as="span" {...blinkStyle} animation={`1.2s linear ${blinkTime}s infinite normal both running ${blink}`} />
    </Flex>
  );
};
