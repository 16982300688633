import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

export const DropdownItem = forwardRef<BoxProps, 'div'>(({
  children,
  ...props
}, ref) => (
  <Box
    ref={ref}
    _hover={{ cursor: 'pointer', bg: 'transparent.grey.4' }}
    _focus={{ bg: 'transparent.grey.4' }}
    _expanded={{ bg: 'transparent.grey.4' }}
    _active={{
      bg: 'transparent.grey.8',
      pointer: 'grabbing',
    }}
    _disabled={{
      opacity: 0.4,
      cursor: 'not-allowed',
    }}
    {...props}
  >
    {children}
  </Box>
));

DropdownItem.displayName = 'Dropdown.Item';
