// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const VisibilityOffIcon = createIcon({
  displayName: 'VisibilityOffIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M3.70646 3.51763C4.0965 3.12759 4.72888 3.12759 5.11892 3.51763L21.3806 19.7794C21.3811 19.7798 21.3819 19.7798 21.3824 19.7794C21.3829 19.7789 21.3837 19.7789 21.3841 19.7793C21.3846 19.7798 21.3846 19.7806 21.3841 19.7811L20.6779 20.4873C20.2874 20.8778 19.6542 20.8778 19.2637 20.4873L16.6139 17.8376C15.357 18.4238 13.8334 18.8096 12.0002 18.8096C4.40822 18.8096 2.12654 12.1926 2.10466 12.1256C2.03701 11.9209 2.03742 11.6982 2.10565 11.4936C2.11885 11.4513 3.03427 8.79507 5.6207 6.84433L3.70646 4.93009C3.31642 4.54005 3.31642 3.90767 3.70646 3.51763ZM15.0869 16.3106L13.2897 14.5133C12.8978 14.703 12.4601 14.8096 12.0002 14.8096C10.3582 14.8096 9.00022 13.4516 9.00022 11.8096C9.00022 11.3498 9.10687 10.912 9.29654 10.5202L7.03655 8.26018C5.54751 9.28418 4.70709 10.6123 4.30063 11.418C4.17625 11.6646 4.17623 11.9548 4.30059 12.2013C5.03285 13.6532 7.17861 16.8096 12.0002 16.8096C13.1842 16.8096 14.2069 16.6193 15.0869 16.3106Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M9.50044 5.07073C10.2613 4.90417 11.0928 4.80964 12.0002 4.80964C19.5922 4.80964 21.8739 11.4266 21.8958 11.4936C21.9634 11.6983 21.963 11.9211 21.8948 12.1256C21.8845 12.1587 21.325 13.7828 19.8513 15.4216L18.4596 14.0299C19.0588 13.3513 19.4569 12.6828 19.6999 12.2011C19.8243 11.9546 19.8242 11.6645 19.6999 11.418C18.9676 9.96612 16.8218 6.80964 12.0002 6.80964C11.7477 6.80964 11.5026 6.8183 11.2646 6.83491L9.50044 5.07073Z"
      />
    ),
  ],
});
