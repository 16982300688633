import { useMemo, useCallback, ChangeEvent, HTMLAttributes, cloneElement, useState, useRef } from 'react';
import { CheckIcon, ChevDIcon } from '@emochan-cabinet/icons';
import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import { useFormControl } from '../FormControl';
import { Colors } from '../../../system/styles';
import { useESCKey } from '../../../hooks/useESCKey';

interface SelectFieldOption {
  label: string,
  value?: string | number,
  leftIcon?: JSX.Element,
}

interface SelectFieldProps extends HTMLAttributes<HTMLDetailsElement> {
  currentValue?: SelectFieldOption['value'],
  leftIcon?: JSX.Element,
  options: SelectFieldOption[],
  handleChange(current?: SelectFieldOption['value']): void,
}

/**
 * Select Component です。
 * Size Variants は未定義です。
 */
export function SelectField({
  leftIcon,
  placeholder,
  currentValue,
  options,
  handleChange,
  ...props
}: SelectFieldProps): JSX.Element {
  const current = options.find((option) => option.value === currentValue);
  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(current);
  const ref = useRef<HTMLDetailsElement|null>(null);

  const handleClose = useCallback(() => setIsOpen(false), []);

  const updateCurrent = useCallback((state?: SelectFieldOption) => {
    setCurrentOption(state);
    handleChange?.(state?.value);
    handleClose();
  }, [handleChange, handleClose]);

  // 他の要素をクリックしたら閉じる
  useOutsideClick({
    ref,
    handler: handleClose,
  });

  // ESCキーで閉じる
  useESCKey(handleClose);

  // 判定は FormControl から注入する
  const {
    id,
    isDisabled,
    isInvalid,
    isValid,
    isRequired,
  } = useFormControl();

  const bgColor = useMemo(() => {
    if (isInvalid) return Colors.red5;
    if (isValid) return Colors.green5;

    return Colors.grey0;
  }, [isInvalid, isValid]);

  const iconColor = useMemo(() => {
    if (isInvalid) return Colors.red50;
    if (isValid) return Colors.green50;
    if (currentOption?.value !== undefined) return Colors.green90;

    return Colors.grey60;
  }, [isInvalid, isValid, currentOption]);

  const labelColor = useMemo(() => {
    if (currentOption?.value !== undefined) return Colors.green90;

    return Colors.grey60;
  }, [currentOption]);

  const requiredProps = useMemo(() => {
    if (isRequired) return { isRequired };

    return {};
  }, [isRequired]);

  const invalidProps = useMemo(() => {
    if (isInvalid) {
      return {
        isInvalid: true,
        'aria-describedby': `${id}-feedback`,
      };
    }

    if (isValid) return { _hover: { bg: 'green.5' } };

    return {};
  }, [isInvalid, isValid, id]);

  const boxShadow = useMemo(() => {
    if (isInvalid) return 'inset 0 0 0 2px var(--emochan-colors-red-50)';
    if (isValid) return 'inset 0 0 0 2px var(--emochan-colors-green-50)';
    if (isOpen) return 'inset 0 0 0 2px var(--emochan-colors-blue-50)';
    if (isDisabled) return 'inset 0 0 0 1px var(--emochan-colors-grey-5)';

    return 'inset 0 0 0 1px var(--emochan-colors-grey-30)';
  }, [isInvalid, isValid, isDisabled, isOpen]);

  const styleProps = {
    ...props,
    ...requiredProps,
    ...invalidProps,
  };

  const handleToggle = useCallback((e: ChangeEvent<HTMLDetailsElement>) => {
    setIsOpen(e.currentTarget.open);
  }, []);

  return (
    <Box
      ref={ref}
      as="details"
      id={props.id || id}
      onToggle={handleToggle}
      w="100%"
      open={!isDisabled && isOpen}
    >
      <Flex
        as="summary"
        listStyleType="none"
        justifyContent="space-between"
        gap={8}
        w="100%"
        pt={12}
        pb={12}
        pl={16}
        pr={16}
        margin={0}
        fontSize="paragraph.medium"
        lineHeight="paragraph.medium"
        bg={bgColor}
        border="none"
        borderRadius={12}
        boxShadow={boxShadow}
        cursor="pointer"
        transition="background-color 80ms, box-shadow 80ms"
        overscrollBehavior="contain"
        pointerEvents={isDisabled ? 'none' : 'auto'}
        outline="none"
        _hover={{ bg: Colors.transparentGrey4 }}
        _focus={{ bg: Colors.grey0 }}
        {...styleProps}
      >
        {leftIcon && cloneElement(leftIcon, { color: iconColor })}
        <Box flexGrow={1} color={labelColor} userSelect="none">
          {currentOption ? currentOption.label : placeholder}
        </Box>
        <ChevDIcon color={labelColor} />
      </Flex>

      <Flex flexDir="column" py={12} boxShadow="low.below" borderRadius={12} overflow="hidden">
        {
          options.map((option) => (
            <Flex
              key={option.value + option.label}
              as="button"
              type="button"
              py="12"
              alignItems="center"
              justifyContent="space-between"
              userSelect="none"
              outline="none"
              _hover={{ bg: 'transparent.grey.4' }}
              _focus={{ bg: 'transparent.grey.12' }}
              onClick={() => {
                if (!option.value) {
                  updateCurrent();
                  return;
                }
                updateCurrent(option);
              }}
            >
              {
                option.leftIcon && (
                  <Box pl="24" pr="8">
                    {option.leftIcon}
                  </Box>
                )
              }
              <Box flexGrow={1} px="16">{option.label}</Box>
              {
                currentOption?.value === option.value && (
                  <Box pl="8" pr="24">
                    <CheckIcon color="blue.50" />
                  </Box>
                )
              }
            </Flex>
          ))
        }
      </Flex>
    </Box>
  );
}

SelectField.displayName = 'SelectField';
