import { useMemo } from 'react';
import { Flex, VisuallyHidden } from '@chakra-ui/react';
import { Paragraph } from '../../typography/Paragraph';
import { Avatar } from '../../displays/Avatar';
import { useTranscriptBox } from './TranscriptBoxProvider';
import { EditableTranscriptProps, EditableTranscript } from './EditableTranscript';

export interface TranscriptProps extends EditableTranscriptProps {
  text: string,
  avatarURL?: string,
  name: string,
  isEditable?: boolean,
}

export function Transcript({
  text,
  name,
  avatarURL,
  isEditable = false,
  ...rest
}: TranscriptProps): JSX.Element {
  const { status } = useTranscriptBox();

  const textContent = useMemo(() => {
    if (isEditable && status !== 'waiting') {
      return (
        <EditableTranscript
          {...rest}
          name={name}
          defaultValue={text}
        />
      );
    }

    return (
      <Paragraph
        as="div"
        size="medium"
        w="100%"
        color={rest.color}
        _hover={{ cursor: 'not-allowed' }}
      >
        {text}
      </Paragraph>
    );
  }, [isEditable, status, text, name, rest]);

  return (
    <Flex gap="24" alignItems="flex-start">
      { avatarURL && <Avatar size="xs" imageURL={avatarURL} name={name} /> }
      <VisuallyHidden>{`${name}: `}</VisuallyHidden>
      {textContent}
    </Flex>
  );
}

Transcript.displayName = 'Transcript';
