/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { CheckCircleFilledIcon } from '@emochan-cabinet/icons';
import { forwardRef, FC, HTMLAttributes, useMemo, useId } from 'react';
import { EmoFace } from '../EmoFace';
import { Logo } from '../../displays/Logo';
import { Avatar, AvatarSize, AvatarKind } from '../../displays/Avatar';
import { Label } from '../../typography/Label';
import { EmoFeeling, EmoColors } from '../../../system/styles';
import { EmoOnomatope, EmoNameJa } from '../../../constants/emo';

const BLOCK_NAME = 'EmoCard';

export const EmoCardSize = {
  small: 'small',
  medium: 'medium',
} as const;
export type EmoCardSize = typeof EmoCardSize[keyof typeof EmoCardSize];

export interface EmoCardProps extends HTMLAttributes<HTMLDivElement> {
  feeling: EmoFeeling;
  user?: {
    name: string;
    imageURL?: string;
  }
  selectable?: boolean;
  selected?: boolean;
  tag?: BoxProps['as'];
  disabled?: boolean;
  size?: EmoCardSize;
  showName?: boolean;
}

const Joker = (
  <Flex
    align="center"
    justify="center"
    {...{
      transform: 'rotate(180deg)',
      transformOrigin: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: '3.75rem',
    }}
  >
    <Logo kind="emochanMark" size="xl" />
  </Flex>
);

const EmoCardClipPath: FC<{id: string}> = ({ id }) => (
  <Box as="svg" position="absolute" w="0" h="0" viewBox="0 0 1 1">
    <clipPath id={id} clipPathUnits="objectBoundingBox">
      <path d="M0 0.4C0 0.259987 0 0.18998 0.0272484 0.136502C0.0512167 0.0894619 0.0894619 0.0512167 0.136502 0.0272484C0.18998 0 0.259987 0 0.4 0H0.6C0.740013 0 0.81002 0 0.863498 0.0272484C0.910538 0.0512167 0.948783 0.0894619 0.972752 0.136502C1 0.18998 1 0.259987 1 0.4V0.6C1 0.740013 1 0.81002 0.972752 0.863498C0.948783 0.910538 0.910538 0.948783 0.863498 0.972752C0.81002 1 0.740013 1 0.6 1H0.4C0.259987 1 0.18998 1 0.136502 0.972752C0.0894619 0.948783 0.0512167 0.910538 0.0272484 0.863498C0 0.81002 0 0.740013 0 0.6V0.4Z" />
    </clipPath>
  </Box>
);

type UserFace = {
  name: string;
  imageURL?: string
}

const Face: FC<{ user?: UserFace, feeling: EmoFeeling, size: EmoCardSize }> = ({
  user,
  feeling,
  size,
}) => {
  if (user) {
    return (
      <Box
        borderRadius="half"
        bg="grey.0"
        p={size === 'small' ? 8 : 12}
        position="absolute"
        inset={size === 'small' ? '3.75rem' : '4.5rem'}
        // Safari 14.1未満
        top={size === 'small' ? '3.75rem' : '4.5rem'}
        left={size === 'small' ? '3.75rem' : '4.5rem'}
      >
        <Avatar
          size={size === 'small' ? AvatarSize.large : AvatarSize.xl}
          kind={AvatarKind.user}
          name={user.name}
          imageURL={user.imageURL}
        />
      </Box>
    );
  }

  if (feeling === 'joker') {
    return Joker;
  }

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      w="6rem"
      h="6rem"
      padding="3.75rem"
      boxSizing="content-box"
    >
      <EmoFace feeling={feeling} filled />
    </Box>
  );
};

export const EmoCard = forwardRef<HTMLDivElement, EmoCardProps>(({
  feeling,
  user,
  selected = false,
  selectable = selected,
  disabled,
  tag = 'button',
  size,
  showName = !!user,
  ...props
}, ref) => {
  const cardSize = useMemo(() => {
    if (size) {
      return size;
    }

    return user ? EmoCardSize.medium : EmoCardSize.small;
  }, [user, size]);

  const emoFace = useMemo(() => (
    <Face
      user={user}
      feeling={feeling}
      size={cardSize}
    />
  ), [feeling, user, cardSize]);

  const emoOnomatope = useMemo(() => (
    <Box
      as="svg"
      viewBox="0 0 288 144"
      fill="currentColor"
      position="absolute"
    >
      <title>{EmoOnomatope[feeling].label}</title>
      {EmoOnomatope[feeling].paths.map((path) => <path key={path} d={path} />)}
    </Box>
  ), [feeling]);

  const emoName = useMemo(() => {
    if (user && showName) {
      return (
        <Label
          align="center"
          css={css({
            position: 'absolute',
            bottom: 'var(--emochan-space-24)',
            left: 'var(--emochan-space-36)',
            width: 'calc(100% - calc(2 * var(--emochan-space-36)))',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          })}
        >
          {user.name}
        </Label>
      );
    }

    return (
      <Box
        as="svg"
        w="100%"
        position="absolute"
        bottom={0}
        left={0}
        viewBox="0 0 216 108"
        fill="currentColor"
      >
        <title>{EmoNameJa[feeling].label}</title>
        {EmoNameJa[feeling].paths.map((path) => <path key={path} d={path} />)}
      </Box>
    );
  }, [feeling, user]);

  const checkMark = useMemo(() => {
    if (!selected) return null;

    return (
      <Flex justify="center" py="20">
        <CheckCircleFilledIcon />
      </Flex>
    );
  }, [selected]);

  const clipPathId = useId();

  return (
    <>
      <EmoCardClipPath id={clipPathId} />
      <Box
        p={selectable ? '4' : undefined}
        borderRadius="quarter"
        sx={{
          '--EmoCard-joy': 'var(--emochan-colors-yellow-70)',
          '--EmoCard-trust': 'var(--emochan-colors-lime-70)',
          '--EmoCard-fear': 'var(--emochan-colors-green-70)',
          '--EmoCard-surprise': 'var(--emochan-colors-aqua-70)',
          '--EmoCard-sadness': 'var(--emochan-colors-navy-70)',
          '--EmoCard-disgust': 'var(--emochan-colors-purple-70)',
          '--EmoCard-anger': 'var(--emochan-colors-red-70)',
          '--EmoCard-anticipation': 'var(--emochan-colors-orange-70)',
          '--EmoCard-joker': 'var(--emochan-colors-grey-70)',
        }}
        userSelect="none"
        cursor={selectable ? 'pointer' : undefined}
        transition={selectable ? 'box-shadow .2s ease-out' : undefined}
        boxShadow={selected ? `0 0 0 4px var(--EmoCard-${feeling})` : undefined}
        _hover={{ boxShadow: selectable ? selected ? `0 0 0 4px var(--EmoCard-${feeling})` : '0 0 0 4px var(--emochan-colors-transparent-grey-10)' : undefined }}
        _focus={{
          boxShadow: selectable ? '0 0 0 4px var(--emochan-colors-transparent-grey-30)' : undefined,
          outline: 'none',
        }}
        as={user ? 'div' : tag}
        {...props}
      >
        <Box
          w={cardSize === EmoCardSize.small ? '13.5rem' : '18rem'}
          h={cardSize === EmoCardSize.small ? '13.5rem' : '18rem'}
          filter={`drop-shadow(${cardSize === EmoCardSize.small
            ? 'rgba(0, 4, 15, 0.16) 0px 8px 16px'
            : 'rgba(0, 4, 15, 0.24) 0px 16px 18px'})`}
          willChange="filter" // Safari でシャドーが切れる対策
        >
          <Box
            ref={ref}
            color="grey.0"
            bg={`gradient.${EmoColors[feeling]}`}
            borderRadius="quarter"
            position="relative"
            w="100%"
            h="100%"
            clipPath={`url("#${clipPathId}")`}
            overflow="hidden"
            _before={{
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 0,
              left: 0,
              w: '100%',
              h: '50%',
              bg: 'var(--emochan-colors-transparent-grey-30)',
              bgBlendMode: 'multiply',
            }}
          >
            {emoFace}
            {emoOnomatope}
            {emoName}
            {checkMark}
            {disabled && (
              <Box
                position="absolute"
                inset={0}
                bg="grey.30"
                mixBlendMode="multiply"
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
});

EmoCard.displayName = BLOCK_NAME;
