import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';

const bgColor = {
  active: 'grey.80',
  inactive: 'grey.10',
  disabled: 'grey.5',
};

const textColor = {
  active: 'grey.0',
  inactive: 'grey.80',
  disabled: 'grey.30',
};

export type TimerProps = {
  timerText: string, // e.g. 00:00
  isDisabled?: boolean,
  isActive?: boolean,
}

export function Timer({
  timerText,
  isDisabled = false,
  isActive = true,
}: TimerProps): JSX.Element {
  const state = useMemo(() => {
    if (isDisabled) return 'disabled';
    if (isActive) return 'active';
    return 'inactive';
  }, [isDisabled, isActive]);

  return (
    <Box
      as="span"
      display="inline-block"
      px="16"
      py="12"
      bg={bgColor[state]}
      color={textColor[state]}
      borderRadius="24"
      boxShadow="0 0 0 1px var(--emochan-colors-border-light-transparent)"
      lineHeight="1.5"
      fontFamily="mono"
      fontWeight="bold"
    >
      {timerText}
    </Box>
  );
}

Timer.displayName = 'Timer';
