import { forwardRef, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormControl } from '.';
import { Paragraph } from '../../typography/Paragraph';
import { Colors } from '../../../system/styles';

export const ShowInputLength = forwardRef<HTMLDivElement>((props, ref) => {
  // 判定は FormControl から注入する
  const { isDisabled, isInvalid, textLength } = useFormControl();
  const color = useMemo(() => {
    if (isDisabled) return Colors.grey30;
    if (isInvalid) return Colors.red50;

    return Colors.grey60;
  }, [isDisabled, isInvalid]);

  const t1 = useMemo(() => <Paragraph as="span" size="xs">{textLength?.length}</Paragraph>, [textLength?.length]);
  const t2 = useMemo(() => <Paragraph as="span" size="xs">/</Paragraph>, []);
  const t3 = useMemo(() => <Paragraph as="span" size="xs">{textLength?.maxLength}</Paragraph>, [textLength?.maxLength]);

  if (textLength?.maxLength === 0) {
    return null;
  }

  return (
    <Box
      display="inline-flex"
      aria-hidden
      color={color}
      ref={ref}
      position="absolute"
      top="0"
      right="0"
      {...props}
    >
      {t1}
      {t2}
      {t3}
    </Box>
  );
});
