import { ReactNode } from 'react';
import { FlexProps, ButtonProps as ChakraButtonProps, ResponsiveValue } from '@chakra-ui/react';
import { ColorFoundation } from '../../../system/styles';

// button component 基盤

export const ButtonSizes = {
  xs: 'xs',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xl: 'xl',
} as const;
export type ButtonSize = typeof ButtonSizes[keyof typeof ButtonSizes];

export const ButtonLabelSizes = {
  xs: 'xs',
  small: 'small',
  medium: 'small',
  large: 'medium',
  xl: 'medium',
} as const;
export type ButtonLabelSize = typeof ButtonLabelSizes[keyof typeof ButtonLabelSizes];

export const ButtonAccessaryPaddings = {
  xs: '0',
  small: '4',
  medium: '4',
  large: '0',
  xl: '0',
} as const;
export type ButtonAccessaryPadding = typeof ButtonAccessaryPaddings[
  keyof typeof ButtonAccessaryPaddings
];

export const ButtonShapes = {
  /**
   * @deprecated
   */
  roundCorner: 'roundCorner',
  pill: 'pill',
} as const;
export type ButtonShape = typeof ButtonShapes[keyof typeof ButtonShapes];

export const ButtonStyles = {
  fill: 'fill',
  outline: 'outline',
  ghost: 'ghost',
  tonal: 'tonal',
} as const;
export type ButtonStyle = typeof ButtonStyles[keyof typeof ButtonStyles];

export const ButtonRadiuses = {
  roundCorner: {
    xs: '6',
    small: '8',
    medium: '12',
    large: '12',
    xl: '12',
  },
  pill: {
    xs: '6',
    small: 'full',
    medium: 'full',
    large: 'full',
    xl: 'full',
  },
} as const;
export type ButtonRadius = typeof ButtonRadiuses[keyof typeof ButtonRadiuses];

export const ButtonSx = {
  '&:disabled,\n  &[aria-disabled="true"]:not([data-loading])': {
    color: 'var(--emochan-colors-grey-30)',
    borderColor: 'var(--emochan-colors-grey-30)',
    backgroundColor: 'transparent',
  },
  '&:disabled.--fill,\n  &[aria-disabled="true"]:not([data-loading]).--fill': {
    color: 'var(--emochan-colors-grey-30)',
    borderColor: 'var(--emochan-colors-grey-30)',
    backgroundColor: 'var(--emochan-colors-grey-5)',
  },
  '&::before,\n  &::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'block',
    width: '100%',
    height: '100%',
    transition: 'background-color .25s ease-out',
  },
  '&::before': {
    backgroundColor: 'transparent !important',
    opacity: '1 !important',
  },
};

type UnderscoreProps = `_${string}`;

export interface ButtonProps extends Omit<FlexProps, 'color' | UnderscoreProps> {
  label?: string;
  leftIcon?: ReactNode;
  leftIconAlign?: 'auto' | 'left';
  rightIcon?: ReactNode;
  color?: Exclude<ColorFoundation, 'positive' | 'warning' | 'secondary'>;
  size?: ResponsiveValue<ButtonSize>;
  shape?: ButtonShape;
  buttonStyle?: ButtonStyle;
  full?: ResponsiveValue<boolean>;
  floated?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  type?: ChakraButtonProps['type']
  noPadding?: boolean
}
