import { forwardRef, useMemo } from 'react';
import { Text, HTMLChakraProps, SystemProps, ResponsiveValue } from '@chakra-ui/react';

export const LabelSizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  xs: 'xs',
} as const;
export type LabelSize = typeof LabelSizes[keyof typeof LabelSizes];

export interface LabelProps extends Omit<HTMLChakraProps<'p'>, 'textAlign' | 'fontWeight'> {
  size?: ResponsiveValue<LabelSize>,
  align?: SystemProps['textAlign']
}

export const Label = forwardRef<HTMLParagraphElement, LabelProps>(({
  size = 'large',
  align,
  children,
  ...rest
}, ref) => {
  const responsiveSize = useMemo(() => {
    if (!size) return size;

    if (typeof size === 'string') {
      return `label.${size}`;
    }

    if (Array.isArray(size)) {
      return size.map((s) => `label.${s}`);
    }

    const r: Record<string, string> = {};

    Object.entries(size).forEach(([key, value]) => {
      r[key] = `label.${value}`;
    });

    return r;
  }, [size]);

  return (
    <Text
      ref={ref}
      fontSize={responsiveSize}
      lineHeight={responsiveSize}
      fontWeight="label"
      textAlign={align}
      {...rest}
    >
      {children}
    </Text>
  );
});

Label.displayName = 'Label';
