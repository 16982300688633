import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAuth, GoogleAuthProvider, AuthErrorCodes } from 'firebase/auth';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { getRemoteConfig } from 'firebase/remote-config';
import { isBrowser, isLocalEnvironment } from './app';

console.debug('[BOOT] use firebase', process.env.NEXT_PUBLIC_FIREBASE_PROJECTID?.replace('emochan-cabinet-', ''));

export const app = !getApps().length
  ? initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN || 'emochan-cabinet-dev.firebaseapp.com',
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASEURL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  }, 'cabinet')
  : getApp('cabinet');

if (!getApps().length) {
  // undefined 入れさせて
  initializeFirestore(app, { ignoreUndefinedProperties: true });
}

export const auth = getAuth(app);

export const db = getFirestore(app);

export const remoteConfig = isBrowser() ? getRemoteConfig(app) : null;

if (remoteConfig) {
  // 5分
  remoteConfig.settings.minimumFetchIntervalMillis = 5 * 60 * 1000;
  remoteConfig.defaultConfig = {
    experimentalFeaturesMembers: '',
    experimentalFeaturesWorkspaces: '',
    experimentalClosedWorkspaces: '',
  };
}

export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

// emulators only for local development
// if (process.env.NEXT_PUBLIC_HOST === 'localhost') {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   connectAuthEmulator(auth, 'http://localhost:4044');
// }

// connectFirestoreEmulator(db, 'localhost', 4088);

export function newId(): string {
  // Alphanumeric characters
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < 20; i += 1) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  console.assert(autoId.length === 20, `Invalid auto ID: ${autoId}`);
  return autoId;
}

interface AuthEventError extends Error {
  code: string;
  message: string;
}

// TODO: Lang 対応
export function translateAuthError(e: AuthEventError, method: string): string {
  switch (e.code) {
    case AuthErrorCodes.EMAIL_EXISTS:
      if (method.indexOf('signup') !== -1) {
        // signup 時に既に登録済みならば、ログインさせてみる
        return '既に登録済みのメールアドレスです。';
      }
      return 'メールアドレス、もしくはパスワードが間違っています';
    case AuthErrorCodes.INVALID_EMAIL:
      return 'メールアドレスの形式が正しくありません。';
    case AuthErrorCodes.USER_DISABLED:
      return 'サービスの利用が停止されています。';
    case AuthErrorCodes.USER_DELETED:
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'メールアドレス、もしくはパスワードが間違っています';
    case AuthErrorCodes.WEAK_PASSWORD:
      return 'パスワードは12文字以上にしてください。';
    case AuthErrorCodes.POPUP_BLOCKED:
      return '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください。';
    case AuthErrorCodes.EXPIRED_OOB_CODE:
      return 'パスワード再設定の期限が切れました。始めからやり直してください。';
    case AuthErrorCodes.INVALID_OOB_CODE:
      return '認証コードが正しくありません。パスワードの再設定をやり直してください。';
    default:
      if (method.indexOf('signin') !== -1) {
        return '認証に失敗しました。しばらく時間をおいて再度お試しください。';
      }
      return 'エラーが発生しました。しばらく時間をおいてお試しください。';
  }
}

// see also: https://firebase.google.com/docs/auth/custom-email-handler?hl=ja
export type EmailHandlerMode = 'resetPassword' | 'recoverEmail' | 'verifyEmail';

export type EmailHandlerQuery = {
  mode: EmailHandlerMode
  oobCode: string
  apiKey: string
  continueUrl: string
  lang: string
}
