// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const MemberPlusIcon = createIcon({
  displayName: 'MemberPlusIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M18 8C17.4477 8 17 8.44772 17 9V11H15C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13H17V15C17 15.5523 17.4477 16 18 16C18.5523 16 19 15.5523 19 15V13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H19V9C19 8.44772 18.5523 8 18 8Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8 15C10.2091 15 12 16.7909 12 19C12 19.5523 12.4477 20 13 20C13.5523 20 14 19.5523 14 19C14 15.6863 11.3137 13 8 13C4.68629 13 2 15.6863 2 19C2 19.5523 2.44772 20 3 20C3.55228 20 4 19.5523 4 19C4 16.7909 5.79086 15 8 15Z"
      />
    ),
  ],
});
