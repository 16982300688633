// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const DocumentPenIcon = createIcon({
  displayName: 'DocumentPenIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M13 2H8.16146C7.63433 1.99998 7.17954 1.99997 6.80497 2.03057C6.40963 2.06287 6.01641 2.13419 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4.13419 4.01641 4.06287 4.40963 4.03057 4.80497C3.99997 5.17954 3.99998 5.63429 4 6.16142V17.8385C3.99998 18.3657 3.99997 18.8205 4.03057 19.195C4.06287 19.5904 4.13419 19.9836 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.01641 21.8658 6.40963 21.9371 6.80497 21.9694C7.17954 22 7.6343 22 8.16144 22H9C9.55229 22 10 21.5523 10 21C10 20.4477 9.55229 20 9 20H8.2C7.62345 20 7.25118 19.9992 6.96784 19.9761C6.69618 19.9539 6.59546 19.9162 6.54601 19.891C6.35785 19.7951 6.20487 19.6422 6.109 19.454C6.0838 19.4045 6.04613 19.3038 6.02393 19.0322C6.00078 18.7488 6 18.3766 6 17.8V6.2C6 5.62345 6.00078 5.25118 6.02393 4.96784C6.04613 4.69618 6.0838 4.59546 6.109 4.54601C6.20487 4.35785 6.35785 4.20487 6.54601 4.109C6.59546 4.0838 6.69618 4.04613 6.96784 4.02393C7.25118 4.00078 7.62345 4 8.2 4H12V7C12 8.65686 13.3431 10 15 10H18.2929C19.2357 10 20 9.2357 20 8.2929C20 7.84014 19.8201 7.40593 19.5 7.08579L15.4142 3C14.7739 2.35971 13.9055 2 13 2ZM14 7V4.41422L17.5858 8H15C14.4477 8 14 7.55229 14 7Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M18.6182 12.512C18.2165 12.3814 17.7838 12.3814 17.3822 12.512C17.0924 12.6061 16.8706 12.7657 16.6957 12.9141C16.5365 13.0492 16.3627 13.223 16.1843 13.4015L12.7617 16.8241L12.7256 16.8601C12.5909 16.9941 12.4214 17.1627 12.2949 17.3691C12.1853 17.548 12.1044 17.7431 12.0555 17.9472C11.999 18.1825 11.9996 18.4216 12.0001 18.6116L12.0002 18.6626L12.0002 20.223C12.0001 20.3421 12.0001 20.4844 12.0103 20.6097C12.0222 20.7549 12.0526 20.9629 12.1637 21.1808C12.3075 21.4631 12.537 21.6925 12.8192 21.8364C13.0372 21.9474 13.2451 21.9779 13.3904 21.9897C13.5157 22 13.6579 21.9999 13.7771 21.9999L15.3375 21.9998L15.3885 21.9999C15.5784 22.0004 15.8175 22.0011 16.0529 21.9446C16.2569 21.8956 16.452 21.8148 16.631 21.7051C16.8373 21.5787 17.006 21.4091 17.1399 21.2745L17.1759 21.2383L20.5985 17.8158C20.777 17.6373 20.9508 17.4635 21.086 17.3043C21.2344 17.1295 21.394 16.9076 21.4881 16.6179C21.6186 16.2162 21.6186 15.7835 21.4881 15.3818C21.394 15.0921 21.2344 14.8702 21.086 14.6954C20.9508 14.5361 20.777 14.3624 20.5985 14.1839L19.8161 13.4015C19.6377 13.2231 19.4639 13.0492 19.3047 12.9141C19.1298 12.7657 18.9079 12.6061 18.6182 12.512ZM17.5759 14.8383C17.7856 14.6287 17.9009 14.5145 17.9901 14.4388L18.0002 14.4303L18.0103 14.4388C18.0995 14.5145 18.2148 14.6287 18.4245 14.8383L19.1617 15.5756C19.3714 15.7853 19.4856 15.9005 19.5613 15.9897L19.5698 15.9999L19.5613 16.01C19.4856 16.0992 19.3714 16.2144 19.1617 16.4241L15.7617 19.8241C15.6691 19.9167 15.6227 19.9628 15.5876 19.9949L15.5849 19.9973L15.5813 19.9974C15.5338 19.9996 15.4685 19.9998 15.3375 19.9998H14.0002V18.6626C14.0002 18.5316 14.0004 18.4662 14.0026 18.4187L14.0028 18.4151L14.0052 18.4125C14.0372 18.3774 14.0833 18.331 14.1759 18.2383L17.5759 14.8383Z"
      />
    ),
  ],
});
