export const Colors = {
  grey150: 'grey.150',
  grey100: 'grey.100',
  grey90: 'grey.90',
  grey80: 'grey.80',
  grey70: 'grey.70',
  grey60: 'grey.60',
  grey50: 'grey.50',
  grey40: 'grey.40',
  grey30: 'grey.30',
  grey20: 'grey.20',
  grey10: 'grey.10',
  grey5: 'grey.5',
  grey0: 'grey.0',
  transparentGrey30: 'transparent.grey.30',
  transparentGrey12: 'transparent.grey.12',
  transparentGrey10: 'transparent.grey.10',
  transparentGrey8: 'transparent.grey.8',
  transparentGrey5: 'transparent.grey.5',
  transparentGrey4: 'transparent.grey.4',
  transparentGrey0: 'transparent.grey.0',
  transparentWhite24: 'transparent.white.24',
  transparentWhite20: 'transparent.white.20',
  transparentWhite16: 'transparent.white.16',
  transparentWhite10: 'transparent.white.10',
  transparentWhite8: 'transparent.white.8',
  transparentWhite0: 'transparent.white.0',
  orange90: 'orange.90',
  orange80: 'orange.80',
  orange70: 'orange.70',
  orange60: 'orange.60',
  orange50: 'orange.50',
  orange40: 'orange.40',
  orange30: 'orange.30',
  orange20: 'orange.20',
  orange10: 'orange.10',
  orange5: 'orange.5',
  yellow90: 'yellow.90',
  yellow80: 'yellow.80',
  yellow70: 'yellow.70',
  yellow60: 'yellow.60',
  yellow50: 'yellow.50',
  yellow40: 'yellow.40',
  yellow30: 'yellow.30',
  yellow20: 'yellow.20',
  yellow10: 'yellow.10',
  yellow5: 'yellow.5',
  lime90: 'lime.90',
  lime80: 'lime.80',
  lime70: 'lime.70',
  lime60: 'lime.60',
  lime50: 'lime.50',
  lime40: 'lime.40',
  lime30: 'lime.30',
  lime20: 'lime.20',
  lime10: 'lime.10',
  lime5: 'lime.5',
  green90: 'green.90',
  green80: 'green.80',
  green70: 'green.70',
  green60: 'green.60',
  green50: 'green.50',
  green40: 'green.40',
  green30: 'green.30',
  green20: 'green.20',
  green10: 'green.10',
  green5: 'green.5',
  aqua90: 'aqua.90',
  aqua80: 'aqua.80',
  aqua70: 'aqua.70',
  aqua60: 'aqua.60',
  aqua50: 'aqua.50',
  aqua40: 'aqua.40',
  aqua30: 'aqua.30',
  aqua20: 'aqua.20',
  aqua10: 'aqua.10',
  aqua5: 'aqua.5',
  blue90: 'blue.90',
  blue80: 'blue.80',
  blue70: 'blue.70',
  blue60: 'blue.60',
  blue50: 'blue.50',
  blue40: 'blue.40',
  blue30: 'blue.30',
  blue20: 'blue.20',
  blue10: 'blue.10',
  blue5: 'blue.5',
  navy90: 'navy.90',
  navy80: 'navy.80',
  navy70: 'navy.70',
  navy60: 'navy.60',
  navy50: 'navy.50',
  navy40: 'navy.40',
  navy30: 'navy.30',
  navy20: 'navy.20',
  navy10: 'navy.10',
  navy5: 'navy.5',
  purple90: 'purple.90',
  purple80: 'purple.80',
  purple70: 'purple.70',
  purple60: 'purple.60',
  purple50: 'purple.50',
  purple40: 'purple.40',
  purple30: 'purple.30',
  purple20: 'purple.20',
  purple10: 'purple.10',
  purple5: 'purple.5',
  red90: 'red.90',
  red80: 'red.80',
  red70: 'red.70',
  red60: 'red.60',
  red50: 'red.50',
  red40: 'red.40',
  red30: 'red.30',
  red20: 'red.20',
  red10: 'red.10',
  red5: 'red.5',
  gradientGrey: 'gradient.grey',
  gradientOrange: 'gradient.orange',
  gradientYellow: 'gradient.yellow',
  gradientLime: 'gradient.lime',
  gradientGreen: 'gradient.green',
  gradientAqua: 'gradient.aqua',
  gradientBlue: 'gradient.blue',
  gradientNavy: 'gradient.navy',
  gradientPurple: 'gradient.purple',
  gradientRed: 'gradient.red',
} as const;
export type Color = typeof Colors[keyof typeof Colors];

export const ColorFoundations = {
  primary: 'primary',
  secondary: 'secondary',
  negative: 'negative',
  positive: 'positive',
  warning: 'warning',
  accent: 'accent',
} as const;
export type ColorFoundation = typeof ColorFoundations[keyof typeof ColorFoundations];

export const BGColorFoundations = {
  primary: 'grey.80',
  secondary: 'grey.10',
  negative: 'red.50',
  positive: 'green.50',
  warning: 'yellow.50',
  accent: 'blue.50',
} as const;
export type BGColorFoundation = typeof BGColorFoundations[keyof typeof BGColorFoundations];

export const FGColorFoundations = {
  primary: 'grey.0',
  secondary: 'grey.90',
  negative: 'grey.0',
  positive: 'grey.0',
  warning: 'grey.90',
  accent: 'grey.0',
} as const;
export type FGColorFoundation = typeof FGColorFoundations[keyof typeof FGColorFoundations];

export const TonalBGColorFoundations = {
  primary: 'grey.5',
  secondary: 'grey.5',
  negative: 'red.5',
  positive: 'green.5',
  warning: 'yellow.5',
  accent: 'blue.5',
} as const;

export const OutlineBorderColorFoundations = {
  primary: 'grey',
  secondary: 'grey',
  negative: 'red',
  positive: 'green',
  warning: 'yellow',
  accent: 'blue',
} as const;

export const sizes = [
  'max',
  'min',
  'full',
] as const;

export const Sizes = {
  120: '120',
  96: '96',
  80: '80',
  64: '64',
  60: '60',
  56: '56',
  52: '52',
  48: '48',
  44: '44',
  40: '40',
  36: '36',
  32: '32',
  28: '28',
  24: '24',
  20: '20',
  18: '18',
  16: '16',
  14: '14',
  12: '12',
} as const;
export type Size = typeof Sizes[keyof typeof Sizes];

export const Spaces = {
  1: '1',
  2: '2',
  4: '4',
  8: '8',
  12: '12',
  16: '16',
  18: '18',
  20: '20',
  24: '24',
  32: '32',
  36: '36',
  48: '48',
  60: '60',
  72: '72',
  96: '96',
  120: '120',
} as const;
export type Space = typeof Spaces[keyof typeof Spaces];

export const Borders = {
  lightOpaque: 'light.opaque',
  lightTransparent: 'light.transparent',
  lightSelected: 'light.selected',
  darkOpaque: 'dark.opaque',
  darkTransparent: 'dark.transparent',
  darkSelected: 'dark.selected',
} as const;
export type Border = typeof Borders[keyof typeof Borders];

export const Shadows = {
  highBelow: 'high.below',
  highAbove: 'high.above',
  middleBelow: 'middle.below',
  middleAbove: 'middle.above',
  lowBelow: 'low.below',
  lowAbove: 'low.above',
} as const;
export type Shadow = typeof Shadows[keyof typeof Shadows];

export const Radiuses = {
  full: 'full',
  half: 'half',
  quarter: 'quarter',
  6: '6',
  8: '8',
  12: '12',
  16: '16',
  18: '18',
  24: '24',
  28: '28',
} as const;
export type Radius = typeof Radiuses[keyof typeof Radiuses];

export const MaxWidths = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;
export type MaxWidth = typeof MaxWidths[keyof typeof MaxWidths];

export const EmoFeeling = {
  joy: 'joy',
  trust: 'trust',
  fear: 'fear',
  surprise: 'surprise',
  sadness: 'sadness',
  disgust: 'disgust',
  anger: 'anger',
  anticipation: 'anticipation',
  joker: 'joker',
} as const;
export type EmoFeeling = typeof EmoFeeling[keyof typeof EmoFeeling];

export const EmoColors = {
  joy: 'yellow',
  trust: 'lime',
  fear: 'green',
  surprise: 'aqua',
  sadness: 'navy',
  disgust: 'purple',
  anger: 'red',
  anticipation: 'orange',
  joker: 'grey',
} as const;
export type EmoColor = typeof EmoColors[keyof typeof EmoColors];
