import { FC, useEffect, useRef, useState } from 'react';
import { Box, Flex, TextareaProps, Textarea } from '@chakra-ui/react';
import { PenIcon } from '@emochan-cabinet/icons';
import { Label } from '../../typography/Label';

interface ThemeCardInputProps extends TextareaProps {
  readonly: boolean
  isSelected: boolean
}

// https://www.figma.com/file/u3BG13Svf44oWQfNdT8IoG/%F0%9F%97%84-emochan-cabinet?node-id=2757%3A22010
export const ThemeCardInput: FC<ThemeCardInputProps> = ({
  readonly,
  isSelected,
  defaultValue,
  onChange,
  ...props
}) => {
  const empty = String(defaultValue).length === 0;
  const inputRef = useRef<HTMLTextAreaElement|null>(null);
  const [value, updateValue] = useState(defaultValue || '');

  useEffect(() => {
    if (inputRef.current && readonly) {
      inputRef.current.value = defaultValue as string;
    }
  }, [defaultValue, readonly]);

  return (
    <Box
      as="button"
      type="button"
      borderRadius="32"
      _focus={readonly ? undefined : {
        boxShadow: 'inset 0px 0px 0px 4px rgb(0 4 15 / 30%)',
        outline: 'none',
      }}
      onClick={() => {
        inputRef?.current?.focus();
      }}
    >
      <Flex
        display="inline-flex"
        as="label"
        pos={isSelected ? 'relative' : 'static'}
        p="4"
        borderWidth="4px"
        borderStyle="solid"
        borderRadius="32"
        borderColor={isSelected ? 'blue.50' : 'transparent'}
        cursor="pointer"
        _hover={readonly ? { cursor: 'initial' } : { borderColor: isSelected ? 'blue.50' : 'transparent.grey.10' }}
        _focus={readonly ? undefined : { borderColor: isSelected ? 'blue.50' : 'transparent.grey.30', outline: 'none' }}
        aria-disabled={readonly}
      >
        <Label
          as="span"
          size="large"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          bg={isSelected ? 'gradient.blue' : 'grey.0'}
          borderColor="border.light.transparent"
          borderWidth="1px"
          color={isSelected ? 'grey.0' : 'blue.50'}
          w="13.5rem"
          h="9.75rem"
          pt="8"
          pb="8"
          pl="16"
          pr="16"
          borderRadius="24"
          zIndex="normal"
          gap="4"
        >
          {(empty && !isSelected) && <PenIcon />}
          <Box pos="relative">
            <Label
              as="div"
              size="large"
              overflow="hidden"
              visibility="hidden"
              whiteSpace="pre-wrap"
              overflowWrap="break-word"
              wordBreak="break-all"
              aria-hidden="true"
              maxH="calc(var(--emochan-lineHeights-label-large) * 5)"
              px="16"
              sx={{ wordWrap: 'break-word' }}
            >
              {`${value || props.placeholder}\u200b`}
            </Label>
            <Textarea
              tabIndex={-1}
              ref={inputRef}
              readOnly={readonly}
              defaultValue={defaultValue}
              value={value}
              resize="none"
              pos="absolute"
              top="50%"
              left={0}
              transform="translateY(-50%)"
              w="100%"
              h="100%"
              maxH="calc(var(--emochan-lineHeights-label-large) * 5)"
              px="16"
              name="theme"
              overflowY="auto"
              bg="transparent"
              textAlign={(!empty || isSelected) ? 'center' : 'left'}
              m={0}
              border="none"
              color={empty || !isSelected ? 'blue.50' : 'grey.0'}
              cursor={readonly ? 'not-allowed' : 'initial'}
              _placeholder={{ color: 'blue.50' }}
              _focus={{
                outline: 'none',
                color: 'grey.0',
                _placeholder: { color: readonly ? 'blue.50' : 'blue.20' },
              }}
              onChange={(e) => {
                onChange?.(e);
                updateValue(e.target.value);
              }}
              {...props}
            />
          </Box>
        </Label>
      </Flex>
    </Box>
  );
};

ThemeCardInput.displayName = 'ThemeCardInput';
