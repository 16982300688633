// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const VideocamOffIcon = createIcon({
  displayName: 'VideocamOffIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M1.40715 2.79289C1.01663 3.18342 1.01663 3.81658 1.40715 4.20711L2.80007 5.60003C2.66285 5.78298 2.54092 5.97825 2.43607 6.18404C2.00009 7.03969 2.00009 8.15979 2.00009 10.4V13.6C2.00009 15.8402 2.00009 16.9603 2.43607 17.816C2.81956 18.5686 3.43148 19.1805 4.18413 19.564C5.03978 20 6.15988 20 8.40009 20H11.6001C13.8403 20 14.9604 20 15.8161 19.564C16.0218 19.4592 16.2171 19.3372 16.4001 19.2L19.7919 22.5919C20.1825 22.9824 20.8156 22.9824 21.2061 22.5919C21.5967 22.2014 21.5967 21.5682 21.2061 21.1777L2.82136 2.79289C2.43084 2.40237 1.79768 2.40237 1.40715 2.79289ZM14.9565 17.7565L4.24361 7.04357C4.23489 7.05958 4.22638 7.07574 4.21808 7.09202C4.16769 7.19091 4.09234 7.39235 4.04795 7.93567C4.00165 8.50235 4.00009 9.24689 4.00009 10.4V13.6C4.00009 14.7531 4.00165 15.4977 4.04795 16.0643C4.09234 16.6077 4.16769 16.8091 4.21808 16.908C4.40983 17.2843 4.71579 17.5903 5.09211 17.782C5.191 17.8324 5.39244 17.9078 5.93576 17.9521C6.50244 17.9984 7.24699 18 8.40009 18H11.6001C12.7532 18 13.4977 17.9984 14.0644 17.9521C14.6077 17.9078 14.8092 17.8324 14.9081 17.782C14.9244 17.7737 14.9405 17.7652 14.9565 17.7565Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M16.0001 10.4V13.1458L17.9924 15.1381C17.9995 14.7254 18 14.2607 18.0001 13.732L20.6764 16.0498C21.0972 16.4142 21.3076 16.5964 21.4859 16.6015C21.6409 16.6059 21.7891 16.5382 21.8872 16.4182C22.0001 16.2801 22.0001 16.0017 22.0001 15.445V8.55497C22.0001 7.99827 22.0001 7.71992 21.8872 7.58182C21.7891 7.46182 21.6409 7.3941 21.4859 7.3985C21.3076 7.40356 21.0972 7.58578 20.6764 7.95023L18.0001 10.268C17.9999 8.11544 17.9914 7.02271 17.5641 6.18404C17.1806 5.43139 16.5687 4.81947 15.8161 4.43597C14.9604 4 13.8403 4 11.6001 4H8.40009C7.81638 4 7.30871 4 6.86196 4.00771L8.85425 6H11.6001C12.7532 6 13.4977 6.00156 14.0644 6.04785C14.6077 6.09225 14.8092 6.1676 14.9081 6.21799C15.2844 6.40973 15.5904 6.71569 15.7821 7.09202C15.8325 7.19091 15.9078 7.39235 15.9522 7.93567C15.9985 8.50235 16.0001 9.24689 16.0001 10.4Z"
      />
    ),
  ],
});
