import { ReactNode } from 'react';
import { Box, BoxProps, forwardRef, Divider } from '@chakra-ui/react';

const BLOCK_NAME = 'List.Body';

export interface ListBodyProps extends BoxProps {
  children: ReactNode
}

export const ListBody = forwardRef<ListBodyProps, 'div'>(({
  children,
  ...props
}, ref) => (
  <>
    <Divider />
    <Box ref={ref} {...props}>
      {children}
    </Box>
  </>
));

ListBody.displayName = BLOCK_NAME;
