import React, { useMemo } from 'react';
import { forwardRef, Icon as ChakraIcon, IconProps as ChakraIconProps, ResponsiveValue } from '@chakra-ui/react';

const IconSizes = {
  xxxl: 'xxxl',
  xxl: 'xxl',
  xl: 'xl',
  large: 'large',
  medium: 'medium',
  small: 'small',
  xs: 'xs',
} as const;
export type IconSize = typeof IconSizes[keyof typeof IconSizes];

export interface IconProps extends ChakraIconProps {
  size?: ResponsiveValue<IconSize>,
}

export const Icon = forwardRef<IconProps, 'svg'>(({
  size = IconSizes.medium,
  viewBox,
  children,
  as,
  ...props
}, ref) => {
  const fz = useMemo(() => {
    if (typeof size === 'string') return `icon.${size}`;

    return {
      base: size.base ? `icon.${size.base}` : undefined,
      md: size.md ? `icon.${size.md}` : undefined,
      lg: size.lg ? `icon.${size.lg}` : undefined,
      xl: size.xl ? `icon.${size.xl}` : undefined,
      // xxl: size.xxl ? `icon.${size.xxl}` : undefined,
      // xxxl: size.xxxl ? `icon.${size.xxxl}` : undefined,
    }
  }, [size]);

  return (
    <ChakraIcon
      as={as}
      w="1em"
      h="1em"
      ref={ref}
      viewBox={viewBox}
      focusable={false}
      // /* Safariでセンターがずれるため */
      fontFamily="Apple Color Emoji"
      fontSize={fz}
      fill="currentColor"
      sx={{ aspectRatio: '1/1' }}
      {...props}
    >
      {children}
    </ChakraIcon>
  )
});
