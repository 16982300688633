import { useEditor, EditorEvents } from '@tiptap/react';
import { Underline } from '@tiptap/extension-underline';
import { Link } from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import { Document } from '@tiptap/extension-document';
import { Text } from '@tiptap/extension-text';
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { HardBreak } from '@tiptap/extension-hard-break';
import { History } from '@tiptap/extension-history';
import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { Italic } from '@tiptap/extension-italic';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Strike } from '@tiptap/extension-strike';
import { Heading } from '@tiptap/extension-heading';
import { Blockquote } from '@tiptap/extension-blockquote';

export default function useRichTextEditor(
  content: string,
  onUpdate?: (state: string) => void,
  onFocus?: (props: EditorEvents['focus']) => void,
  onBlur?: (props: EditorEvents['blur']) => void,
  editable = false,
  placeholder?: string,
): { editor: ReturnType<typeof useEditor> } {
  // https://tiptap.dev/api/nodes/
  const editor = useEditor({
    extensions: [
      Document,
      Heading,
      Paragraph,
      Blockquote,
      HorizontalRule,
      History,
      HardBreak,
      Text,
      Bold,
      Italic,
      Underline,
      Strike,
      ListItem,
      BulletList.configure({ HTMLAttributes: { class: 'emochan-ul' } }),
      OrderedList.configure({ HTMLAttributes: { class: 'emochan-ol' } }),
      Link.configure({
        protocols: ['http', 'https'],
        HTMLAttributes: { class: 'emochan-link' },
      }),
      Placeholder.configure({ placeholder }),
      BubbleMenu,
    ],
    onUpdate: ({ editor: e }) => {
      onUpdate?.(e.getHTML());
    },
    onFocus: (e) => {
      onFocus?.(e);
    },
    onBlur: (e) => {
      onBlur?.(e);
    },
    content,
    editable,
  });

  return { editor };
}
