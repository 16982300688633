// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const GrabberIcon = createIcon({
  displayName: 'GrabberIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M4 8C3.44772 8 3 8.44772 3 9C3 9.55228 3.44772 10 4 10H20C20.5523 10 21 9.55228 21 9C21 8.44772 20.5523 8 20 8H4Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M4 14C3.44772 14 3 14.4477 3 15C3 15.5523 3.44772 16 4 16H20C20.5523 16 21 15.5523 21 15C21 14.4477 20.5523 14 20 14H4Z"
      />
    ),
  ],
});
