// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const OpenInNewIcon = createIcon({
  displayName: 'OpenInNewIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11 4C11 3.44772 10.5523 3 10 3H7C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V14C21 13.4477 20.5523 13 20 13C19.4477 13 19 13.4477 19 14V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V7C5 5.89543 5.89543 5 7 5H10C10.5523 5 11 4.55228 11 4Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M21 4V10C21 10.5523 20.5523 11 20 11C19.4477 11 19 10.5523 19 10V6.35547L13.3849 11.9706C12.9944 12.3611 12.3612 12.3611 11.9707 11.9706C11.5802 11.58 11.5802 10.9469 11.9707 10.5563L17.527 5H14C13.4477 5 13 4.55228 13 4C13 3.44772 13.4477 3 14 3H20C20.5523 3 21 3.44772 21 4Z"
      />
    ),
  ],
});
