import { forwardRef, useMemo } from 'react';
import { Text, HTMLChakraProps, SystemProps, ResponsiveValue } from '@chakra-ui/react';

export const ParagraphSizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  xs: 'xs',
} as const;
export type ParagraphSize = typeof ParagraphSizes[keyof typeof ParagraphSizes];

export interface ParagraphProps extends Omit<HTMLChakraProps<'p'>, 'textAlign' | 'fontWeight'> {
  size?: ResponsiveValue<ParagraphSize>,
  align?: SystemProps['textAlign']
}

export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(({
  size = 'large',
  align,
  children,
  ...rest
}, ref) => {
  const responsiveSize = useMemo(() => {
    if (!size) return size;

    if (typeof size === 'string') {
      return `paragraph.${size}`;
    }

    if (Array.isArray(size)) {
      return size.map((s) => `paragraph.${s}`);
    }

    const r: Record<string, string> = {};

    Object.entries(size).forEach(([key, value]) => {
      r[key] = `paragraph.${value}`;
    });

    return r;
  }, [size]);

  return (
    <Text
      ref={ref}
      fontSize={responsiveSize}
      lineHeight={responsiveSize}
      fontWeight="paragraph"
      textAlign={align}
      {...rest}
    >
      {children}
    </Text>
  );
});

Paragraph.displayName = 'Paragraph';
