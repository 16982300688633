import { useEffect, useCallback } from 'react';

const KEY_NAME_ESC = 'Escape';

export function useESCKey(handleClose: () => void): void {
  const handleEscKey = useCallback((e: KeyboardEvent) => {
    if (e.key === KEY_NAME_ESC) {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener('keyup', handleEscKey, false);

    return () => {
      document.removeEventListener('keyup', handleEscKey, false);
    };
  }, [handleEscKey]);
}
