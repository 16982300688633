// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const NumberedListIcon = createIcon({
  displayName: 'NumberedListIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M3.5 2.75C3.22386 2.75 3 2.97386 3 3.25C3 3.52614 3.22386 3.75 3.5 3.75H4.5V6.75C4.5 7.02614 4.72386 7.25 5 7.25C5.27614 7.25 5.5 7.02614 5.5 6.75V3.25C5.5 2.97386 5.27614 2.75 5 2.75L3.5 2.75Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M9.5 4C8.94772 4 8.5 4.44772 8.5 5C8.5 5.55228 8.94771 6 9.5 6H19C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4H9.5Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M9.5 11C8.94772 11 8.5 11.4477 8.5 12C8.5 12.5523 8.94771 13 9.5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H9.5Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8.5 19C8.5 18.4477 8.94772 18 9.5 18H19C19.5523 18 20 18.4477 20 19C20 19.5523 19.5523 20 19 20H9.5C8.94771 20 8.5 19.5523 8.5 19Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M3 10.25C3 9.97386 3.22386 9.75 3.5 9.75H6C6.1873 9.75 6.35888 9.85467 6.44459 10.0212C6.53029 10.1877 6.51573 10.3882 6.40687 10.5406L4.4716 13.25H6C6.27614 13.25 6.5 13.4739 6.5 13.75C6.5 14.0261 6.27614 14.25 6 14.25H3.5C3.31271 14.25 3.14112 14.1453 3.05542 13.9788C2.96972 13.8123 2.98427 13.6118 3.09314 13.4594L5.02841 10.75H3.5C3.22386 10.75 3 10.5261 3 10.25Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M3.5 16.75C3.22386 16.75 3 16.9739 3 17.25C3 17.5261 3.22386 17.75 3.5 17.75H5.5V18.5H4.5C4.22386 18.5 4 18.7239 4 19C4 19.2761 4.22386 19.5 4.5 19.5H5.5V20.25H3.5C3.22386 20.25 3 20.4739 3 20.75C3 21.0261 3.22386 21.25 3.5 21.25H6C6.27614 21.25 6.5 21.0261 6.5 20.75V17.25C6.5 16.9739 6.27614 16.75 6 16.75H3.5Z"
      />
    ),
  ],
});
