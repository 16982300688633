export type EEHandler<T> = (value: T) => any;

export type EE<T> = {
  on: (handler: EEHandler<T>) => void;
  off: (handler: EEHandler<T>) => void;
  offAll: () => void;
  emit: (value: T) => void;
};

export const eventEmit: <T>() => EE<T> = <T>() => {
  const set = new Set<EEHandler<T>>();
  return {
    on(handler: EEHandler<T>) {
      set.add(handler);
    },
    off(handler: EEHandler<T>) {
      set.delete(handler);
    },
    offAll() {
      set.clear();
    },
    emit(value: T) {
      set.forEach((handler) => handler(value));
    },
  };
};
