import { getMicPermissionStatus } from '@emochan-cabinet/speech';
import { isBrowser } from './app';
import { auth } from './firebase';

export async function getDebugData(): Promise<string> {
  const nodeEnv = process.env.NODE_ENV;
  const sha = process.env.NEXT_PUBLIC_COMMIT_SHA;

  if (!isBrowser()) return '';

  const mic = await getMicPermissionStatus();

  const ua = 'userAgentData' in navigator
  // @ts-expect-error userAgentData
    ? JSON.stringify(await navigator.userAgentData.getHighEntropyValues(
      [
        'platform',
        'platformVersion',
        'architecture',
        'model',
        'uaFullVersion',
      ],
    ))
    : navigator.userAgent;

  const tokenResult = await auth.currentUser?.getIdTokenResult(true)
    .catch(() => null);

  const result = [
    `ua: ${ua}`,
    // eslint-disable-next-line max-len
    `window: ${window.innerWidth}:${window.innerHeight}, ${getComputedStyle(document.documentElement).getPropertyValue('--emochan-vh')}`,
    // eslint-disable-next-line max-len
    `vh: ${getComputedStyle(document.documentElement).getPropertyValue('--emochan-vh')} : ${getComputedStyle(document.documentElement).getPropertyValue('--emochan-vvh')}`,
    `dpr: ${window.devicePixelRatio}`,
    // eslint-disable-next-line no-mixed-operators
    `zoom: ${Math.floor(window.outerWidth / window.innerWidth * 100)}%`,
    `visualViewport: ${window.visualViewport?.width}:${window.visualViewport?.height}`,
    `location: ${JSON.stringify(window.location)}`,
    `firebase: ${process.env.NEXT_PUBLIC_FIREBASE_PROJECTID?.replace('emochan-cabinet-', '')}`,
    `env: ${nodeEnv}`,
    `sha: ${sha}`,
    `mic: ${mic === null ? 'null' : mic.state}`,
  ];

  if (tokenResult) {
    const {
      claims: { sub },
      signInProvider,
    } = tokenResult;

    result.push(`uid: ${sub}`);
    result.push(`signInProvider: ${signInProvider}`);
  }

  return ['```'].concat(result).concat('```').join('\n');
}
