import { FC, useMemo, ReactNode } from 'react';
import { Flex, Box, StyleProps, BoxProps } from '@chakra-ui/react';
import { Label } from '../../typography/Label';

export type HeaderStartProps = {
  icon?: ReactNode
  label: string
  logo?: ReactNode
}

const HeaderStart: FC<HeaderStartProps> = ({ icon, label, logo }) => (
  <Flex align="center">
    {icon}
    {logo && <Box h="1.5rem" px="8" aria-label={label}>{logo}</Box>}
  </Flex>
);

HeaderStart.displayName = 'Header.Start';

const HeaderCenter: FC<{ element: string | ReactNode }> = ({ element }) => {
  const cssProps: StyleProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    w: 'max-content',
  };

  const centerEl = typeof element === 'string'
    ? (
      <Box {...cssProps} p="8">
        <Label as="h1" size="medium">{element}</Label>
      </Box>
    )
    : (
      <Box {...cssProps}>
        {element}
      </Box>
    );

  return centerEl;
};

HeaderCenter.displayName = 'Header.Center';

export interface HeaderProps extends BoxProps {
  start?: HeaderStartProps
  center?: string | ReactNode
  end?: ReactNode
  hasShadow?: boolean
}

// eslint-disable-next-line prefer-arrow-callback
export const Header: FC<HeaderProps> = ({ start, center, end, hasShadow = true, ...props }) => {
  const startEl = useMemo(() => {
    if (start) {
      return <HeaderStart {...start} />;
    }
    return <span />;
  }, [start]);

  const centerEl = useMemo(() => <HeaderCenter element={center} />, [center]);

  const endEl = useMemo(() => {
    if (end) {
      return <Flex flexGrow={1} h="100%" justifyContent="flex-end" alignItems="center">{end}</Flex>;
    }

    return <Flex flexGrow={1} h="100%" justifyContent="flex-end" alignItems="center" as="span" />;
  }, [end]);

  return (
    <Flex
      role="navigation"
      as="nav"
      h="3.5rem"
      px="8"
      bg="grey.0"
      aria-label="emochan navigation"
      position="relative"
      zIndex="normal"
      justifyContent="space-between"
      alignItems="center"
      boxShadow={hasShadow ? '0px 1px 0px var(--emochan-colors-border-light-transparent)' : undefined}
      {...props}
    >
      {startEl}
      {centerEl}
      {endEl}
    </Flex>
  );
};
