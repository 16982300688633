// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const MemberSingleFilledIcon = createIcon({
  displayName: 'MemberSingleFilledIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8C6 11.3137 8.68629 14 12 14Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M7 16C4.23858 16 2 18.2386 2 21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21C22 18.2386 19.7614 16 17 16H7Z"
      />
    ),
  ],
});
