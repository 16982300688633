// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const StrikeIcon = createIcon({
  displayName: 'StrikeIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M14.1106 6.91098C14.3857 7.38631 14.9951 7.54918 15.4716 7.27474C15.9482 7.00031 16.1115 6.3925 15.8363 5.91716L15.5872 5.48683C14.3491 3.34783 11.607 2.61495 9.46252 3.8499C7.46873 4.99808 6.69351 7.44303 7.58944 9.5H9.85671L9.54715 8.96518C8.85931 7.77685 9.2675 6.25733 10.4589 5.57124C11.6502 4.88516 13.1737 5.29231 13.8615 6.48064L14.1106 6.91098Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M13.4869 18.3428C14.8404 17.5626 15.3575 15.8917 14.7243 14.5H16.8444C17.3883 16.6217 16.4834 18.9293 14.4904 20.078C12.0906 21.4613 9.02191 20.6404 7.63636 18.2445L7.13461 17.3769C6.8575 16.8977 7.02195 16.285 7.50192 16.0083C7.98189 15.7317 8.59562 15.8959 8.87273 16.375L9.37448 17.2427C10.2058 18.6802 12.047 19.1728 13.4869 18.3428Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H5Z"
      />
    ),
  ],
});
