import { FC, ReactNode, isValidElement, cloneElement } from 'react';
import {
  BoxProps,
  ModalHeader as ChakraModalHeader,
  ModalBody as ChakraModalBody,
  ModalFooter as ChakraModalFooter,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import { Paragraph } from '../../typography/Paragraph';
import { useMedia } from '../../../hooks/useMedia';
import { getChildren } from '../../../utils/ReactChildren';

export type ModalHeaderProps = {
  title: string
  tag?: BoxProps['as'];
}

export type ModalElementProps = {
  children: ReactNode
}

export const ModalHeader: FC<ModalHeaderProps> = ({ title, tag = 'h3' }) => (
  <ChakraModalHeader as={tag} whiteSpace="pre-wrap">{title}</ChakraModalHeader>
);

ModalHeader.displayName = 'Modal.Header';

export interface ModalFooterProps extends Omit<FlexProps, 'direction'> {
  direction: 'vertical' | 'horizontal'
  full: boolean
}

export const ModalFooter: FC<ModalFooterProps> = ({ children, direction, full, ...props }) => {
  const { isMedium } = useMedia();
  const isV = direction === 'vertical';
  const isFull = full || isV || isMedium;

  let kids = children;

  if (isFull) {
    const buttons = getChildren(children);

    kids = buttons.map((child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          ...child.props as any,
          flexGrow: 1,
        });
      }
      return child;
    });
  }

  return (
    <ChakraModalFooter>
      <Flex
        w={isFull ? '100%' : props.w}
        flexDirection={isV ? 'column' : 'row'}
        gap={isV ? '12' : '8'}
        justifyContent="flex-end"
        p="16"
        {...props}
      >
        {kids}
      </Flex>
    </ChakraModalFooter>
  );
};

ModalFooter.displayName = 'Modal.Footer';

export const ModalBody = ChakraModalBody;

ModalBody.displayName = 'Modal.Body';

export const ModalDescription: FC<{ text: string }> = ({ text }) => <Paragraph size="medium" color="grey.80" whiteSpace="pre-wrap">{text}</Paragraph>;

ModalDescription.displayName = 'Modal.DesModalDescription';

// TODO: Modal 以外でも使える
export interface UseModalProps {
  isOpen?: boolean
  onClose?(): void
  onOpen?(): void
}
