// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const PauseIcon = createIcon({
  displayName: 'PauseIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8V16C11 17.6569 9.65685 19 8 19C6.34315 19 5 17.6569 5 16V8ZM9 8V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M13 8C13 6.34315 14.3431 5 16 5C17.6569 5 19 6.34315 19 8V16C19 17.6569 17.6569 19 16 19C14.3431 19 13 17.6569 13 16V8ZM17 8V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8Z"
      />
    ),
  ],
});
