import { forwardRef, useMemo } from 'react';
import { Text, HTMLChakraProps, SystemProps, ResponsiveValue } from '@chakra-ui/react';

export const DisplaySizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  xs: 'xs',
} as const;
export type DisplaySize = typeof DisplaySizes[keyof typeof DisplaySizes];

export interface DisplayProps extends Omit<HTMLChakraProps<'div'>, 'textAlign' | 'fontWeight'> {
  size?: ResponsiveValue<DisplaySize>,
  align?: SystemProps['textAlign']
}

export const Display = forwardRef<HTMLDivElement, DisplayProps>(({
  size = 'large',
  align,
  children,
  ...rest
}, ref) => {
  const responsiveSize = useMemo(() => {
    if (!size) return size;

    if (typeof size === 'string') {
      return `display.${size}`;
    }

    if (Array.isArray(size)) {
      return size.map((s) => `display.${s}`);
    }

    const r: Record<string, string> = {};

    Object.entries(size).forEach(([key, value]) => {
      r[key] = `display.${value}`;
    });

    return r;
  }, [size]);

  return (
    <Text
      ref={ref}
      fontSize={responsiveSize}
      lineHeight={responsiveSize}
      fontWeight="display"
      textAlign={align}
      {...rest}
    >
      {children}
    </Text>
  );
});

Display.displayName = 'Display';
