import { cloneElement, forwardRef, ReactNode } from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { Tooltip } from '../../notifications/Tooltip';

export interface FormatButtonProps extends BoxProps {
  label: string
  icon: ReactNode
  isActive?: boolean
  isDisabled?: boolean
}

const activeStyleProps = {
  bg: 'blue.5',
  color: 'blue.50',
  boxShadow: '0 0 0 1px var(--emochan-colors-blue-50)',
  _hover: { bg: 'transparent.blue.12' },
  _focus: { bg: 'transparent.blue.12' },
};

const defaultStyleProps: BoxProps = {
  bg: 'grey.0',
  color: 'grey.60',
  _hover: { bg: 'transparent.grey.4', color: 'grey.100' },
  _focus: { bg: 'transparent.grey.12', color: 'grey.100', outline: 'none' },
  _active: activeStyleProps,
};

// アイコン付きメモ編集ボタン
// https://www.figma.com/file/u3BG13Svf44oWQfNdT8IoG/%F0%9F%97%84-emochan-cabinet?node-id=2907%3A21898
const FormatButton = forwardRef<HTMLButtonElement, FormatButtonProps>(({
  label,
  icon,
  isActive,
  onClick,
  ...props
}, ref) => {
  let styleProps = defaultStyleProps;

  if (isActive) {
    styleProps = { ...styleProps, ...activeStyleProps };
  }

  return (
    <Tooltip
      label={label}
      placement="top"
      hasArrow
      offset={[0, 6]}
    >
      <Box p="12" pos="relative">
        <Flex
          as="button"
          type="button"
          onClick={onClick}
          ref={ref}
          aria-label={label}
          w="1.5rem"
          h="1.5rem"
          alignItems="center"
          justifyContent="center"
          borderRadius="6"
          bg="grey.0"
          color="grey.60"
          _hover={{ bg: 'transparent.grey.4', color: 'grey.100' }}
          _focus={{ bg: 'transparent.grey.12', color: 'grey.100', outline: 'none' }}
          _active={{ bg: 'blue.5', color: 'blue.50', boxShadow: '0 0 0 1px var(--emochan-colors-blue-50)' }}
          _before={{
            content: '""',
            pos: 'absolute',
            inset: 0,
          }}
          {...styleProps}
          {...props}
        >
          {cloneElement(icon, { size: 'small' })}
        </Flex>
      </Box>
    </Tooltip>
  );
});

export default FormatButton;
