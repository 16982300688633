// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const MemberSingleIcon = createIcon({
  displayName: 'MemberSingleIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M7 16C4.23858 16 2 18.2386 2 21C2 21.5523 2.44772 22 3 22C3.55228 22 4 21.5523 4 21C4 19.3431 5.34315 18 7 18H17C18.6569 18 20 19.3431 20 21C20 21.5523 20.4477 22 21 22C21.5523 22 22 21.5523 22 21C22 18.2386 19.7614 16 17 16H7Z"
      />
    ),
  ],
});
