// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const DocumentIcon = createIcon({
  displayName: 'DocumentIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M9 16C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H9Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M8.16146 2H13C13.9055 2 14.7739 2.35971 15.4142 3L19 6.58579C19.6403 7.22608 20 8.0945 20 9V17.8385C20 18.3656 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8386 22H8.16144C7.6343 22 7.17954 22 6.80497 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V6.16146C3.99998 5.63433 3.99997 5.17954 4.03057 4.80497C4.06287 4.40963 4.13419 4.01641 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.01641 2.13419 6.40963 2.06287 6.80497 2.03057C7.17954 1.99997 7.63433 1.99998 8.16146 2ZM6.96784 4.02393C6.69618 4.04613 6.59546 4.0838 6.54601 4.109C6.35785 4.20487 6.20487 4.35785 6.109 4.54601C6.0838 4.59546 6.04613 4.69618 6.02393 4.96784C6.00078 5.25118 6 5.62345 6 6.2V17.8C6 18.3766 6.00078 18.7488 6.02393 19.0322C6.04613 19.3038 6.0838 19.4045 6.109 19.454C6.20487 19.6422 6.35785 19.7951 6.54601 19.891C6.59546 19.9162 6.69618 19.9539 6.96784 19.9761C7.25118 19.9992 7.62345 20 8.2 20H15.8C16.3766 20 16.7488 19.9992 17.0322 19.9761C17.3038 19.9539 17.4045 19.9162 17.454 19.891C17.6422 19.7951 17.7951 19.6422 17.891 19.454C17.9162 19.4045 17.9539 19.3038 17.9761 19.0322C17.9992 18.7488 18 18.3766 18 17.8V10L16.1615 10C15.6343 10 15.1795 10 14.805 9.96943C14.4096 9.93713 14.0164 9.86582 13.638 9.67302C13.0735 9.3854 12.6146 8.92646 12.327 8.36197C12.1342 7.98359 12.0629 7.59038 12.0306 7.19503C12 6.82046 12 6.3657 12 5.83855L12 4H8.2C7.62345 4 7.25118 4.00078 6.96784 4.02393ZM14 4.41422V5.8C14 6.37656 14.0008 6.74883 14.0239 7.03217C14.0461 7.30383 14.0838 7.40455 14.109 7.45399C14.2049 7.64216 14.3579 7.79514 14.546 7.89101C14.5955 7.9162 14.6962 7.95388 14.9678 7.97608C15.2512 7.99923 15.6234 8 16.2 8L17.5858 8L14 4.41422Z"
      />
    ),
  ],
});
