// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M6 11C7.86658 11 9.43455 9.72147 9.87594 7.99238C9.91658 7.99741 9.95799 8 10 8H21C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6H10C9.95799 6 9.91658 6.00259 9.87594 6.00762C9.43455 4.27853 7.86658 3 6 3C3.79086 3 2 4.79086 2 7C2 9.20914 3.79086 11 6 11ZM8 7C8 8.10457 7.10457 9 6 9C4.89543 9 4 8.10457 4 7C4 5.89543 4.89543 5 6 5C7.10457 5 8 5.89543 8 7Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M14.1241 17.9924C14.5654 19.7215 16.1334 21 18 21C20.2091 21 22 19.2091 22 17C22 14.7909 20.2091 13 18 13C16.1334 13 14.5654 14.2785 14.1241 16.0076C14.0834 16.0026 14.042 16 14 16H3C2.44772 16 2 16.4477 2 17C2 17.5523 2.44772 18 3 18H14C14.042 18 14.0834 17.9974 14.1241 17.9924ZM16 17C16 18.1046 16.8954 19 18 19C19.1046 19 20 18.1046 20 17C20 15.8954 19.1046 15 18 15C16.8954 15 16 15.8954 16 17Z"
      />
    ),
  ],
});
