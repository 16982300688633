// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const CrossCircleIcon = createIcon({
  displayName: 'CrossCircleIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M9.87868 15.5356C9.48815 15.9261 8.85499 15.9261 8.46446 15.5356C8.07394 15.1451 8.07394 14.5119 8.46446 14.1214L10.5858 12.0001L8.46446 9.87876C8.07394 9.48824 8.07394 8.85507 8.46446 8.46455C8.85499 8.07402 9.48815 8.07402 9.87868 8.46455L12 10.5859L14.1213 8.46452C14.5118 8.074 15.145 8.074 15.5355 8.46452C15.9261 8.85505 15.9261 9.48821 15.5355 9.87874L13.4142 12.0001L15.5355 14.1214C15.9261 14.5119 15.9261 15.1451 15.5355 15.5356C15.145 15.9261 14.5118 15.9261 14.1213 15.5356L12 13.4143L9.87868 15.5356Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      />
    ),
  ],
});
