// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const VolumeDownIcon = createIcon({
  displayName: 'VolumeDownIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M13.6412 6.23287C13.3294 5.86775 12.8616 5.67399 12.3829 5.71166C11.9543 5.7454 11.6448 5.9921 11.4979 6.11512C11.3332 6.25302 11.1453 6.44096 10.9541 6.63218L9.92304 7.66327C9.45097 8.13534 9.28747 8.29496 9.11574 8.42068C8.72788 8.70459 8.27748 8.89115 7.80246 8.96465C7.59213 8.9972 7.36365 8.99993 6.69605 8.99993L6.66009 8.99969C6.53481 8.99852 6.28043 8.99615 6.04725 9.07008C5.58235 9.21747 5.21813 9.58169 5.07073 10.0466C4.9968 10.2798 4.99918 10.5342 5.00035 10.6594L5.00059 10.6954V13.3045L5.00035 13.3404C4.99918 13.4657 4.9968 13.7201 5.07073 13.9533C5.21813 14.4182 5.58235 14.7824 6.04725 14.9298C6.28043 15.0037 6.53481 15.0013 6.66009 15.0002L6.69605 14.9999C7.36365 14.9999 7.59213 15.0027 7.80246 15.0352C8.27748 15.1087 8.72788 15.2953 9.11574 15.5792C9.28747 15.7049 9.45097 15.8645 9.92304 16.3366L10.9541 17.3677C11.1453 17.5589 11.3332 17.7468 11.4979 17.8847C11.6448 18.0078 11.9543 18.2545 12.3829 18.2882C12.8616 18.3259 13.3294 18.1321 13.6412 17.767C13.9204 17.4401 13.9648 17.0468 13.9817 16.8559C14.0007 16.6419 14.0006 16.3762 14.0006 16.1058V7.89407C14.0006 7.62369 14.0007 7.35793 13.9817 7.14396C13.9648 6.95307 13.9204 6.55981 13.6412 6.23287ZM12.0006 8.41415V15.5857L11.2848 14.8699C10.8849 14.4698 10.6084 14.1932 10.2971 13.9654C9.65064 13.4922 8.89998 13.1812 8.10828 13.0587C7.78104 13.0081 7.44654 13.001 7.00059 13V10.9998C7.44654 10.9989 7.78104 10.9918 8.10828 10.9411C8.89998 10.8186 9.65064 10.5077 10.2971 10.0345C10.6084 9.80664 10.8848 9.53006 11.2848 9.12998L12.0006 8.41415Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M18.5 11.9999C18.5 12.6999 18.3417 13.3624 18.025 13.9874C17.7083 14.6124 17.2917 15.1249 16.775 15.5249C16.6083 15.6249 16.4375 15.6291 16.2625 15.5374C16.0875 15.4458 16 15.2999 16 15.0999V8.84994C16 8.64994 16.0875 8.50411 16.2625 8.41244C16.4375 8.32077 16.6083 8.32494 16.775 8.42494C17.2917 8.84161 17.7083 9.36661 18.025 9.99994C18.3417 10.6333 18.5 11.2999 18.5 11.9999Z"
      />
    ),
  ],
});
