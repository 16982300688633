// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const VolumeUpIcon = createIcon({
  displayName: 'VolumeUpIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M19 11.975C19 10.5917 18.6333 9.32917 17.9 8.1875C17.1667 7.04583 16.1833 6.19167 14.95 5.625C14.7 5.50833 14.5167 5.32917 14.4 5.0875C14.2833 4.84583 14.2667 4.6 14.35 4.35C14.45 4.08333 14.6292 3.89167 14.8875 3.775C15.1458 3.65833 15.4083 3.65833 15.675 3.775C17.2917 4.49167 18.5833 5.5875 19.55 7.0625C20.5167 8.5375 21 10.175 21 11.975C21 13.775 20.5167 15.4125 19.55 16.8875C18.5833 18.3625 17.2917 19.4583 15.675 20.175C15.4083 20.2917 15.1458 20.2917 14.8875 20.175C14.6292 20.0583 14.45 19.8667 14.35 19.6C14.2667 19.35 14.2833 19.1042 14.4 18.8625C14.5167 18.6208 14.7 18.4417 14.95 18.325C16.1833 17.7583 17.1667 16.9042 17.9 15.7625C18.6333 14.6208 19 13.3583 19 11.975Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M16.5 12C16.5 12.7 16.3417 13.3625 16.025 13.9875C15.7083 14.6125 15.2917 15.125 14.775 15.525C14.6083 15.625 14.4375 15.6292 14.2625 15.5375C14.0875 15.4458 14 15.3 14 15.1V8.85C14 8.65 14.0875 8.50417 14.2625 8.4125C14.4375 8.32083 14.6083 8.325 14.775 8.425C15.2917 8.84167 15.7083 9.36667 16.025 10C16.3417 10.6333 16.5 11.3 16.5 12Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M11.6412 6.23293C11.3294 5.86781 10.8616 5.67405 10.3829 5.71172C9.95426 5.74546 9.64482 5.99217 9.49788 6.11518C9.33318 6.25308 9.14528 6.44102 8.95413 6.63224L7.92304 7.66333C7.45097 8.1354 7.28747 8.29503 7.11574 8.42074C6.72788 8.70465 6.27748 8.89122 5.80246 8.96471C5.59213 8.99726 5.36365 8.99999 4.69605 8.99999L4.66009 8.99975C4.53481 8.99858 4.28043 8.99621 4.04725 9.07014C3.58235 9.21754 3.21813 9.58176 3.07073 10.0467C2.9968 10.2798 2.99918 10.5342 3.00035 10.6595L3.00059 10.6955V13.3045L3.00035 13.3405C2.99918 13.4658 2.9968 13.7202 3.07073 13.9533C3.21813 14.4182 3.58235 14.7825 4.04725 14.9298C4.28043 15.0038 4.53481 15.0014 4.66009 15.0002L4.69605 15C5.36365 15 5.59213 15.0027 5.80246 15.0353C6.27748 15.1088 6.72788 15.2953 7.11574 15.5792C7.28747 15.705 7.45097 15.8646 7.92304 16.3367L8.95412 17.3677C9.14528 17.559 9.33317 17.7469 9.49788 17.8848C9.64482 18.0078 9.95426 18.2545 10.3829 18.2883C10.8616 18.3259 11.3294 18.1322 11.6412 17.7671C11.9204 17.4401 11.9648 17.0469 11.9817 16.856C12.0007 16.642 12.0006 16.3762 12.0006 16.1059V7.89413C12.0006 7.62375 12.0007 7.35799 11.9817 7.14402C11.9648 6.95313 11.9204 6.55988 11.6412 6.23293ZM10.0006 8.41421V15.5858L9.28478 14.87C8.88485 14.4699 8.60836 14.1933 8.29707 13.9654C7.65064 13.4922 6.89998 13.1813 6.10828 13.0588C5.78104 13.0082 5.44654 13.001 5.00059 13.0001V10.9999C5.44654 10.9989 5.78104 10.9918 6.10828 10.9412C6.89998 10.8187 7.65064 10.5078 8.29707 10.0346C8.60837 9.8067 8.88485 9.53011 9.28478 9.13003L10.0006 8.41421Z"
      />
    ),
  ],
});
