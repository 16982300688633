import { createIcon } from './create-icon';

export const SlackIcon = createIcon({
  path: [
    <path
      d="M6.20193 14.6385C6.20193 15.7949 5.25731 16.7395 4.10096 16.7395C2.94462 16.7395 2 15.7949 2 14.6385C2 13.4821 2.94462 12.5375 4.10096 12.5375H6.20193V14.6385Z"
      fill="#E01E5A"
    />,
    <path
      d="M7.26025 14.6385C7.26025 13.4821 8.20488 12.5375 9.36123 12.5375C10.5176 12.5375 11.4622 13.4821 11.4622 14.6385V19.899C11.4622 21.0554 10.5176 22 9.36123 22C8.20488 22 7.26025 21.0554 7.26025 19.899V14.6385Z"
      fill="#E01E5A"
    />,
    <path
      d="M9.36123 6.20193C8.20488 6.20193 7.26025 5.25731 7.26025 4.10096C7.26025 2.94462 8.20488 2 9.36123 2C10.5176 2 11.4622 2.94462 11.4622 4.10096V6.20193H9.36123Z"
      fill="#36C5F0"
    />,
    <path
      d="M9.36153 7.26042C10.5179 7.26042 11.4625 8.20505 11.4625 9.36139C11.4625 10.5177 10.5179 11.4624 9.36153 11.4624H4.10097C2.94462 11.4624 2 10.5177 2 9.36139C2 8.20505 2.94462 7.26042 4.10097 7.26042H9.36153Z"
      fill="#36C5F0"
    />,
    <path
      d="M17.7979 9.36139C17.7979 8.20505 18.7425 7.26042 19.8988 7.26042C21.0551 7.26042 21.9997 8.20505 21.9997 9.36139C21.9997 10.5177 21.0551 11.4624 19.8988 11.4624H17.7979V9.36139Z"
      fill="#2EB67D"
    />,
    <path
      d="M16.7396 9.36153C16.7396 10.5179 15.7949 11.4625 14.6386 11.4625C13.4822 11.4625 12.5376 10.5179 12.5376 9.36153V4.10097C12.5376 2.94462 13.4822 2 14.6386 2C15.7949 2 16.7396 2.94462 16.7396 4.10097V9.36153Z"
      fill="#2EB67D"
    />,
    <path
      d="M14.6386 17.7979C15.7949 17.7979 16.7396 18.7426 16.7396 19.8989C16.7396 21.0552 15.7949 21.9998 14.6386 21.9998C13.4822 21.9998 12.5376 21.0552 12.5376 19.8989V17.7979H14.6386Z"
      fill="#ECB22E"
    />,
    <path
      d="M14.6386 16.7395C13.4822 16.7395 12.5376 15.7949 12.5376 14.6385C12.5376 13.4821 13.4822 12.5375 14.6386 12.5375H19.8991C21.0555 12.5375 22.0001 13.4821 22.0001 14.6385C22.0001 15.7949 21.0555 16.7395 19.8991 16.7395H14.6386Z"
      fill="#ECB22E"
    />,
  ],
});
