import { forwardRef, HTMLAttributes } from 'react';
import { Flex, Box } from '@chakra-ui/react';

export type RainbowBarProps = HTMLAttributes<HTMLDivElement>;

const keys = [
  'joy',
  'trust',
  'fear',
  'surprise',
  'sadness',
  'disgust',
  'anger',
  'anticipation',
];

export const RainbowBar = forwardRef<HTMLDivElement, RainbowBarProps>((props, ref) => (
  <Flex
    ref={ref}
    aria-hidden="true"
    w="100%"
    gap="4"
    p="4"
    {...props}
  >
    {
      keys.map((key) => (
        <Box
          key={key}
          as="span"
          height=".25rem"
          borderRadius="full"
          flexGrow={1}
          bg={key}
        />
      ))
    }
  </Flex>
));

RainbowBar.displayName = 'RainbowBar';
