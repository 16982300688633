import { createContext, useContext } from 'react';

export interface TranscriptBoxState {
  status: 'waiting' | 'transcribing' | 'paused',
}

export const TranscriptBoxContext = createContext<TranscriptBoxState>({} as TranscriptBoxState);

TranscriptBoxContext.displayName = 'TranscriptBoxContext';

export function useTranscriptBox(): TranscriptBoxState {
  return useContext(TranscriptBoxContext);
}
