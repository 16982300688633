// Code generated by icon generator, DO NOT EDIT.
import { createIcon } from './create-icon';

export const LinkDiagonalIcon = createIcon({
  displayName: 'LinkDiagonalIcon',
  viewBox: '0 0 24 24',
  path: [
    (
      <path
        fillRule="evenodd"
        d="M11.9999 16.2423C12.3904 15.8517 13.0236 15.8517 13.4141 16.2423C13.8047 16.6328 13.8047 17.266 13.4141 17.6565L11.9999 19.0707C10.0473 21.0233 6.88147 21.0233 4.92884 19.0707C2.97622 17.1181 2.97622 13.9522 4.92884 11.9996L6.34306 10.5854C6.73358 10.1949 7.36675 10.1949 7.75727 10.5854C8.14779 10.9759 8.1478 11.6091 7.75727 11.9996L6.34306 13.4138C5.17148 14.5854 5.17149 16.4849 6.34306 17.6565C7.51463 18.828 9.41413 18.828 10.5857 17.6565L11.9999 16.2423Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M11.9999 7.75698C11.6094 8.1475 10.9762 8.1475 10.5857 7.75698C10.1952 7.36646 10.1952 6.73329 10.5857 6.34277L11.9999 4.92855C13.9525 2.97593 17.1184 2.97593 19.071 4.92855C21.0236 6.88117 21.0236 10.047 19.071 11.9996L17.6568 13.4138C17.2662 13.8044 16.6331 13.8044 16.2426 13.4138C15.852 13.0233 15.852 12.3901 16.2426 11.9996L17.6568 10.5854C18.8283 9.41383 18.8283 7.51434 17.6568 6.34277C16.4852 5.17119 14.5857 5.17119 13.4141 6.34277L11.9999 7.75698Z"
      />
    ),
    (
      <path
        fillRule="evenodd"
        d="M15.5354 8.46409C15.1449 8.07356 14.5118 8.07356 14.1212 8.46409L8.46438 14.1209C8.07385 14.5115 8.07385 15.1446 8.46438 15.5352C8.8549 15.9257 9.48807 15.9257 9.87859 15.5352L15.5354 9.8783C15.926 9.48778 15.926 8.85461 15.5354 8.46409Z"
      />
    ),
  ],
});
